import {useEffect, useMemo, useState} from "react";
import {Button, Col, Row, Table} from "react-bootstrap";
import LoadingErrorContent from "../../../../common/LoadingErrorContent";
import {ProvidersType} from "../../../nomenclatures/providers/hooks/useProviders";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {ConfirmDeleteModal} from "../../../../common/ConfirmDeleteModal";
import {collection, getDocs, query, where, writeBatch, QueryFieldFilterConstraint} from "firebase/firestore";
import useWarehouseIn, {WarehouseInType} from "../../hooks/useWarehouseIn";
import {WarehouseInDetails} from "./WarehouseInDetails";
import WarehouseInTableRow from "./WarehouseInTableRow";
import {WarehouseObjectType} from "../../hooks/useWarehouseObjects";
import {fbDb} from "../../../../App";
import UseRecalcWarehouseItemStatus from "../../hooks/useRecalcWarehouseItemQty";
import WarehouseInOutFilter, {WarehouseInOutFilterType} from "../WarehpuseInOutFilter";
import CenterAlignedCell from "../../../../common/CenterAlignedCell";
import OwnCompanyWarehouseSelector from "../../warehouse/OwnCompanyWarehouseSelector";
import {dateWithEndDayTime, dateWithZeroTime} from "../../../../common";

export interface WarehouseObjectInComponentProps {
    whObject: WarehouseObjectType;
    allProviders: ProvidersType;
}

const toDay = new Date();

export default function WarehouseObjectInComponent(props: WarehouseObjectInComponentProps) {
    const [selectedCompanyId, setSelectedCompanyId] = useState<string | undefined>();
    const [selectedWarehouseId, setSelectedWarehouseId] = useState<string | undefined>();

    const [filter, setFilter] = useState<WarehouseInOutFilterType>({
        fromDate: dateWithZeroTime(new Date(toDay.getFullYear(), toDay.getMonth(), 1, 0, 0, 0, 0)),
        toDate: dateWithEndDayTime(toDay),
    });
    const [itemsConstraints, setItemsConstraints] = useState<QueryFieldFilterConstraint[]>([]);

    function buildItemsConstraints(filter: WarehouseInOutFilterType) {
        const constraints: QueryFieldFilterConstraint[] = [];
        if (filter.fromDate) {
            constraints.push(where("transactionDate", ">=", filter.fromDate));
        }
        if (filter.toDate) {
            constraints.push(where("transactionDate", "<=", filter.toDate));
        }
        if (filter.provider) {
            constraints.push(where("provider.id", "==", filter.provider.id));
        }
        if (filter.product) {
            constraints.push(where("productIds", "array-contains", filter.product.id));
        }
        return constraints;
    }

    const [items, loading, error] = useWarehouseIn(selectedWarehouseId, itemsConstraints);
    const [editTransaction, setEditTransaction] = useState<WarehouseInType | null>(null);
    const [deleteTransaction, setDeleteTransaction] = useState<WarehouseInType | null>(null);
    const warehouseItemUpdater = UseRecalcWarehouseItemStatus();

    useEffect(() => {
        setItemsConstraints(buildItemsConstraints(filter));
    }, [filter]);

    let tableRows = useMemo(() => {
        return items.map(
            p => <WarehouseInTableRow data={p} key={p.id}
                                      onEdit={() => setEditTransaction(p)}
                                      onDelete={() => setDeleteTransaction(p)}/>
        )
    }, [items]);

    function addNew() {
        setEditTransaction(
            {
                transactionDate: new Date(),
                ownCompanyId: selectedCompanyId!,
                warehouseId: selectedWarehouseId!,
                hasDDS: true,
                isEUR: false,
            } as WarehouseInType
        )
    }

    async function removeTransaction() {
        if (deleteTransaction) {
            const affectedProducts = new Set<string>();

            const whtItemsRef = query(collection(fbDb, "warehouseInItems"),
                where("transactionId", "==", deleteTransaction.id));
            const whtItems = await getDocs(whtItemsRef);

            const batch = writeBatch(fbDb);
            batch.delete(deleteTransaction.ref);

            for (const item of whtItems.docs) {
                affectedProducts.add(item.get("productId"));
                batch.delete(item.ref);
            }
            await batch.commit();

            affectedProducts.forEach(productId =>
                warehouseItemUpdater(deleteTransaction.ownCompanyId, deleteTransaction.warehouseId, productId, false, undefined));
            setDeleteTransaction(null);
        }
    }

    return <div className={"mt-1 p-3 border shadow rounded bg-light"}>
        <Row>
            <Col>
                <h3>Доставки</h3>
            </Col>
            <Col xs={"auto"} className={"text-end"}>
                {
                    selectedWarehouseId &&
                    <Button onClick={addNew}>
                        <FontAwesomeIcon icon={faPlus}/> Добави
                    </Button>
                }
            </Col>
        </Row>

        <OwnCompanyWarehouseSelector
            selectedCompanyId={selectedCompanyId}
            selectedWarehouseId={selectedWarehouseId}
            onChange={(companyId, warehouseId) => {
                setSelectedCompanyId(companyId);
                setSelectedWarehouseId(warehouseId);
            }}
        />

        <LoadingErrorContent loading={loading} error={error?.message}>
            <div className={"max-h-65vh scrollable bg-white"}>
                <Row style={{width: "75%"}}>
                    <Col>
                        <WarehouseInOutFilter
                            viewMode={"in"}
                            filter={filter}
                            onChange={(newFilter) => setFilter({...newFilter})}/>
                    </Col>
                </Row>
                <Table size={"sm"} bordered hover>
                    <thead>
                    <tr>
                        <th>Дата</th>
                        <th>Булстат</th>
                        <th>Доставчик</th>
                        <th>Фактура No:</th>
                        <th className={"text-end"}>Сума</th>
                        <CenterAlignedCell header>EUR?</CenterAlignedCell>
                        <CenterAlignedCell header>ДДС?</CenterAlignedCell>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {tableRows}
                    </tbody>
                </Table>
            </div>
        </LoadingErrorContent>

        {
            editTransaction &&
            <WarehouseInDetails warehouseTransaction={{...editTransaction}}
                                onDone={() => setEditTransaction(null)}/>
        }
        {
            deleteTransaction &&
            <ConfirmDeleteModal working={false}
                                onConfirm={() => removeTransaction()}
                                onReject={() => setDeleteTransaction(null)}/>
        }
    </div>;
}
