import {useEffect, useMemo, useState} from "react";
import {Button, Col, Row, Tab, Table, Tabs} from "react-bootstrap";
import LoadingErrorContent from "../../../../common/LoadingErrorContent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {ConfirmDeleteModal} from "../../../../common/ConfirmDeleteModal";
import {collection, getDocs, query, QueryFieldFilterConstraint, where, writeBatch,} from "firebase/firestore";
import {WarehouseOutDetails} from "./WarehouseOutDetails";
import WarehouseOutTableRow from "./WarehouseOutTableRow";
import useWarehouseOut, {WarehouseOutType} from "../../hooks/useWarehouseOut";
import {WarehouseObjectType} from "../../hooks/useWarehouseObjects";
import {fbDb} from "../../../../App";
import UseRecalcWarehouseItemStatus from "../../hooks/useRecalcWarehouseItemQty";
import WarehouseInOutFilter, {WarehouseInOutFilterType} from "../WarehpuseInOutFilter";
import useOwnCompaniesRepo from "../../hooks/useOwnCompaniesRepo";
import {OwnCompanyType} from "../../hooks/useOwnCompanies";
import useWarehouseObjectsRepo from "../../hooks/useWarehouseObjectsRepo";
import OwnCompanyWarehouseSelector from "../../warehouse/OwnCompanyWarehouseSelector";
import {dateWithEndDayTime, dateWithZeroTime} from "../../../../common";

export interface WarehouseObjectOutComponentProps {
    whObject: WarehouseObjectType;
}

const toDay = new Date();

export default function WarehouseObjectOutComponent(props: WarehouseObjectOutComponentProps) {
    const [selectedCompanyId, setSelectedCompanyId] = useState<string | undefined>();
    const [selectedWarehouseId, setSelectedWarehouseId] = useState<string | undefined>();

    const [filter, setFilter] = useState<WarehouseInOutFilterType>({
        fromDate: dateWithZeroTime(new Date(toDay.getFullYear(), toDay.getMonth(), 1, 0, 0, 0, 0)),
        toDate: dateWithEndDayTime(toDay),
    });
    const [itemsConstraints, setItemsConstraints] = useState<QueryFieldFilterConstraint[]>([]);

    const buildItemsConstraints = (filter: WarehouseInOutFilterType) => {
        const constraints: QueryFieldFilterConstraint[] = [];
        if (filter.fromDate) {
            constraints.push(where("transactionDate", ">=", filter.fromDate));
        }
        if (filter.toDate) {
            constraints.push(where("transactionDate", "<=", filter.toDate));
        }
        if (filter.client) {
            constraints.push(where("client.id", "==", filter.client.id));
        }
        if (filter.product) {
            constraints.push(where("productIds", "array-contains", filter.product.id));
        }
        return constraints;
    }

    const [items, loading, error] = useWarehouseOut(selectedWarehouseId, itemsConstraints);
    const [editTransaction, setEditTransaction] = useState<WarehouseOutType | null>(null);
    const [deleteTransaction, setDeleteTransaction] = useState<WarehouseOutType | null>(null);
    const warehouseItemUpdater = UseRecalcWarehouseItemStatus();

    useEffect(() => {
        setItemsConstraints(buildItemsConstraints(filter));
    }, [filter]);

    let tableRows = useMemo(() => {
        return items.map(
            p => <WarehouseOutTableRow data={p} key={p.id}
                                       onEdit={() => setEditTransaction(p)}
                                       onDelete={() => setDeleteTransaction(p)}/>
        )
    }, [items]);


    function addNew() {
        setEditTransaction(
            {
                transactionDate: new Date(),
                warehouseId: selectedWarehouseId,
                ownCompanyId: selectedCompanyId,
            } as WarehouseOutType
        )
    }

    async function removeTransaction() {
        if (deleteTransaction && deleteTransaction.ref) {
            const affectedProducts = new Set<string>();

            const whtItemsRef = query(collection(fbDb, "warehouseOutItems"),
                where("transactionId", "==", deleteTransaction.id));
            const whtItems = await getDocs(whtItemsRef);

            const batch = writeBatch(fbDb);
            batch.delete(deleteTransaction.ref);

            for (const item of whtItems.docs) {
                affectedProducts.add(item.get("productId"));
                batch.delete(item.ref);
            }
            await batch.commit();

            affectedProducts.forEach(productId =>
                warehouseItemUpdater(deleteTransaction.ownCompanyId, deleteTransaction.warehouseId, productId, false, undefined));
            affectedProducts.forEach(productId =>
                warehouseItemUpdater(deleteTransaction.ownCompanyId, deleteTransaction.warehouseId, productId, true, undefined));
            setDeleteTransaction(null);
        }
    }

    return <div className={"mt-1 p-3 border shadow rounded bg-light"}>
        <Row>
            <Col>
                <h3>Продажби</h3>
            </Col>
            <Col xs={"auto"} className={"text-end"}>
                <Button onClick={addNew}>
                    <FontAwesomeIcon icon={faPlus}/> Добави
                </Button>
            </Col>
        </Row>

        <Row>
            <Col>
                <OwnCompanyWarehouseSelector
                    selectedCompanyId={selectedCompanyId}
                    selectedWarehouseId={selectedWarehouseId}
                    onChange={(companyId, warehouseId) => {
                        setSelectedCompanyId(companyId);
                        setSelectedWarehouseId(warehouseId);
                    }}
                />
            </Col>
        </Row>

        <Row style={{width: "75%"}}>
            <Col>
                <WarehouseInOutFilter
                    viewMode={"out"}
                    filter={filter}
                    onChange={(newFilter) => setFilter(newFilter)}/>
            </Col>
        </Row>

        <LoadingErrorContent loading={loading} error={error?.message}>
            <div className={"max-h-65vh scrollable bg-white"}>
                <Table size={"sm"} bordered hover>
                    <thead>
                    <tr>
                        <th>Дата</th>
                        <th>Булстат</th>
                        <th>Клиент</th>
                        <th className={"text-end"}>Сума</th>
                        <th className={"w-100px"}></th>
                    </tr>
                    </thead>
                    <tbody>
                    {tableRows}
                    </tbody>
                </Table>
            </div>
        </LoadingErrorContent>

        {
            editTransaction &&
            <WarehouseOutDetails warehouseTransaction={{...editTransaction}}
                                 onDone={() => setEditTransaction(null)}/>
        }
        {
            deleteTransaction &&
            <ConfirmDeleteModal working={false}
                                onConfirm={() => removeTransaction()}
                                onReject={() => setDeleteTransaction(null)}/>
        }
    </div>
        ;
}
