import {ProviderType} from "../../../crm/customers-offers/hooks/useProviders";
import {Account} from "../../../../types";

export type WarehouseRequestType = {
    id?: number;
    ownCompanyId: string;
    warehouseId: string;
    requestNumber?: number;
    provider?: ProviderType;
    items: WarehouseRequestTypeItem[];
    itemIds: string[];
    createdAt: Date;
    createdBy: Account | null;
    isActive: boolean;
}

export type WarehouseRequestTypeItem = {
    ownCompanyId: string;
    warehouseId: string;
    product: WarehouseRequestItemProductType;
    qty: number;
    inquiryNumber: string;
    expectedDeliveryDate: Date;
    deliveryDate?: Date;
    invoiceNumber?: string;
    invoiceDate?: Date;
    deliveryQty?:number;
    deliveryPrice?: number;
    isActive: boolean;
}

export type WarehouseRequestItemProductType = {
    id?: string;
    sku: string;
    name: string;
    dimension: string;
    price: number;
}

export const EMPTY_WAREHOUSE_REQUEST: WarehouseRequestType = {
    ownCompanyId: "",
    warehouseId: "",
    items: [],
    itemIds: [],
    createdAt: new Date(),
    createdBy: null,
    isActive: true
}
