import {
    collection,
    DocumentData,
    FirestoreDataConverter,
    FirestoreError,
    query,
} from "firebase/firestore";
import {useCollection} from "react-firebase-hooks/firestore";
import {fbDb} from "../../../App";


export interface OwnCompanyType {
    id: string;
    name: string;
    shortName: any;
    productionCardCounterName: string;
}
export type OwnCompaniesType = OwnCompanyType[];


export const ownCompanyConverter: FirestoreDataConverter<OwnCompanyType> = {
    toFirestore(company: OwnCompanyType): DocumentData {
        return {
            name: company.name,
            shortName: company.shortName,
            productionCardCounterName: company.productionCardCounterName,
        };
    },
    fromFirestore(snapshot, options): OwnCompanyType {
        const data = snapshot.data(options)!;
        return {
            id: snapshot.id,
            name: data.name,
            shortName: data.shortName ?? data.name,
            productionCardCounterName: data.productionCardCounterName,
        };
    }
};



export default function useOwnCompanies(): [OwnCompaniesType | undefined, boolean, FirestoreError | undefined] {
    const ownCompaniesQuery = query(collection(fbDb, "ownCompanies").withConverter(ownCompanyConverter));
    const [ownCompanies, loadingOwnCompanies, errorOwnCompanies] = useCollection<OwnCompanyType>(ownCompaniesQuery);

    return [ownCompanies?.docs.map(d => d.data()) ?? undefined, loadingOwnCompanies, errorOwnCompanies];
}
