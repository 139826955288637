import {
    Button,
    Col,
    Container,
    Form,
    Row,
    Spinner,
    Tab,
    Table,
    Tabs,
    ToggleButton,
    ToggleButtonGroup
} from "react-bootstrap";
import LoadingErrorContent from "../../../common/LoadingErrorContent";
import {useEffect, useMemo, useState} from "react";
import ProductionPlanFilter, {initialProductionPlanFilter, ProductionPlanFilterType} from "./ProductionPlanFilter";
import {useCollection} from "react-firebase-hooks/firestore";
import {fbDb} from "../../../App";
import {collection, DocumentReference, limit, query, QueryConstraint, updateDoc, where} from "firebase/firestore";
import {productionCardConverter, ProductionCardType} from "../types/ProductionCardType";
import {dateToString, showErrorsListInToast} from "../../../common";
import ReactDatePicker from "react-datepicker";
import PrintIconButton from "../../../common/icon-buttons/PrintIconButton";
import ProductionPlanPrintPreview from "../../crm/customers-offers/print/production-card/ProductionPlanPrintPreview";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleArrowDown} from "@fortawesome/free-solid-svg-icons";
import ProductionPlanDateEditCell from "./ProductionPlanDateEditCell";
import CancelIconButton from "../../../common/icon-buttons/CancelIconButton";
import {RenderProductionCardExpeditions} from "./RenderProductionCardExpeditions";
import I18Label from "../../../i18/i18label";
import appDb from "../../../global-state/global-db";
import {useHookstate} from "@hookstate/core";
import {OwnCompanyType} from "../../warehouse/hooks/useOwnCompanies";
import OwnCompanySelector from "../../nomenclatures/own-companies/OwnCompanySelector";
import useOwnCompaniesRepo from "../../warehouse/hooks/useOwnCompaniesRepo";

interface ProductionPlanTableProps {
    onSelect?: (card: ProductionCardType) => void;
}

const ProductionPlanTable = ({onSelect}: ProductionPlanTableProps) => {
    const db = useHookstate(appDb);
    const ownCompanies = db.ownCompanies.value.map(c => ({...c}) as OwnCompanyType);
    const [selectedOwnCompany, setSelectedOwnCompany] = useState<string[]>([]);
    const [viewMode, setViewMode] = useState("Чакащи");
    const [updatingField, setUpdatingField] = useState("");
    const [printCard, setPrintCard] = useState<ProductionCardType | undefined>(undefined);
    const [filter, setFilter] = useState<ProductionPlanFilterType>(initialProductionPlanFilter);
    const [orderBy, setOrderBy] = useState("0");

    useEffect(() => {
        if(ownCompanies && ownCompanies.length > 0 && selectedOwnCompany.length === 0) {
            setSelectedOwnCompany([ownCompanies[0].id]);
        }
    }, [ownCompanies]);

    const getConstraintViewMode = () => {
        if (viewMode === "Чакащи") return "Чакащи";
        if (viewMode === "В производство") return "В производство";
        if (viewMode === "Изпълнена") return "Изпълнена";

        if (filter.clientId || filter.cardNumber || filter.regarding || filter.productionPlace || filter.deadlineDate ||
            filter.productionDate || filter.expeditionDate || filter.planExpeditionDate || filter.planProductionDate) {
            return viewMode;
        }
        return "";
    }
    const constraintViewMode = getConstraintViewMode();

    const constraints: QueryConstraint[] = [
        where("productionStatus", "==", constraintViewMode),
        where("ownCompanyId", "in", selectedOwnCompany)
    ];
    if (filter.cardNumber) constraints.push(where("cardNumber", "==", parseInt(filter.cardNumber)));
    if (filter.inquiryNumber) constraints.push(where("inquiryNumber", "==", parseInt(filter.inquiryNumber)));
    if (filter.clientId) constraints.push(where("invoiceClientName", "==", filter.clientName));
    if (filter.regarding) constraints.push(where("regarding", "==", filter.regarding));
    if (filter.productionPlace) constraints.push(where("productionPlace", "==", filter.productionPlace));
    if (filter.deadlineDate) constraints.push(where("deadlineDate", "==", filter.deadlineDate));
    if (filter.productionDate) constraints.push(where("productionDate", "==", filter.productionDate));
    if (filter.planExpeditionDate) constraints.push(where("planExpeditionDate", "==", filter.planExpeditionDate));
    if (filter.planProductionDate) constraints.push(where("planProductionDate", "==", filter.planProductionDate));
    if (constraintViewMode !== "Чакащи" && constraintViewMode !== "В производство" && constraintViewMode !== "Изпълнена") {
        constraints.push(limit(100));
    }

    const [cardsData, loading, error] = useCollection<ProductionCardType>(
        selectedOwnCompany.length > 0 ?
            query(collection(fbDb, "productionCards").withConverter(productionCardConverter), ...constraints)
            : null
    );

    const cards = useMemo(
        () => {
            if (cardsData) {
                return cardsData.docs.map(card => card.data());
            } else return [];
        }, [cardsData]);

    async function updateCardField(ref: DocumentReference | undefined | null, key: string, data: any) {
        if (ref) {
            try {
                setUpdatingField(`${ref?.id}-${key}`);
                await updateDoc(ref, data);
            } catch (e: any) {
                showErrorsListInToast("Възникна грешка", [e.message]);
            } finally {
                setUpdatingField("");
            }
        }
    }

    const {getOwnCompanyShortName} = useOwnCompaniesRepo();

    const tableRows = useMemo(() => {
        if(!cardsData) return [];

        if (cards) {
            let filteredCards = cards;
            if (orderBy) {
                filteredCards.sort((a, b) => {
                    if (orderBy === "0") return a.cardNumber - b.cardNumber;
                    if (orderBy === "9") return (a.inquiryNumber ?? 0) - (b.inquiryNumber ?? 0);
                    if (orderBy === "1") return a.invoiceClientName.localeCompare(b.invoiceClientName);
                    if (orderBy === "2") return a.regarding.localeCompare(b.regarding);
                    if (orderBy === "3") return a.productionPlace.localeCompare(b.productionPlace);
                    if (orderBy === "4") return (dateToString(a.deadlineDate) || "").localeCompare(dateToString(b.deadlineDate) || "");
                    if (orderBy === "5") return (dateToString(a.planProductionDate) || "").localeCompare(dateToString(b.planProductionDate) || "");
                    if (orderBy === "6") return (dateToString(a.planExpeditionDate) || "").localeCompare(dateToString(b.planExpeditionDate) || "");
                    if (orderBy === "7") return (dateToString(a.productionDate) || "").localeCompare(dateToString(b.productionDate) || "");
                    if (orderBy === "8") return (dateToString(a.expeditionDate) || "").localeCompare(dateToString(b.expeditionDate) || "");

                    return 0;
                });
            }

            return filteredCards.map(card => {
                return <tr key={card.id}>
                    {
                        onSelect &&
                        <td className={"p-2"}>
                            <Button size={"sm"} onClick={() => onSelect(card)} variant={"outline-success"}>
                                <FontAwesomeIcon icon={faCircleArrowDown}/>
                            </Button>
                        </td>
                    }
                    <td style={{ width: "50px"}} className={"text-center"}>
                        {
                            getOwnCompanyShortName(card.ownCompanyId)
                            // updatingField === `${card.ref?.id}-ownCompanyId` ?
                            //     <div className={"text-center"}><Spinner/></div> :
                            //     <Form.Select
                            //         size={"sm"}
                            //         value={card.ownCompanyId}
                            //         onChange={e => updateCardField(card.ref, 'ownCompanyId', {ownCompanyId: e.target.value})}>
                            //         {
                            //             ownCompanies.map(company => (
                            //                 <option key={company.id} value={company.id}>{company.shortName}</option>
                            //             ))
                            //         }
                            //     </Form.Select>
                        }
                    </td>
                    <td> {card.cardNumber} </td>
                    <td> {card.inquiryNumber}/{dateToString(card.inquiryDate)} </td>
                    <td> {card.invoiceClientName} </td>
                    <td> {card.regarding} </td>
                    <td> {card.productionPlace} </td>
                    <ProductionPlanDateEditCell
                        setDate={date => updateCardField(card.ref, 'deadlineDate', {deadlineDate: date || null})}
                        date={card.deadlineDate ?? undefined}/>
                    <ProductionPlanDateEditCell
                        setDate={date => updateCardField(card.ref, 'planProductionDate', {planProductionDate: date || null})}
                        date={card.planProductionDate ?? undefined}/>
                    <ProductionPlanDateEditCell
                        setDate={date => updateCardField(card.ref, 'planExpeditionDate', {planExpeditionDate: date || null})}
                        date={card.planExpeditionDate ?? undefined}/>
                    <ProductionPlanDateEditCell
                        setDate={date => updateCardField(card.ref, 'productionDate', {productionDate: date || null})}
                        date={card.productionDate ?? undefined}/>
                    <td><RenderProductionCardExpeditions
                        expeditions={card.expeditions ?? []}
                        expeditionStatus={"ЗАВЪРШЕНА"}
                    /></td>
                    <td>
                        {
                            updatingField === `${card.ref?.id}-productionStatus` ?
                                <div className={"text-center"}><Spinner/></div> :
                                <Form.Select
                                    size={"sm"}
                                    value={card.productionStatus || "Чакащи"}
                                    onChange={e => updateCardField(card.ref, 'productionStatus', {productionStatus: e.target.value})}>
                                    <option value={"Чакащи"}>Чакащи</option>
                                    <option value={"В производство"}>В производство</option>
                                    <option value={"Изпълнена"}>Произведена</option>
                                    <option value={"Приключена"}>Приключена</option>
                                    <option value={"Отказана"}>Отказана</option>
                                </Form.Select>
                        }
                    </td>
                    <td>
                        {
                            updatingField === `${card.ref?.id}-notes` ?
                                <div className={"text-center"}><Spinner/></div> :
                                <Form.Control
                                    size={"sm"}
                                    as={"textarea"}
                                    rows={2}
                                    value={card.notes || ""}
                                    onChange={e => updateCardField(card.ref, 'notes', {notes: e.target.value})}
                                />
                        }
                    </td>
                    <td className={"p-2"}>
                        <PrintIconButton onClick={() => setPrintCard(card)}/>
                    </td>
                </tr>
            });
        }
        return [];
    }, [cardsData, filter, orderBy, updatingField]);

    async function updateMaterialIds() {
        if (cards) {
            for (const card of cards) {
                if (card.ref) {
                    const newMaterialIds = card.materials.map(m => m.productId);
                    await updateDoc(card.ref, {materialIds: newMaterialIds});
                }
            }
        }
    }

    return (
        <LoadingErrorContent loading={loading} error={error?.message}>
            <Container>
                <Row className={"mb-2"}>
                    <Col>
                        <OwnCompanySelector
                            idPrefix={"production-plan"}
                            selectedOwnCompaniesId={selectedOwnCompany}
                            onChange={setSelectedOwnCompany}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Tabs className={"ms-1"}
                              activeKey={viewMode}
                              onSelect={key => setViewMode(key || "")}>
                            <Tab eventKey={"Чакащи"} title={"Чакащи"}></Tab>
                            <Tab eventKey={"В производство"} title={"В производство"}></Tab>
                            <Tab eventKey={"Изпълнена"} title={"Произведени"}></Tab>
                            {
                                !onSelect &&
                                <Tab eventKey={"Приключена"} title={"Приключена"}></Tab>
                            }
                            {
                                !onSelect &&
                                <Tab eventKey={"Отказана"} title={"Отказана"}></Tab>
                            }
                        </Tabs>
                    </Col>
                    <Col xs={"auto"} className={"text-end text-nowrap"}>
                        <Form.Group as={Row} className={"m-0"}>
                            <Form.Label column className={"me-2 lh-1"}>Подреди по:</Form.Label>
                            <Col xs={"auto"} className={"p-0 me-2"}>
                                <Form.Select size={"sm"} value={orderBy}
                                             onChange={e => setOrderBy(e.target.value)}
                                >
                                    <option value={"0"}>Карта No</option>
                                    <option value={"8"}>Запитване No</option>
                                    <option value={"1"}>Клиент</option>
                                    <option value={"2"}>Относно</option>
                                    <option value={"3"}>Място</option>
                                    <option value={"4"}>Краен срок</option>
                                    <option value={"5"}>Планирана дата за производство</option>
                                    <option value={"6"}>Планирана дата за експедиция</option>
                                    <option value={"9"}>Дата на производство</option>
                                </Form.Select>
                            </Col>
                        </Form.Group>
                    </Col>
                </Row>

                <div className={"max-h-70vh min-h-400px scrollable mt-2 p-3 border"}>
                    <Table size={"sm"} bordered hover>
                        <thead style={{zIndex: 1}}>
                        <tr>
                            {
                                onSelect !== undefined && <th rowSpan={2}/>
                            }
                            <th rowSpan={2}>Ф-ма:</th>
                            <th rowSpan={2}>Карта No:</th>
                            <th rowSpan={2}>Запитване</th>
                            <th rowSpan={2}><I18Label label={"Клиент"}/></th>
                            <th rowSpan={2}>Относно</th>
                            <th rowSpan={2}>Място</th>
                            <th rowSpan={2}>Краен срок до</th>
                            <th colSpan={2} className={"text-center"}>Планирана дата за</th>
                            <th rowSpan={2}>Дата на произвеждане</th>
                            <th rowSpan={2}>Eкспедиция</th>
                            <th rowSpan={2}>Статус</th>
                            <th rowSpan={2}>Коментари</th>
                            <th rowSpan={2} className={"text-center"} title={"Изчисти филтъра"}>
                                <CancelIconButton onClick={() => setFilter({...initialProductionPlanFilter})}/>
                            </th>
                        </tr>
                        <tr>
                            <th className={"w-75px text-center"}>Производство</th>
                            <th className={"w-75px text-center"} style={{maxWidth: "80px"}}>Експедиция</th>
                        </tr>
                        <ProductionPlanFilter
                            filter={filter}
                            setFilter={setFilter}
                            hasSelect={onSelect !== undefined}
                        />
                        </thead>
                        <tbody>
                        {
                            loading ? <tr>
                                <td colSpan={13} className={"text-center"}>
                                    <Spinner animation={"border"}/>
                                </td>
                            </tr> : error ? <tr>
                                    <td colSpan={13} className={"text-center"}>
                                        <span className={"text-danger"}>Възникна грешка при зареждане на данните</span>
                                    </td>
                                </tr> :
                                tableRows
                        }
                        {
                            constraintViewMode === "" &&
                            <tr>
                                <td colSpan={13} className={"text-center"}>
                                    <span
                                        className={"text-danger"}>Моля, изберете поне един критерий за филтриране.</span>
                                </td>
                            </tr>
                        }
                        {
                            (cards && cards.length === 100 && !(["Чакащи", "Изпълнена", "В производство"].includes(constraintViewMode))) &&
                            <tr>
                                <td colSpan={13} className={"text-center"}>
                                    <span
                                        className={"text-danger"}>Внимание! Показани са само първите 100 резултата.</span>
                                </td>
                            </tr>
                        }
                        </tbody>
                    </Table>
                </div>

                {
                    printCard && <ProductionPlanPrintPreview zIndex={1200} card={printCard}
                                                             onDismiss={() => setPrintCard(undefined)}/>
                }
            </Container>
        </LoadingErrorContent>
    );
}

export default ProductionPlanTable;
