import {useEffect, useMemo, useState} from "react";
import {Form, Table} from "react-bootstrap";
import useWarehouseItemsForObject from "../hooks/useWarehouseItemsForObject";
import LoadingErrorContent from "../../../common/LoadingErrorContent";
import {dateToString, removeEmptyFields, zeroToEmptyString, zeroToEmptyStringAsNumber} from "../../../common";
import useDebounce from "../../../hooks/UseDebounce";
import {addDoc, collection, query, updateDoc, where} from "firebase/firestore";
import {fbDb} from "../../../App";
import {WarehouseItemsType, WarehouseItemType} from "../hooks/useWarehouseInItems";
import {useWarehouseRequestsRepo} from "../warehouse-transactions/warehouse-request/useWarehouseRequestsRepo";
import CenterAlignedCell from "../../../common/CenterAlignedCell";
import {useCollection} from "react-firebase-hooks/firestore";
import {productionCardConverter, ProductionCardType} from "../../production/types/ProductionCardType";
import I18Label from "../../../i18/i18label";

export interface WarehouseObjectComponentProps {
    warehouseId: string
}


export default function WarehouseObjectComponent({warehouseId}: WarehouseObjectComponentProps) {
    const [editingItem, setEditingItem] = useState<WarehouseItemType | null>(null);
    const [editingCell, setEditingCell] = useState("");
    const [working, setWorking] = useState(false);
    const [filterSku, setFilterSku] = useState("");
    const [filterName, setFilterName] = useState("");
    const [items, loading, error] = useWarehouseItemsForObject(warehouseId ?? "");
    const {items: requestItems, loading: requestLoading, error: requestError} = useWarehouseRequestsRepo({
        warehouseId: warehouseId,
        createdAtFrom: undefined,
        createdAtTo: undefined,
        isActive: true,
    });
    const [filteredItems, setFilteredItems] = useState<WarehouseItemsType>([]);

    const [productionCardData, loadingProductionCard, errorProductionCard] = useCollection(
        query(collection(fbDb, "productionCards"),
            where("productionStatus", "in", ["Чакащи", "В производство", "Изпълнена"]),
        ).withConverter(productionCardConverter)
    );
    const [productionCards, setProductionCards] = useState<ProductionCardType[]>([]);

    useEffect(() => {
        if (productionCardData) {
            setProductionCards(productionCardData.docs.map(d => d.data()));
        } else setProductionCards([]);
    }, [productionCardData]);

    useDebounce(() => {
        if (items) {
            setFilteredItems(items.filter(
                r => (!filterName || filterName.length === 0 || r.name.toLowerCase().indexOf(filterName.toLowerCase()) > -1) &&
                    (!filterSku || filterSku.length === 0 || !r.sku || r.sku.toLowerCase().indexOf(filterSku.toLowerCase()) > -1)
            ));
        } else setFilteredItems([]);
    }, [items, filterName, filterSku], 1);


    function getReservedQty(warehouseId: string, productId: string | undefined) {
        if (!productId) return 0;

        let reservedQty = 0;
        productionCards.forEach(c => {
            c.materials.forEach(m => {
                if (m.productId === productId && m.warehouseId === warehouseId) {
                    reservedQty += m.productQty;
                }
            });
        });

        return reservedQty;
    }

    async function updateData(item: WarehouseItemType) {
        setWorking(true);
        if (item.ref) {
            await updateDoc(item.ref, {qty: item.qty, minQty: item.minQty});
        } else {
            await addDoc(collection(fbDb, "warehouseItems"), removeEmptyFields<WarehouseItemType>(item));
        }

        setEditingItem(null);
        setWorking(false);
    }

    function generateRequestQtyCell(p: WarehouseItemType) {
        let requests: string[] = [];
        let qty: string[] = [];
        let expectedDeliveryDate: string[] = [];

        requestItems.forEach(r => {
            r.items.forEach(i => {
                if (i.product.id === p.productId) {
                    requests.push(r.requestNumber?.toString().padStart(8, '0') ?? "");
                    qty.push(zeroToEmptyString(i.qty));
                    expectedDeliveryDate.push(dateToString(i.expectedDeliveryDate));
                }
            });
        });

        return (<>
            <td className={"text-end w-100px bg-secondary-light"}> {requests.map(
                r => <div>{r}</div>
            )} </td>
            <td className={"text-end w-100px bg-secondary-light"}> {qty.map(
                q => <div>{q}</div>
            )} </td>
            <td className={"text-end w-100px bg-secondary-light"}> {expectedDeliveryDate.map(
                d => <div>{d}</div>
            )} </td>
        </>);
    }

    let tableRows = useMemo(() => {
        return <>
            {
                filteredItems.map(
                    p => {
                        const reservedQty = getReservedQty(warehouseId, p.productId);
                        return (
                            <tr key={p.id}>
                                <td> {p.sku} </td>
                                <td> {p.name} </td>
                                <td> {p.isOwnProduction ? <I18Label label={"ДА"}/> : ""} </td>
                                <td><I18Label label={p.dimens}/></td>
                                <td className={"text-end w-100px"}> {zeroToEmptyStringAsNumber(p.singlePrice)} </td>
                                <td className={"text-end w-100px"}> {zeroToEmptyString(p.availableQty)} </td>
                                <td className={"text-end w-100px"}> {zeroToEmptyString(reservedQty)}</td>
                                <td className={"text-end w-100px bg-secondary-light"}> {zeroToEmptyString((p.availableQty ?? 0) - reservedQty)}</td>
                                <td className={"text-end w-100px"}> {zeroToEmptyString(p.minQty)} </td>
                                {
                                    generateRequestQtyCell(p)
                                }
                            </tr>
                        );
                    }
                )
            }
            {
                requestItems.filter(r => r.items.filter(i => !filteredItems.map(f => f.productId).includes(i.product.id ?? "")).length > 0).map(
                    r => r.items.filter(i => !filteredItems.map(f => f.productId).includes(i.product.id ?? "")).map(
                        (i, iidx) => <tr key={r.id + "_" + i.product.id + "_" + iidx}>
                            <td> {i.product.sku} </td>
                            <td> {i.product.name} </td>
                            <td></td>
                            <td><I18Label label={i.product.dimension}/>...</td>
                            <td className={"text-end w-100px"}> {zeroToEmptyStringAsNumber(i.product.price)} </td>
                            <td className={"text-end w-100px"}></td>
                            <td className={"text-end w-100px"}></td>
                            <td className={"text-end w-100px"}></td>
                            <td className={"text-end w-100px"}></td>
                            <td className={"text-end w-100px bg-secondary-light"}> {r.requestNumber?.toString().padStart(8, "0")} </td>
                            <td className={"text-end w-100px bg-secondary-light"}> {zeroToEmptyString(i.qty)} </td>
                            <td className={"text-end w-100px bg-secondary-light"}> {dateToString(i.expectedDeliveryDate)} </td>
                        </tr>
                    )
                )
            }
        </>

    }, [filteredItems, editingItem, editingCell, working, requestItems, loadingProductionCard, productionCards]);

    return <LoadingErrorContent loading={loading || requestLoading || loadingProductionCard}
                                error={error?.message || requestError?.message}>
        <div className={"max-h-75vh scrollable"}>
            <Table size={"sm"} bordered hover>
                <thead>
                <tr>
                    <th rowSpan={2}><I18Label label={"Код"}/></th>
                    <th rowSpan={2}><I18Label label={"Продукт"}/></th>
                    <th rowSpan={2}><I18Label label={"Собств.пр."}/></th>
                    <th rowSpan={2}><I18Label label={"Мярка"}/></th>
                    <th className={"text-end"} rowSpan={2}><I18Label label={"Дост.цена"}/></th>
                    <CenterAlignedCell header colSpan={4}><I18Label label={"Количество"}/></CenterAlignedCell>
                    <CenterAlignedCell header colSpan={3}><I18Label label={"Заявки"}/></CenterAlignedCell>
                </tr>
                <tr>
                    <th className={"text-end"}><I18Label label={"Налично"}/></th>
                    <th className={"text-end"}><I18Label label={"Резерв."}/></th>
                    <th className={"text-end"}><I18Label label={"Свободно"}/></th>
                    <th className={"text-end"}><I18Label label={"Мин"}/></th>
                    <th className={"text-end"}><I18Label label={"Заявка No:"}/></th>
                    <th className={"text-end"}><I18Label label={"Заяв.к-во"}/></th>
                    <th className={"text-end"}><I18Label label={"Доставка"}/></th>
                </tr>
                <tr>
                    <td>
                        <Form.Control size={"sm"} value={filterSku}
                                      onChange={(e) => setFilterSku(e.target.value)}/>
                    </td>
                    <td>
                        <Form.Control size={"sm"} value={filterName}
                                      onChange={(e) => setFilterName(e.target.value)}/>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                </thead>
                <tbody>
                {tableRows}
                </tbody>
            </Table>
        </div>
    </LoadingErrorContent>;

}
