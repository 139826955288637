import {Spinner, Table} from "react-bootstrap";
import {dateToString, dateWithZeroTime} from "../../../common";
import React, {FC, useEffect, useState} from "react";
import CenterAlignedCell from "../../../common/CenterAlignedCell";
import {expeditionConverter, ExpeditionType} from "../../../fb-converters/expedition-converter";
import {ExpeditionPlanFilterType} from "./ExpeditionPlanFilter";
import useFirebaseCollection from "../../../hooks/useFirebaseCollection";
import ExpeditionCourse, {ExpeditionCourseType} from "./ExpeditionCourse";
import {QueryConstraint, where} from "firebase/firestore";
import ExpeditionPlanPrintPreview from "../print/expedition/ExpeditionPlanPrintPreview";
import I18Label from "../../../i18/i18label";

type ExpeditionPlanTableProps = {
    zIndex: number,
    filter: ExpeditionPlanFilterType,
    viewMode?: "active" | "completed"
}

const ExpeditionPlanTable: FC<ExpeditionPlanTableProps> = ({zIndex, filter, viewMode}) => {
    const onSelect = false;

    const expeditionConstraints: QueryConstraint[] = [];
    if (filter.fromDate) {
        expeditionConstraints.push(where("expeditionDate", ">=", dateWithZeroTime(filter.fromDate)!));
    }
    if (filter.toDate) {
        const toDate = dateWithZeroTime(filter.toDate)!;
        toDate.setDate(toDate.getDate() + 1);
        expeditionConstraints.push(where("expeditionDate", "<=", toDate));
    }
    if (filter.client) {
        expeditionConstraints.push(where("client.id", "==", filter.client.id));
    }
    if (filter.driver) {
        expeditionConstraints.push(where("driver.id", "==", filter.driver.id));
    }
    if (filter.car) {
        expeditionConstraints.push(where("car.id", "==", filter.car.id));
    }

    const [expeditionsData, loadExpeditions, errorExpeditions] =
        useFirebaseCollection("expedition", expeditionConstraints, expeditionConverter);


    const [expeditions, setExpeditions] = useState<ExpeditionType[] | undefined>(undefined);
    const [courses, setCourses] = useState<ExpeditionCourseType[] | undefined>();
    const [filteredCourses, setFilteredCourses] = useState<ExpeditionCourseType[] | undefined>();
    const [printCourse, setPrintCourse] = useState<ExpeditionCourseType | undefined>();

    function isExpeditionActive(exp: ExpeditionType) {
        return exp.status === "ПЛАНИРАНА" || exp.status === "ИЗПЪЛНЯВА СЕ";
    }

    useEffect(
        () => {
            setExpeditions(undefined);
            setCourses(undefined);

            if (expeditionsData) {
                const corses: ExpeditionCourseType[] = [];
                const exps: ExpeditionType[] = [];
                expeditionsData.docs.forEach(d => {
                    const exp = d.data() as ExpeditionType;
                    exps.push(exp);
                    const course: ExpeditionCourseType = {
                        id: exp.id!,
                        expeditionDate: exp.expeditionDate!,
                        driverId: exp.driver!.id,
                        driverName: exp.driver!.name,
                        carId: exp.car!.id,
                        carName: exp.car!.name,
                        carMaxWeight: exp.car!.maxWeight,
                        totalQuantity: exp.productQty || 0,
                        totalParcels: exp.weights?.leaving?.parcels || 0,
                        totalPallets: exp.weights?.leaving?.pallets || 0,
                        totalScrap: exp.weights?.arriving?.scrap || 0,
                        totalDelivery: exp.weights?.arriving?.delivery || 0,
                        totalPaletsLeaving: exp.palets?.leaving || 0,
                        totalPaletsArriving: exp.palets?.arriving || 0,
                        viewStatus: isExpeditionActive(exp) ? "active" : "completed",
                    }
                    const expeditonDate = dateToString(exp.expeditionDate);
                    const existingCourse = corses.find(c =>
                        dateToString(c.expeditionDate) === expeditonDate &&
                        c.driverId === course.driverId &&
                        c.carId === course.carId);
                    if (!existingCourse) {
                        corses.push(course);
                    } else {
                        existingCourse.totalParcels += course.totalParcels;
                        existingCourse.totalPallets += course.totalPallets;
                        existingCourse.totalScrap += course.totalScrap;
                        existingCourse.totalDelivery += course.totalDelivery;
                        existingCourse.totalPaletsLeaving += course.totalPaletsLeaving;
                        existingCourse.totalPaletsArriving += course.totalPaletsArriving;
                        const isCourseActive = isExpeditionActive(exp);
                        if (isCourseActive) {
                            existingCourse.viewStatus = "active";
                        }
                    }
                });

                setExpeditions(exps);
                setCourses(corses);
            }
        }, [expeditionsData]
    );

    useEffect(() => {
        if (courses) {
            setFilteredCourses(courses.filter(c =>
                (viewMode === "active" && c.viewStatus === "active") ||
                (viewMode === "completed" && c.viewStatus === "completed")
            ));
        } else {
            setFilteredCourses(undefined);
        }
    }, [courses, viewMode]);

    function getCourseExpeditions(printCourse: ExpeditionCourseType, expeditionTypes: ExpeditionType[]) {
        return expeditionTypes.filter(e =>
            dateToString(e.expeditionDate) === dateToString(printCourse.expeditionDate) &&
            e.driver?.id === printCourse.driverId &&
            e.car?.id === printCourse.carId);
    }

    return (
        <>
            <Table size={"sm"} hover bordered>
                <thead style={{backgroundColor: "white"}}>
                <tr>
                    <CenterAlignedCell header style={{width: onSelect ? 120 : 90}} rowSpan={3}></CenterAlignedCell>
                    <CenterAlignedCell header className={"w-100px"} rowSpan={3}>Дата</CenterAlignedCell>
                    <CenterAlignedCell header rowSpan={3}>Шофьор</CenterAlignedCell>
                    <CenterAlignedCell header rowSpan={3}>Автомобил</CenterAlignedCell>
                    <CenterAlignedCell header rowSpan={3}>Запитване</CenterAlignedCell>
                    <CenterAlignedCell header rowSpan={3}><I18Label label={"Клиент"} /></CenterAlignedCell>
                    <CenterAlignedCell header rowSpan={3}>Посока</CenterAlignedCell>
                    <CenterAlignedCell header rowSpan={3}>Адрес</CenterAlignedCell>
                    <CenterAlignedCell header colSpan={2} rowSpan={2}>Лице за контакт</CenterAlignedCell>
                    <CenterAlignedCell header rowSpan={3}>Допълнителна информация</CenterAlignedCell>
                    <CenterAlignedCell header rowSpan={3}>Кол.</CenterAlignedCell>
                    <CenterAlignedCell header colSpan={5} style={{width: "320px"}}>Тегло, кг</CenterAlignedCell>
                    <CenterAlignedCell header colSpan={2} rowSpan={2} style={{width: "160px"}}>Палети, бр</CenterAlignedCell>
                    <CenterAlignedCell header rowSpan={3}>Статус</CenterAlignedCell>
                </tr>
                <tr>
                    <CenterAlignedCell header colSpan={2} style={{width: "160px"}}>Заминаване</CenterAlignedCell>
                    <CenterAlignedCell header colSpan={3} style={{width: "160px"}}>Връщане</CenterAlignedCell>
                </tr>
                <tr>
                    <CenterAlignedCell header>Име</CenterAlignedCell>
                    <CenterAlignedCell header>Телефон</CenterAlignedCell>
                    <CenterAlignedCell header style={{width: "80px"}}>Пратка</CenterAlignedCell>
                    {/*<CenterAlignedCell header style={{width: "80px"}}>Палет</CenterAlignedCell>*/}
                    <CenterAlignedCell header style={{width: "80px"}}>Натов.</CenterAlignedCell>

                    <CenterAlignedCell header style={{width: "80px"}}>Скрап</CenterAlignedCell>
                    <CenterAlignedCell header style={{width: "80px"}}>Доставка</CenterAlignedCell>
                    <CenterAlignedCell header style={{width: "80px"}}>Натов.</CenterAlignedCell>

                    <CenterAlignedCell header style={{width: "80px"}}>Замин.</CenterAlignedCell>
                    <CenterAlignedCell header style={{width: "80px"}}>Връщане</CenterAlignedCell>
                </tr>
                </thead>
                <tbody>
                {
                    loadExpeditions &&
                    <tr>
                        <td colSpan={6} className={"text-center fw-bold"}>
                            <Spinner animation={"border"}/>
                        </td>
                    </tr>
                }
                {
                    filteredCourses && filteredCourses.length === 0 &&
                    <tr>
                        <td colSpan={99} className={"text-center fw-bold"}>Няма планирани курсове</td>
                    </tr>
                }
                {
                    filteredCourses && filteredCourses.length > 0 &&
                    filteredCourses.map(
                        course => <>
                            <ExpeditionCourse
                                zIndex={zIndex + 1}
                                key={course.id}
                                course={course}
                                expeditions={expeditions ?? []}
                                onPrint={() => setPrintCourse(course)}
                            />
                        </>
                    )
                }
                </tbody>
            </Table>
            {
                printCourse &&
                <ExpeditionPlanPrintPreview
                    zIndex={zIndex + 2}
                    expeditionCourse={printCourse}
                    expeditions={getCourseExpeditions(printCourse, expeditions ?? [])}
                    onDismiss={() => setPrintCourse(undefined)
                }
                />
            }
        </>
    );
}
export default ExpeditionPlanTable;
