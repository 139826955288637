import {
    Modal,
    ModalTitle,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import React, {useEffect, useState} from "react";
import {DIALOG_OPTIONS} from "../../../types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ModalBackground from "../../../common/ModalBackground";
import DialogCloseButton from "../../../common/DialogCloseButton";
import {OwnCompanyType} from "../../warehouse/hooks/useOwnCompanies";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import {OwnCompaniesList} from "./OwnCompaniesList";

export function OwnCompaniesDialog({zIndex, show, onClose, onSelect}: DIALOG_OPTIONS) {

    const [viewMode, setViewMode] = useState<"VIEW_LIST" | "VIEW_ADD" | "VIEW_EDIT" | "VIEW_DELETE">('VIEW_LIST');
    const [editDocument, setEditDocument] = useState<OwnCompanyType | null>(null);
    const [deleteDocument, setDeleteDocument] = useState<OwnCompanyType | null>(null);
    const [addDocument, setAddDocument] = useState(false);

    useEffect(() => {
        setViewMode(editDocument ? "VIEW_EDIT" : "VIEW_LIST");
    }, [editDocument])

    useEffect(() => {
        setViewMode(deleteDocument ? "VIEW_DELETE" : "VIEW_LIST");
    }, [deleteDocument])

    useEffect(() => {
        setViewMode(addDocument ? "VIEW_ADD" : "VIEW_LIST");
    }, [addDocument])

    return (
        <ModalBackground zIndex={zIndex}>
            <Modal show={show} size={"sm"} style={{zIndex: (zIndex + 1)}}>
                <ModalHeader>
                    <ModalTitle>
                        <FontAwesomeIcon icon={faHome}/> Собствени фирми
                    </ModalTitle>
                    <DialogCloseButton onClose={() => onClose(null)}/>
                </ModalHeader>
                {
                    <OwnCompaniesList onClose={onClose} isVisible={viewMode === "VIEW_LIST"}
                                      onSelect={onSelect}
                                      onAdd={() => setAddDocument(true)}
                                      onEdit={(doc: OwnCompanyType) => {
                                          setEditDocument(doc);
                                      }}
                                      onDelete={(doc: OwnCompanyType) => {
                                          setDeleteDocument(doc);
                                      }}
                    />
                }
                {
                    // viewMode === "VIEW_EDIT" && editDocument &&
                    // <WarehouseObjectDetails object={editDocument}
                    //                         zIndex={(zIndex || 1100) + 10}
                    //                         onDone={() => {
                    //                   setEditDocument(null)
                    //               }}/>
                }
                {
                    // viewMode === "VIEW_DELETE" && deleteDocument &&
                    // <WarehouseObjectDelete doc={deleteDocument} onDone={() => {
                    //     setDeleteDocument(null)
                    // }}/>
                }
                {
                    // viewMode === "VIEW_ADD" && addDocument &&
                    // <WarehouseObjectDetails object={null}
                    //                         zIndex={(zIndex || 1100) + 10}
                    //                         onDone={() => {
                    //                   setAddDocument(false)
                    //               }}/>
                }

            </Modal>
        </ModalBackground>
    )
}
