import {Button, Col, Form, ModalBody, ModalFooter, Row, Spinner, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCancel,
    faCircleArrowDown,
    faEdit,
    faPlus,
    faSearch,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import {
    orderBy, where,
} from "firebase/firestore";
import React, {FC, useEffect, useState} from "react";
import LoadDataFromServerError from "../../../common/LoadDataFromServerError";
import useDebounce from "../../../hooks/UseDebounce";
import {dateToString, zeroToEmptyString, zeroToEmptyStringAsCurrency} from "../../../common";
import {ProductionType} from "./hooks/useProductionOnce";
import useProduction from "./hooks/useProduction";
import RightAlignedCell from "../../../common/RightAlignedCell";
import PrintIconButton from "../../../common/icon-buttons/PrintIconButton";
import Frame, {FrameContextConsumer} from 'react-frame-component';
import PrintProtocolFrame from "./PrintProtocolFrame";
import OwnCompanyWarehouseSelector from "../../warehouse/warehouse/OwnCompanyWarehouseSelector";

interface Props {
    isVisible: boolean;
    onAdd: (selectedOwnCompanyId: string, selectedWarehouseId: string) => any;
    onEdit: (item: ProductionType) => any;
    onDelete: (item: ProductionType) => any;
    onClose: (result: any) => any;
    onSelect?: (result: any) => any;
}

export const ProductionList = ({isVisible, onAdd, onEdit, onDelete, onClose, onSelect}: Props) => {
    const [selectedOwnCompanyId, setSelectedOwnCompanyId] = useState<string | undefined>();
    const [selectedWarehouseId, setSelectedWarehouseId] = useState<string | undefined>();

    const [filterNumber, setFilterNumber] = useState('')
    const [filterName, setFilterName] = useState('')
    const [filterSku, setFilterSku] = useState('');
    const [filterInquiry, setFilterInquiry] = useState('');

    const [products, loading, error] = useProduction([
        where("warehouseId", '==', selectedWarehouseId ?? ""),
        orderBy("name", "asc")
    ]);
    const [filtered, setFilteredUsers] = useState<any[]>([])

    const [printedProtocol, setPrintedProtocol] = useState<any>(null);

    function getInquiryLabel(r: ProductionType) {
        return r.inquiryId ? `No: ${r.inquiryNumber ?? ""} / ${dateToString(r.inquiryDate) ?? ""} - ${r.clientName ?? ""}` : '';
    }

    useDebounce(() => {
        if (products) {
            setFilteredUsers(products.filter(
                (r: ProductionType) => {
                    return (!filterName || filterName.length === 0 || r.name.toLowerCase().indexOf(filterName.toLowerCase()) > -1) &&
                        (!filterNumber || filterNumber.length === 0 || (r.number && r.number.toLowerCase().indexOf(filterNumber.toLowerCase()) > -1)) &&
                        (!filterSku || filterSku.length === 0 || (r.sku && r.sku.toLowerCase().indexOf(filterSku.toLowerCase()) > -1)) &&
                        (!filterInquiry || filterInquiry.length === 0 || (getInquiryLabel(r).toLowerCase().indexOf(filterInquiry.toLowerCase()) > -1));
                }
            ));
        } else setFilteredUsers([]);
    }, [products, filterNumber, filterName, filterSku, filterInquiry], 1);

    function isLoading() {
        return loading;
    }

    function printProtocol(r: any) {
        setPrintedProtocol({...r});
    }

    return (
        <>
            <Frame height={0} initialContent={`<!DOCTYPE html><html><head >
<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css"
  integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65"
  crossorigin="anonymous"
/>
<style>
@media print {
  .table-bordered>:not(caption)>*>* {
    border-width: 4px !important;
  }
  }
</style>
</head><body><div class="frame-root"></div></body></html>`}>
                <FrameContextConsumer>
                    {
                        // Callback is invoked with iframe's window and document instances
                        ({document, window}: any) => {
                            return (<PrintProtocolFrame window={window} protocol={printedProtocol}/>)
                        }
                    }
                </FrameContextConsumer>
            </Frame>
            <ModalBody className={isVisible ? "" : "d-none"}>
                <Row>
                    <Col>
                        <OwnCompanyWarehouseSelector
                            selectedCompanyId={selectedOwnCompanyId}
                            selectedWarehouseId={selectedWarehouseId}
                            onChange={(companyId, warehouseId) => {
                                setSelectedOwnCompanyId(companyId);
                                setSelectedWarehouseId(warehouseId);
                            }}
                        />
                    </Col>
                </Row>

                {isLoading() && <Spinner animation={"border"}/>}

                {
                    !isLoading() && error && <LoadDataFromServerError/>
                }

                {
                    !loading && !error && <div className={"max-h-60vh scrollable"}>
                        <Table size={"sm"} borderless hover>
                            <thead style={{backgroundColor: "white"}}>
                            <tr>
                                <th style={{width: onSelect ? 120 : 90}}></th>
                                <th className={"w-100px"}>Дата</th>
                                <th className={"w-100px"}>Номер</th>
                                <th className={"w-120px"}>SKU</th>
                                <th>Име</th>
                                <RightAlignedCell header>Кол.</RightAlignedCell>
                                <th>От заявка</th>
                                <th className={"w-120px text-end"}>Стойност</th>
                            </tr>
                            <tr>
                                <td className={"text-start"}>
                                    <FontAwesomeIcon icon={faSearch}/>
                                </td>
                                <td/>
                                <td>
                                    <Form.Control size={"sm"} value={filterNumber}
                                                  onChange={(e) => setFilterNumber(e.target.value)}/>
                                </td>
                                <td>
                                    <Form.Control size={"sm"} value={filterSku}
                                                  onChange={(e) => setFilterSku(e.target.value)}/>
                                </td>
                                <td>
                                    <Form.Control size={"sm"} value={filterName}
                                                  onChange={(e) => setFilterName(e.target.value)}/>
                                </td>
                                <td/>
                                <td>
                                    <Form.Control size={"sm"} value={filterInquiry}
                                                  onChange={(e) => setFilterInquiry(e.target.value)}/>
                                </td>
                                <td>
                                    <Form.Control size={"sm"} disabled={true}/>
                                </td>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                loading &&
                                <tr>
                                    <td colSpan={6} className={"text-center fw-bold"}>
                                        <Spinner animation={"border"}/>
                                    </td>
                                </tr>
                            }
                            {
                                filtered && filtered.length === 0 &&
                                <tr>
                                    <td colSpan={6} className={"text-center fw-bold"}>Няма произведени продукти</td>
                                </tr>
                            }
                            {

                                filtered && filtered.length > 0 && filtered.map(
                                    r =>
                                        <tr key={r.id} className={"tr-bordered"}>
                                            <td>
                                                <Button size={"sm"} variant={"outline-secondary"}
                                                        onClick={() => onEdit(r)}
                                                >
                                                    <FontAwesomeIcon icon={faEdit}/>
                                                </Button>&nbsp;
                                                <Button size={"sm"} variant={"outline-danger"}
                                                        onClick={() => {
                                                            onDelete(r)
                                                        }}
                                                >
                                                    <FontAwesomeIcon icon={faTrash}/>
                                                </Button>&nbsp;
                                                <PrintIconButton size={"sm"} variant={"outline-primary"}
                                                                 onClick={() => {
                                                                     printProtocol(r)
                                                                 }}/>&nbsp;
                                                {
                                                    onSelect &&
                                                    <Button size={"sm"} variant={"outline-success"}
                                                            onClick={() => {
                                                                onSelect({...r, id: r.id, ref: r.ref})
                                                            }}
                                                    >
                                                        <FontAwesomeIcon icon={faCircleArrowDown}/>
                                                    </Button>
                                                }
                                            </td>
                                            <td>
                                                {dateToString(r.productionDate)}
                                            </td>
                                            <td>
                                                {r.number}
                                            </td>
                                            <td>
                                                {r.sku}
                                            </td>
                                            <td>
                                                {r.name}
                                            </td>
                                            <RightAlignedCell>
                                                {zeroToEmptyString(r.qty)}
                                            </RightAlignedCell>
                                            <td>
                                                {getInquiryLabel(r)}
                                            </td>
                                            <td className={"text-end"}>
                                                {zeroToEmptyStringAsCurrency(r.price)}
                                            </td>
                                        </tr>
                                )
                            }
                            </tbody>

                        </Table>
                    </div>
                }

            </ModalBody>

            <ModalFooter className={isVisible ? "" : "d-none"}>
                <Col className={"text-end"}>
                    {
                        (selectedOwnCompanyId !== undefined && selectedWarehouseId !== undefined) &&
                        <Button onClick={() => onAdd(selectedOwnCompanyId, selectedWarehouseId)}>
                            <FontAwesomeIcon icon={faPlus}/> Добави нов
                        </Button>
                    }
                    &nbsp;
                    <Button variant={"outline-secondary"} onClick={() => {
                        onClose(null)
                    }}>
                        <FontAwesomeIcon icon={faCancel}/> Затвори
                    </Button>
                </Col>
            </ModalFooter>
        </>
    )
}
