import {FC} from "react";
import {useHookstate} from "@hookstate/core";
import appDb from "../../../global-state/global-db";
import {Form, ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import {OwnCompaniesType} from "../../warehouse/hooks/useOwnCompanies";
import useOwnCompaniesRepo from "../../warehouse/hooks/useOwnCompaniesRepo";
import {WarehouseObjectType} from "../../warehouse/hooks/useWarehouseObjects";
import useWarehouseObjectsRepo from "../../warehouse/hooks/useWarehouseObjectsRepo";

interface WarehouseObjectsDropdownProps {
    ownCompanyId?: string,
    selectedWarehouseObjectId?: string,
    onChange: (selectedWarehouseObjectId: string) => void,
    readOnly?: boolean
}

const WarehouseObjectsDropdown: FC<WarehouseObjectsDropdownProps> = ({
                                                                         ownCompanyId,
                                                                         selectedWarehouseObjectId,
                                                                         onChange,
                                                                         readOnly
                                                                     }) => {
    const {ownCompanyWarehouseObjects} = useWarehouseObjectsRepo(ownCompanyId);

    return (
        <Form.Select
            disabled={readOnly}
            onChange={(e) => onChange(e.target.value)}
            value={selectedWarehouseObjectId ?? ""}
        >
            {
                ownCompanyWarehouseObjects.map(warehouseObject => (
                    <option key={warehouseObject.id} value={warehouseObject.id}>
                        {warehouseObject.name ?? ""}
                    </option>
                ))
            }
        </Form.Select>
    )
}

export default WarehouseObjectsDropdown;
