import {Card, Col, Container, Form, Row, Spinner, Table} from "react-bootstrap";
import {useEffect, useMemo, useState} from "react";
import CRMSummaryReportFilter, {CRMSummaryReportFilterType} from "./CRMSummaryReportFilter";
import {fbDb} from "../../../../App";
import {
    collection,
    doc,
    getDocs,
    query,
    QueryConstraint,
    updateDoc,
    where,
    writeBatch
} from "firebase/firestore";
import {customerInquiryConverter, CustomerInquiryType} from "../../../../fb-converters/cutomerInquiry-converter";
import {customerInvoiceConverter, CustomerInvoiceType} from "../../../../fb-converters/cutomerInvoice-converter";
import {customerOfferConverter} from "../../../../fb-converters/cutomerOffer-converter";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {dateToString} from "../../../../common";
import I18Label from "../../../../i18/i18label";
import {productionCardConverter} from "../../../production/types/ProductionCardType";
import NotesIconButton from "../../../../common/icon-buttons/NotesIconButton";
import SaveIconButton from "../../../../common/icon-buttons/SaveIconButton";
import {expeditionConverter} from "../../../../fb-converters/expedition-converter";

type reportItemType = {
    comments: string | null;
    randId: string;
    clientId: string;
    clientName: string;

    inquiryId: string;
    inquiryNumber: string;
    inquiryDate: string;

    offerId: string;
    offerNumber: string;
    offerDate: string;
    offerAuthor: string;

    confirmationId: string;
    confirmationNumber: string;
    confirmationDate: string;

    proformaId: string[];
    proformaNumber: string[];
    proformaDate: string[];

    invoiceId: string[];
    invoiceNumber: string[];
    invoiceDate: string[];

    productionCardId: string[];
    productionCardNumber: string[];
    productionCardDate: string[];
    productionCardStatus: string[];
}

export default function CRMSummaryReportPage() {
    const [filter, setFilter] = useState<CRMSummaryReportFilterType | null>(null);
    const [editInquiryId, setEditInquiryId] = useState<string | undefined>(undefined);
    const [editInquiryComments, setEditInquiryComments] = useState<string | undefined>(undefined);

    const filterControl = useMemo(
        () => <CRMSummaryReportFilter onChange={setFilter}/>,
        []
    );

    // async function calculate() {
    //     return;
    //     const allInquiriesData = await getDocs(
    //         query(
    //             collection(fbDb, 'customerInquiries').withConverter(customerInquiryConverter),
    //             where('inquiryDate', '>=', new Date(2024, 1, 1)),
    //             // limit(10)
    //         )
    //     );
    //     const allInquiries = allInquiriesData.docs.map(d => d.data());
    //
    //     const allInvoicesData = await getDocs(
    //         query(
    //             collection(fbDb, 'customerInvoices').withConverter(customerInvoiceConverter),
    //             where('invoiceDate', '>=', new Date(2024, 1, 1)),
    //         )
    //     );
    //     const allInvoices = allInvoicesData.docs.map(d => d.data());
    //
    //     for (const i of allInquiries) {
    //         const inquiryAllOffersData = await getDocs(
    //             query(
    //                 collection(fbDb, `customerInquiries/${i.id}/offers`).withConverter(customerOfferConverter)
    //             )
    //         );
    //         const inquiryAllOffers = inquiryAllOffersData.docs.map(d => d.data());
    //
    //         await updateDoc(
    //             doc(fbDb, `customerInquiries/${i.id}`),
    //             {
    //                 offers: inquiryAllOffers.map(o => ({
    //                     offerId: o.id,
    //                     offerDate: o.offerDate,
    //                     offerNumber: o.offerNumber
    //                 }))
    //             }
    //         );
    //
    //     }
    //     ;
    // }

    const invoiceQuery = useMemo(
        () => {
            const hasFilter = filter !== null && filter.fromDate && filter.toDate;
            if (!hasFilter) return null;
            const invoicesCollection = collection(fbDb, 'customerInvoices').withConverter(customerInvoiceConverter);
            const constraints: QueryConstraint[] = [];
            if (filter.fromDate) {
                constraints.push(where('inquiryDate', '>=', filter.fromDate));
            }
            if (filter.toDate) {
                constraints.push(where('inquiryDate', '<=', filter.toDate));
            }
            if (filter.client) {
                constraints.push(where('receiver.clientId', '==', filter.client.id));
            }

            return query(invoicesCollection, ...constraints);
        },
        [filter]
    );

    const inquiryQuery = useMemo(
        () => {
            const hasFilter = filter !== null && filter.fromDate && filter.toDate;
            if (!hasFilter) return null;
            const inquiriesCollection = collection(fbDb, 'customerInquiries').withConverter(customerInquiryConverter);
            const constraints: QueryConstraint[] = [];
            if (filter.fromDate) {
                constraints.push(where('inquiryDate', '>=', filter.fromDate));
            }
            if (filter.toDate) {
                constraints.push(where('inquiryDate', '<=', filter.toDate));
            }
            if (filter.client) {
                constraints.push(where('client.id', '==', filter.client.id));
            }

            return query(inquiriesCollection, ...constraints);
        },
        [filter]
    );


    const productionCardQuery = useMemo(
        () => {
            const hasFilter = filter !== null && filter.fromDate && filter.toDate;
            if (!hasFilter) return null;
            const productionCardCollection = collection(fbDb, 'productionCards').withConverter(productionCardConverter);
            const constraints: QueryConstraint[] = [];
            if (filter.fromDate) {
                constraints.push(where('inquiryDate', '>=', filter.fromDate));
            }
            if (filter.toDate) {
                constraints.push(where('inquiryDate', '<=', filter.toDate));
            }

            return query(productionCardCollection, ...constraints);
        },
        [filter]
    );

    const expeditionQuery = useMemo(
        () => {
            const hasFilter = filter !== null && filter.fromDate && filter.toDate;
            if (!hasFilter) return null;
            const expeditionCollection = collection(fbDb, 'expedition').withConverter(expeditionConverter);
            const constraints: QueryConstraint[] = [];
            constraints.push(where('status', '==', 'ЗАВЪРШЕНА'));
            if (filter.fromDate) {
                constraints.push(where('inquiry.inquiryDate', '>=', filter.fromDate));
            }
            if (filter.toDate) {
                constraints.push(where('inquiry.inquiryDate', '<=', filter.toDate));
            }

            return query(expeditionCollection, ...constraints);
        },
        [filter]
    );

    console.log(expeditionQuery);

    const [inquiryData, inquiryLoading, inquiryError] = useCollectionData<CustomerInquiryType>(inquiryQuery);
    const [invoiceData, invoiceLoading, invoiceError] = useCollectionData<CustomerInvoiceType>(invoiceQuery);
    const [productionCardData, productionCardLoading, productionCardError] = useCollectionData(productionCardQuery);
    const [expeditionData, expeditionLoading, expeditionError] = useCollectionData(expeditionQuery);

    const updateInquiryOffers = async (inquiryId: string) => {
        const inquiryAllOffersData = await getDocs(
            query(
                collection(fbDb, `customerInquiries/${inquiryId}/offers`).withConverter(customerOfferConverter)
            )
        );
        const inquiryAllOffers = inquiryAllOffersData.docs.map(d => d.data());

        await updateDoc(
            doc(fbDb, `customerInquiries/${inquiryId}`),
            {
                offers: inquiryAllOffers.map(o => ({
                    offerId: o.id,
                    offerDate: o.offerDate,
                    offerNumber: o.offerNumber
                }))
            }
        );
    }

    useEffect(() => {
        if (invoiceLoading || inquiryError) return;

        const batch = writeBatch(fbDb);
        inquiryData?.map(
            i => {
                if ((!i.offers || i.offers.length === 0) && i.id) {
                    updateInquiryOffers(i.id);
                }
            }
        );

    }, [inquiryData]);

    const reportData = useMemo(() => {
        if (inquiryData === undefined ||
            invoiceData === undefined ||
            productionCardData === undefined ||
            expeditionData === undefined
        ) return null;

        const result: reportItemType[] = [];
        for (const i of inquiryData) {
            const inquiryId = i.id ?? "";
            const inquiryNumber = i.inquiryNumber?.toString().padStart(6, "0") ?? "";
            const inquiryDate = dateToString(i.inquiryDate) ?? "";
            const clientId = i.client?.id ?? "";
            const clientName = i.client?.name ?? "";

            for (const o of i.offers) {
                const offerId = o.offerId ?? "";
                const offerNumber = o.offerNumber?.toString().padStart(6, "0") ?? "";
                const offerDate = dateToString(o.offerDate) ?? "";
                const offerAuthor = o.authorName ?? "";

                const confirmation = invoiceData.find(i => i.offerId === offerId && i.documentType === "потвърждение на поръчка");

                const confirmationId = confirmation?.id ?? "";
                const confirmationNumber = confirmation?.invoiceNumber?.toString().padStart(6, "0") ?? "";
                const confirmationDate = dateToString(confirmation?.invoiceDate) ?? "";

                const proforma = invoiceData.filter(i => i.offerId === offerId && i.documentType === "проформа фактура");
                const proformaId = proforma.map(p => p.id ?? "");
                const proformaNumber = proforma.map(p => p.invoiceNumber?.toString().padStart(6, "0") ?? "");
                const proformaDate = proforma.map(p => dateToString(p.invoiceDate) ?? "");

                const invoice = invoiceData.filter(i => i.offerId === offerId && i.documentType === "фактура");
                const invoiceId = invoice.map(p => p.id ?? "");
                const invoiceNumber = invoice.map(p => p.invoiceNumber?.toString().padStart(6, "0") ?? "");
                const invoiceDate = invoice.map(p => dateToString(p.invoiceDate) ?? "");

                const productionCard = productionCardData.filter(i => i.inquiryId === inquiryId && i.offerId === offerId);
                const productionCardId = productionCard.map(p => p.id ?? "");
                const productionCardNumber = productionCard.map(p => p.cardNumber?.toString().padStart(6, "0") ?? "");
                const productionCardDate = productionCard.map(p => dateToString(p.cardDate) ?? "");
                const productionCardStatus = productionCard.map(p => p.productionStatus ?? "");

                result.push({
                    randId: Math.random().toString(),
                    clientId,
                    clientName,
                    inquiryId,
                    inquiryNumber,
                    inquiryDate,
                    offerId,
                    offerNumber,
                    offerDate,
                    offerAuthor,
                    confirmationId,
                    confirmationNumber,
                    confirmationDate,
                    proformaId,
                    proformaNumber,
                    proformaDate,
                    invoiceId,
                    invoiceNumber,
                    invoiceDate,
                    productionCardId,
                    productionCardNumber,
                    productionCardDate,
                    productionCardStatus,
                    comments: i.comments
                });
            }
        }
        return result;
    }, [inquiryData, invoiceData, productionCardData, expeditionData]);

    async function saveInquiryComments(inquiryId: string, editInquiryComments: string | undefined) {
        await updateDoc(
            doc(fbDb, `customerInquiries/${inquiryId}`),
            {
                comments: editInquiryComments
            }
        );
    }

    console.log(expeditionData);

    return <Container>
        <Card>
            <Card.Header>
                <Card.Title><I18Label label={"CRM - обобщена справка"}/></Card.Title>
            </Card.Header>

            <Card.Body>
                {filterControl}

                {
                    !reportData && <Row>
                        <Col className={"text-center"}>
                            <I18Label label={"Моля, изчакайте..."}/>
                        </Col>
                    </Row>
                }

                {
                    inquiryError && <Row className={"text-center"}>
                        <Col>
                            <div className={"text-danger"}>{inquiryError.message}</div>
                        </Col>
                    </Row>
                }
                {
                    invoiceError && <Row className={"text-center"}>
                        <Col>
                            <div className={"text-danger"}>{invoiceError.message}</div>
                        </Col>
                    </Row>
                }

                {
                    (inquiryLoading || invoiceLoading) && <Row className={"text-center"}>
                        <Col>
                            <Spinner animation={"border"}/> Зареждане ...
                        </Col>
                    </Row>
                }

                {
                    reportData && <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th rowSpan={2}><I18Label label={"Клиент"}/></th>
                            <th colSpan={2}><I18Label label={"Запитване"}/></th>
                            <th colSpan={3}><I18Label label={"Оферта"}/></th>
                            <th colSpan={2}><I18Label label={"Потв.поръчка"}/></th>
                            <th colSpan={4}><I18Label label={"Карта за възлагане"}/></th>
                            <th colSpan={2}><I18Label label={"Проф.фактура"}/></th>
                            <th colSpan={2}><I18Label label={"Фактура"}/></th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            reportData.map(
                                i => {
                                    // debugger
                                    const itemCnt = Math.max(i.proformaId.length, i.invoiceId.length, i.productionCardId.length);
                                    const rowSpans = itemCnt > 0 ? itemCnt : 1;

                                    const rows = [
                                        (<tr key={i.randId}>
                                            <td rowSpan={rowSpans}>
                                                <Row>
                                                    <Col>{i.clientName}</Col>
                                                    <Col xs={"auto"}>
                                                        <NotesIconButton onClick={() => {
                                                            if(editInquiryId && editInquiryId === i.inquiryId) {
                                                                setEditInquiryId(undefined);
                                                                setEditInquiryComments(undefined);
                                                                return;
                                                            }
                                                            setEditInquiryId(i.inquiryId);
                                                            setEditInquiryComments(i.comments ?? "");
                                                        }} note={i.comments ?? undefined} />
                                                    </Col>
                                                </Row>

                                            </td>
                                            <td rowSpan={rowSpans}>{i.inquiryNumber}</td>
                                            <td rowSpan={rowSpans}>{i.inquiryDate}</td>
                                            <td rowSpan={rowSpans}>{i.offerNumber}</td>
                                            <td rowSpan={rowSpans}>{i.offerDate}</td>
                                            <td rowSpan={rowSpans}>{i.offerAuthor}</td>
                                            <td rowSpan={rowSpans}>{i.confirmationNumber}</td>
                                            <td rowSpan={rowSpans}>{i.confirmationDate}</td>
                                            <td>{itemCnt > 0 ? i.productionCardNumber[0] : ""}</td>
                                            <td>{itemCnt > 0 ? i.productionCardDate[0] : ""}</td>
                                            <td>{itemCnt > 0 ? i.productionCardStatus[0] : ""}</td>
                                            <td>{
                                                expeditionData?.filter(
                                                    e => e.cardId === i.productionCardId[0]
                                                ).map(
                                                    e => <div>{dateToString(e.expeditionDate)} - {e.client?.name}</div>
                                                )
                                            }</td>
                                            <td>{itemCnt > 0 ? i.proformaNumber[0] : ""}</td>
                                            <td>{itemCnt > 0 ? i.proformaDate[0] : ""}</td>
                                            <td>{itemCnt > 0 ? i.invoiceNumber[0] : ""}</td>
                                            <td>{itemCnt > 0 ? i.invoiceDate[0] : ""}</td>
                                        </tr>)
                                    ];

                                    if (itemCnt > 1) {
                                        for (let idx = 1; idx < itemCnt; idx++) {
                                            rows.push(
                                                <tr key={i.randId + `_${idx}`}>
                                                    <td>{idx < i.productionCardNumber.length ? i.productionCardNumber[idx] : ""}</td>
                                                    <td>{idx < i.productionCardDate.length ? i.productionCardDate[idx] : ""}</td>
                                                    <td>{idx < i.productionCardStatus.length ? i.productionCardStatus[idx] : ""}</td>
                                                    <td>{idx < i.proformaNumber.length ? i.proformaNumber[idx] : ""}</td>
                                                    <td>{idx < i.proformaDate.length ? i.proformaDate[idx] : ""}</td>
                                                    <td>{idx < i.invoiceNumber.length ? i.invoiceNumber[idx] : ""}</td>
                                                    <td>{idx < i.invoiceDate.length ? i.invoiceDate[idx] : ""}</td>
                                                </tr>
                                            );
                                        }
                                    }

                                    if(editInquiryId && editInquiryId === i.inquiryId) {
                                        rows.push(
                                            <tr>
                                                <td>
                                                    <Row>
                                                        <Col>Бележки:</Col>
                                                        <Col xs={"auto"}>
                                                            <SaveIconButton onClick={() => {
                                                                saveInquiryComments(i.inquiryId, editInquiryComments);
                                                                setEditInquiryId(undefined);
                                                                setEditInquiryComments(undefined);
                                                            }} />
                                                        </Col>
                                                    </Row></td>
                                                <td colSpan={12}>
                                                    <Form.Control
                                                        as={"textarea"} value={editInquiryComments}
                                                        onChange={(e) => {
                                                            const newComments = e.target.value;
                                                            setEditInquiryComments(newComments);
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    }

                                    return rows;
                                }
                            )
                        }
                        </tbody>
                    </Table>
                }

            </Card.Body>

        </Card>

    </Container>
}
