import {useHookstate} from "@hookstate/core";
import appDb from "../../../global-state/global-db";
import {OwnCompanyType} from "./useOwnCompanies";

export default function useOwnCompaniesRepo() {
    const db = useHookstate(appDb);
    const ownCompanies = db.ownCompanies.value.map(c => {
        return {...c} as OwnCompanyType
    });


    const getOwnCompanyName =
        (ownCompanyId: string | null) => {
            const company = ownCompanies.find(c => c.id === ownCompanyId);
            return company ? company.name : "";
        }

    const getOwnCompanyShortName =
        (ownCompanyId: string) => {
            const company = ownCompanies.find(c => c.id === ownCompanyId);
            return company ? company.shortName : "";
        }

    const getAllOwnCompanyShortNames =
        () => {
            return ownCompanies.map(c => c.shortName);
        }

    const getAllCompanyNames =
        () => {
            return ownCompanies.map(c => c.name);
        }

    return {
        ownCompanies,
        getOwnCompanyName,
        getOwnCompanyShortName,
        getAllOwnCompanyShortNames,
        getAllCompanyNames
    }
}
