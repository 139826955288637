import {DocumentData, DocumentReference, QueryDocumentSnapshot, SnapshotOptions} from "firebase/firestore";
import {ProductionCardProductsType} from "./ProductionCardProductType";
import {ProductionCardMaterialsType} from "./ProductionCardMaterialType";

export type ProductionDeliveryType = {
    productId: string;
    transport: string | null;
    address: string | null;
    personForContact: string | null;
    phone: string | null;
    deadlineDate: Date | null;
}

export type ProductionCardExpeditionType = {
    expeditionId: string;
    expeditionDate: Date | null;
    driverName: string | null;
    carName: string | null;
    qty: number | null;
    expeditionStatus: string | null;
}

export type ProductionCardExpeditionsType = ProductionCardExpeditionType[];

export type ProductionCardType = {
    id?: string;
    ref?: DocumentReference;
    ownCompanyId: string;
    cardNumber: number;
    cardDate: Date;
    inquiryId: string | null;
    inquiryNumber: number | null;
    inquiryDate: Date | null;
    invoiceNumber: number;
    invoiceDate: Date;
    invoiceId: string;
    invoiceRef: DocumentReference | null;
    invoiceClientName: string;
    offerId: string;
    productId: string;
    regarding: string;
    productionPlace: string;
    specification: ProductionCardProductsType;
    notes: string;
    materials: ProductionCardMaterialsType;
    materialIds: string[];
    transport: string | null;
    address: string | null;
    personForContact: string | null;
    phone: string | null;
    deadlineDate: Date | null;
    deliveryDate: Date | null;
    planProductionDate: Date | null;
    planExpeditionDate: Date | null;
    productionDate: Date | null;
    expeditionDate: Date | null;
    deliveryNote: string | null;
    productionStatus: string | null;
    expeditions: ProductionCardExpeditionsType | null;
}

export type ProductionCardsType = ProductionCardType[];

export const EMPTY_PRODUCTION_CARD: ProductionCardType = {
    ownCompanyId: "",
    cardNumber: 0,
    cardDate: new Date(),
    inquiryId: "",
    inquiryNumber: 0,
    inquiryDate: new Date(),
    invoiceNumber: 0,
    invoiceDate: new Date(),
    invoiceId: "",
    invoiceRef: null,
    invoiceClientName: "",
    offerId: "",
    productId: "",
    regarding: "",
    productionPlace: "",
    specification: [],
    notes: "",
    materials: [],
    materialIds: [],
    transport: null,
    address: null,
    personForContact: null,
    phone: null,
    deliveryDate: null,
    deadlineDate: null,
    planProductionDate: null,
    planExpeditionDate: null,
    productionDate: null,
    expeditionDate: null,
    deliveryNote: null,
    productionStatus: "Чакащи",
    expeditions: null,
}

export const productionCardConverter = {
    toFirestore(document: ProductionCardType): DocumentData {
        return {
            ownCompanyId: document.ownCompanyId || "",
            cardNumber: document.cardNumber || 0,
            cardDate: document.cardDate || null,
            inquiryId: document.inquiryId || "",
            inquiryNumber: document.inquiryNumber || 0,
            inquiryDate: document.inquiryDate || null,
            invoiceNumber: document.invoiceNumber || 0,
            invoiceDate: document.invoiceDate || null,
            invoiceId: document.invoiceId || "",
            invoiceRef: document.invoiceRef || null,
            invoiceClientName: document.invoiceClientName || "",
            offerId: document.offerId || "",
            productId: document.productId || "",
            regarding: document.regarding || "",
            productionPlace: document.productionPlace || "",
            specification: document.specification || [],
            notes: document.notes || "",
            materials: document.materials.map(
                item => {
                    return {
                        ...item,
                        productDimension: item.productDimension || "",
                    }
                }
            ) || [],
            materialIds: document.materials.map(item => item.productId) || [],
            transport: document.transport || null,
            address: document.address || null,
            personForContact: document.personForContact || null,
            phone: document.phone || null,
            deliveryDate: document.deliveryDate || null,
            deadlineDate: document.deadlineDate || (document.deliveryDate || null),
            planProductionDate: document.planProductionDate || null,
            planExpeditionDate: document.planExpeditionDate || null,
            productionDate: document.productionDate || null,
            expeditionDate: document.expeditionDate || null,
            deliveryNote: document.deliveryNote || null,
            productionStatus: document.productionStatus || "Чакащи",
            expeditions: document.expeditions || null,
        };
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
    ): ProductionCardType {
        const document = snapshot.data(options)!;
        return {
            id: snapshot.id,
            ref: snapshot.ref,
            ownCompanyId: document.ownCompanyId ?? "",
            cardNumber: document.cardNumber,
            cardDate: document.cardDate.toDate(),
            inquiryId: document.inquiryId ?? null,
            inquiryNumber: document.inquiryNumber ?? null,
            inquiryDate: document.inquiryDate?.toDate() ?? null,
            invoiceNumber: document.invoiceNumber,
            invoiceDate: document.invoiceDate.toDate(),
            invoiceId: document.invoiceId,
            invoiceRef: document.invoiceRef,
            invoiceClientName: document.invoiceClientName,
            offerId: document.offerId ?? "",
            productId: document.productId ?? "",
            regarding: document.regarding,
            productionPlace: document.productionPlace,
            specification: document.specification,
            notes: document.notes,
            materials: document.materials.map(
                (material: any) => {
                    return {
                        ...material, supplyDate: material.supplyDate ? material.supplyDate.toDate() : null
                    }
                }
            ),
            materialIds: document.materials.map((material: any) => material.productId),
            transport: document.transport,
            address: document.address,
            personForContact: document.personForContact,
            phone: document.phone,
            deliveryDate: document.deliveryDate ? document.deliveryDate.toDate() : null,
            deadlineDate: document.deadlineDate ? document.deadlineDate.toDate() : (document.deliveryDate ? document.deliveryDate.toDate() : null),
            planProductionDate: document.planProductionDate ? document.planProductionDate.toDate() : null,
            planExpeditionDate: document.planExpeditionDate ? document.planExpeditionDate.toDate() : null,
            productionDate: document.productionDate ? document.productionDate.toDate() : null,
            expeditionDate: document.expeditionDate ? document.expeditionDate.toDate() : null,
            deliveryNote: document.deliveryNote,
            productionStatus: document.productionStatus ?? "Чакащи",
            expeditions: document.expeditions ? document.expeditions.map(
                (expedition: any) => {
                    return {
                        expeditionId: expedition.expeditionId,
                        expeditionDate: expedition.expeditionDate ? expedition.expeditionDate.toDate() : null,
                        driverName: expedition.driverName,
                        carName: expedition.carName,
                        qty: expedition.qty,
                        expeditionStatus: expedition.expeditionStatus
                    }
                }
            ) : null,
        } as ProductionCardType;
    }
};
