import {Button, Col, ModalBody, ModalFooter, Row, Spinner, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCancel, faCircleArrowDown, faEdit, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {useCollection} from "react-firebase-hooks/firestore";
import {collection} from "firebase/firestore";
import {fbDb} from "../../../App";
import {useEffect, useState} from "react";
import LoadDataFromServerError from "../../../common/LoadDataFromServerError";
import {
    convertWharehouseObjectData,
    WarehouseObjectsType,
    WarehouseObjectType
} from "../../warehouse/hooks/useWarehouseObjects";
import OwnCompanySelector from "../own-companies/OwnCompanySelector";
import useOwnCompaniesRepo from "../../warehouse/hooks/useOwnCompaniesRepo";

export interface WarehouseObjectsListProps {
    isVisible: boolean;
    onAdd: () => any;
    onEdit: (item: WarehouseObjectType) => any;
    onDelete: (item: WarehouseObjectType) => any;
    onClose: (result: any) => any;
    onSelect?: (result: any) => any;
}

export function WarehouseObjectsList({
                                         isVisible,
                                         onAdd,
                                         onEdit,
                                         onDelete,
                                         onClose,
                                         onSelect
                                     }: WarehouseObjectsListProps) {
    const {getOwnCompanyName} = useOwnCompaniesRepo();
    const [selectedOwnCompanyIds, setSelectedOwnCompanyIds] = useState<string[]>([]);

    const [_objectsData, loadingObjects, errorObjects] = useCollection(
        collection(fbDb, "warehouseObjects")
    );

    const [objects, setObjects] = useState<WarehouseObjectsType>([]);
    const [filteredObjects, setFilteredObjects] = useState<WarehouseObjectsType>([]);

    useEffect(
        () => {
            setObjects(_objectsData ? convertWharehouseObjectData(_objectsData) : []);
        }, [_objectsData]
    );

    function isLoading() {
        return loadingObjects;
    }

    useEffect(
        () => {
            if (objects && selectedOwnCompanyIds) {
                setFilteredObjects(objects.filter(
                    d => !d.ownCompanyId ||
                        selectedOwnCompanyIds.length === 0 ||
                        selectedOwnCompanyIds.includes(d.ownCompanyId ?? "")
                ));
            } else {
                setFilteredObjects([]);
            }
        }, [objects, selectedOwnCompanyIds]
    )

    return (
        <>
            {isLoading() && <Spinner animation={"border"}/>}

            {
                !isLoading() && errorObjects && <LoadDataFromServerError/>
            }

            {
                !isLoading() && !errorObjects && objects && isVisible && objects.length === 0 &&
                <Row className={"m-5"}>
                    <Col className={"text-center fw-bold"}>Няма дефинирани складови обекти</Col>
                </Row>
            }


            {
                !loadingObjects && !errorObjects && objects && isVisible && objects.length > 0 &&
                <>
                    <ModalBody>
                        <div className={"mb-3"}>
                            <OwnCompanySelector
                                idPrefix={"wh-obj"}
                                selectedOwnCompaniesId={selectedOwnCompanyIds}
                                onChange={(v) => {
                                    setSelectedOwnCompanyIds(v);
                                }}
                            />
                        </div>
                        <div className={"max-h-60vh scrollable"}>
                            <Table size={"sm"} borderless hover>
                                <thead style={{backgroundColor: "white"}}>
                                <tr>
                                    <th style={{width: onSelect ? 120 : 90}}></th>
                                    <th>Фирма</th>
                                    <th>Складов обект</th>
                                    <th>Кратко име</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    filteredObjects.length > 0 && filteredObjects.map(
                                        d =>
                                            <tr key={d.id} className={"tr-bordered"}>
                                                <td>
                                                    <Button size={"sm"} variant={"outline-secondary"}
                                                            onClick={() => onEdit(d)}
                                                    >
                                                        <FontAwesomeIcon icon={faEdit}/>
                                                    </Button>&nbsp;
                                                    <Button size={"sm"} variant={"outline-danger"}
                                                            onClick={() => {
                                                                onDelete(d)
                                                            }}
                                                    >
                                                        <FontAwesomeIcon icon={faTrash}/>
                                                    </Button>&nbsp;
                                                    {
                                                        onSelect &&
                                                        <Button size={"sm"} variant={"outline-success"}
                                                                onClick={() => {
                                                                    onSelect(d)
                                                                }}
                                                        >
                                                            <FontAwesomeIcon icon={faCircleArrowDown}/>
                                                        </Button>
                                                    }
                                                </td>
                                                <td>
                                                    {getOwnCompanyName(d.ownCompanyId)}
                                                </td>
                                                <td>
                                                    {d.name}
                                                </td>
                                                <td>
                                                    {d.shortName}
                                                </td>
                                            </tr>
                                    )
                                }
                                </tbody>

                            </Table>
                        </div>
                    </ModalBody>
                </>
            }

            {
                isVisible &&
                <ModalFooter>
                    <Button onClick={() => onAdd()}>
                        <FontAwesomeIcon icon={faPlus}/> Добави нов
                    </Button>
                    <Button variant={"outline-secondary"} onClick={() => {
                        onClose(null)
                    }}>
                        <FontAwesomeIcon icon={faCancel}/> Затвори
                    </Button>
                </ModalFooter>
            }
        </>
    )
}
