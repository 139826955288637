import React, {useEffect, useMemo, useRef, useState} from "react";

import {Button, Col, Form, Modal, ModalBody, ModalTitle, Row, Spinner, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faSave,
    faCancel,
    faDolly, faTrash, faPencil
} from "@fortawesome/free-solid-svg-icons";
import {
    dateToString, removeEmptyFields, showErrorsListInToast, textToFloat,
    zeroToEmptyString, zeroToEmptyStringAsCurrency,
    zeroToEmptyStringAsNumber
} from "../../../../common";
import SelectValueFromDialog from "../../../../common/SelectValueFromDialog";
import {ProviderType} from "../../../nomenclatures/providers/hooks/useProviders";
import {ProductsDialog} from "../../../nomenclatures/products/ProductsDialog";
import {ProvidersDialog} from "../../../nomenclatures/providers/ProvidersDialog";
import ReactDatePicker from "react-datepicker";
import ModalHeader from "react-bootstrap/ModalHeader";
import DialogCloseButton from "../../../../common/DialogCloseButton";
import ModalBackground from "../../../../common/ModalBackground";
import {ProductType} from "../../../nomenclatures/products/hooks/useProductsOnce";
import PlusIconButton from "../../../../common/icon-buttons/PlusIconButton";
import {WarehouseRequestType, WarehouseRequestTypeItem} from "./WarehouseRequestType";
import EditableTableCell from "../../../../common/EditableTableCell";
import {API} from "../../../../api";
import {createWarehouseRequest, updateWarehouseRequest, useWarehouseRequestsRepo} from "./useWarehouseRequestsRepo";
import {useHookstate} from "@hookstate/core";
import appState from "../../../../global-state/global-state";
import {doc as getDocRef, updateDoc} from "firebase/firestore";
import {fbDb} from "../../../../App";
import {OwnCompanyDropdown} from "../../../nomenclatures/own-companies/OwnCompanySelector";
import WarehouseObjectsDropdown from "../../../nomenclatures/warehouse-objects/WarehouseObjectsDropdown";
import useOwnCompaniesRepo from "../../hooks/useOwnCompaniesRepo";
import useWarehouseObjectsRepo from "../../hooks/useWarehouseObjectsRepo";


export interface WarehouseTransactionDetailsProps {
    zIndex?: number;
    warehouseRequest: WarehouseRequestType;
    onDone: () => any;
}

export function WarehouseRequestDetails({zIndex, warehouseRequest, onDone}: WarehouseTransactionDetailsProps) {
    const globalState = useHookstate(appState).get();
    const [saving, setSaving] = useState(false);
    const [item, setItem] = useState<WarehouseRequestType>({...warehouseRequest});
    const [newProduct, setNewProduct] = useState<ProductType | null>(null);
    const [newProductQty, setNewProductQty] = useState("1");
    const [newProductPrice, setNewProductPrice] = useState("0");
    const [newProductInquiryNumber, setNewProductInquiryNumber] = useState("");
    const [newProductDeliveryDate, setNewProductDeliveryDate] = useState<Date | undefined>(undefined);
    const newProductQtyRef = useRef<HTMLInputElement>(null);
    const [editItemRowIndex, setEditItemRowIndex] = useState(-1);
    const [editItem, setEditItem] = useState<WarehouseRequestTypeItem | null>(null);
    const [selectedRows, setSelectedRows] = useState<number[]>([]);

    const [validating, setValidating] = useState(false);
    const {ownCompanies} = useOwnCompaniesRepo();
    const {allWarehouseObjects} = useWarehouseObjectsRepo();

    const save = async () => {
        setSaving(true);

        if (!item.requestNumber) {
            item.requestNumber = globalState.warehouseRequestsNumber + 1;
            const globalSettingsRef = getDocRef(fbDb, "settings", "globalSettings");
            await updateDoc(globalSettingsRef, {warehouseRequestsNumber: item.requestNumber});
        }

        item.itemIds = item.items.map(i => i.product.id || "");
        item.createdBy = removeEmptyFields(API.user);
        if(!item.ownCompanyId) {
            item.ownCompanyId = ownCompanies.length > 0 ? ownCompanies[0].id : "";
        }
        if(!item.warehouseId) {
            const companyWarehouses = allWarehouseObjects.filter(w => w.ownCompanyId === item.ownCompanyId);
            item.warehouseId = companyWarehouses.length > 0 ? companyWarehouses[0].id : "";
        }

        const clearItem = removeEmptyFields<WarehouseRequestType>(item);
        if (!clearItem) {
            return;
        }

        clearItem.items = clearItem.items.map(i => removeEmptyFields<WarehouseRequestTypeItem>(i)!);

        try {
            if (!item.id) {
                await createWarehouseRequest(clearItem)
            } else {
                await updateWarehouseRequest(clearItem)
            }
            onDone();
        } catch (e) {
            console.error(e);
        } finally {
            setSaving(false);
        }
    }

    const providerDialog = <ProvidersDialog show={true} zIndex={(zIndex || 1060) + 1} onClose={() => {
    }}/>;
    const productsDialog = <ProductsDialog show={true} zIndex={(zIndex || 1060) + 1} onClose={() => {
    }}/>

    function selectProduct(product: ProductType) {
        if (product) {
            setNewProduct({...product});
            setNewProductPrice(product.deliveryPrice?.toString() || "0");
            newProductQtyRef.current && newProductQtyRef.current.focus();
        } else {
            setNewProduct(null);
        }
    }

    function removeItem(idx: number) {
        let _item = {...item};
        let _items = (_item.items || []);
        _items.splice(idx, 1);
        setItem(_item);
    }

    function doEditItem(idx: number) {
        setEditItemRowIndex(idx);
        setEditItem({...item.items[idx]});
    }

    const tableRows = useMemo(() => {
        if (item.items && item.items.length > 0) {
            return item.items.map(
                (i, idx) =>
                    <tr key={idx}>
                        <td>
                            <Form.Check onClick={
                                (e: any) => {
                                    if (e.target.checked) {
                                        setSelectedRows([...selectedRows, idx]);
                                    } else {
                                        setSelectedRows(selectedRows.filter(i => i !== idx));
                                    }
                                }
                            } checked={selectedRows.includes(idx)}
                            />
                        </td>
                        <td> {i.product.sku} </td>
                        <td> {i.product.name} </td>
                        <td> {i.product.dimension} </td>

                        <EditableTableCell
                            className={"text-end"}
                            inputClassName={"text-end"}
                            inEditMode={idx === editItemRowIndex}
                            value={idx === editItemRowIndex && editItem ? editItem.qty.toString() : i.qty.toString()}
                            isWorking={false}
                            onBlur={(newValue: string) => {
                                setEditItem({...editItem!, qty: textToFloat(newValue || "0") ?? 0});
                            }}
                            onDone={() => {
                            }}
                            onEdit={() => {
                            }}
                        />

                        <EditableTableCell
                            className={"text-end"}
                            inputClassName={"text-end"}
                            inEditMode={editItemRowIndex === idx} isWorking={false}
                            value={
                                idx === editItemRowIndex && editItem ? zeroToEmptyStringAsCurrency(editItem.product.price?.toString()) :
                                    zeroToEmptyStringAsCurrency(i.product.price?.toString())
                            } onEdit={() => {
                        }} onBlur={
                            (newValue: string) => {
                                const newEditItem = {...editItem!};
                                newEditItem.product.price = textToFloat(newValue || "0") ?? 0;
                                setEditItem(newEditItem);
                            }
                        } onDone={() => {
                        }}/>

                        <EditableTableCell
                            className={"text-end"}
                            inputClassName={"text-end"}
                            inEditMode={editItemRowIndex === idx}
                            isWorking={false}
                            value={idx === editItemRowIndex && editItem ? editItem.inquiryNumber?.toString() : i.inquiryNumber?.toString()}
                            onBlur={(newValue: string) => {
                                setEditItem({...editItem!, inquiryNumber: newValue});
                            }}
                            onDone={() => {
                            }}
                            onEdit={() => {
                            }}
                        />

                        <td>
                            {
                                idx === editItemRowIndex && editItem ?
                                    <ReactDatePicker
                                        selected={editItem.expectedDeliveryDate}
                                        onChange={(date: any) => {
                                            setEditItem({...editItem!, expectedDeliveryDate: date})
                                        }}
                                        dateFormat={"dd.MM.yyyy"}
                                        className={"form-control form-control-sm"}
                                        isClearable={true}
                                    />
                                    :
                                    dateToString(i.expectedDeliveryDate)
                            }
                        </td>

                        <td>
                            {
                                idx === editItemRowIndex && editItem ?
                                    <ReactDatePicker
                                        selected={editItem.deliveryDate}
                                        onChange={(date: any) => {
                                            setEditItem({...editItem!, deliveryDate: date})
                                        }}
                                        dateFormat={"dd.MM.yyyy"}
                                        className={"form-control form-control-sm"}
                                        isClearable={true}
                                    />
                                    :
                                    dateToString(i.deliveryDate)
                            }
                        </td>

                        <EditableTableCell
                            inEditMode={editItemRowIndex === idx} isWorking={false}
                            value={editItemRowIndex === idx && editItem ? editItem.invoiceNumber || "" : i.invoiceNumber || ""}
                            onEdit={() => {
                            }}
                            onBlur={
                                (newValue: string) => {
                                    setEditItem({...editItem!, invoiceNumber: newValue});
                                }}
                            onDone={() => {
                            }}
                        />

                        <td>
                            {
                                idx === editItemRowIndex && editItem ?
                                    <ReactDatePicker
                                        selected={editItem.invoiceDate}
                                        onChange={(date: any) => {
                                            setEditItem({...editItem!, invoiceDate: date})
                                        }}
                                        dateFormat={"dd.MM.yyyy"}
                                        className={"form-control form-control-sm"}
                                        isClearable={true}
                                    />
                                    :
                                    dateToString(i.invoiceDate)
                            }
                        </td>

                        <EditableTableCell
                            className={"text-end"}
                            inputClassName={"text-end"}
                            inEditMode={idx === editItemRowIndex}
                            value={idx === editItemRowIndex && editItem ? editItem.deliveryQty?.toString() || "" : i.deliveryQty?.toString() || ""}
                            isWorking={false}
                            onBlur={(newValue: string) => {
                                setEditItem({...editItem!, deliveryQty: textToFloat(newValue || "0") ?? 0});
                            }}
                            onDone={() => {
                            }}
                            onEdit={() => {
                            }}
                        />

                        <EditableTableCell
                            className={"text-end"}
                            inputClassName={"text-end"}
                            inEditMode={editItemRowIndex === idx} isWorking={false}
                            value={
                                idx === editItemRowIndex && editItem ? zeroToEmptyStringAsCurrency(editItem.deliveryPrice?.toString()) :
                                    zeroToEmptyStringAsCurrency(i.deliveryPrice?.toString())
                            } onEdit={() => {
                        }} onBlur={
                            (newValue: string) => {
                                setEditItem({...editItem!, deliveryPrice: textToFloat(newValue || "0") ?? 0});
                            }
                        } onDone={() => {
                        }}/>

                        <td className={"text-end"}>
                            {
                                editItemRowIndex === idx && editItem ?
                                    (
                                        editItem.deliveryQty && editItem.deliveryPrice ?
                                            zeroToEmptyStringAsNumber(editItem.deliveryQty * editItem.deliveryPrice)
                                            :
                                            (
                                                editItem.qty && editItem.product.price ?
                                                    zeroToEmptyStringAsNumber(editItem.qty * editItem.product.price)
                                                    :
                                                    ""
                                            )
                                    ) : (
                                        i.deliveryQty && i.deliveryPrice ?
                                            zeroToEmptyStringAsNumber(i.deliveryQty * i.deliveryPrice)
                                            :
                                            (
                                                i.qty && i.product.price ?
                                                    zeroToEmptyStringAsNumber(i.qty * i.product.price)
                                                    :
                                                    ""
                                            )
                                    )
                            }
                        </td>

                        <td className={"text-center"}>
                            {
                                editItemRowIndex === idx && editItem ?
                                    <Form.Check type={"checkbox"} checked={!editItem.isActive}
                                                onChange={(e: any) => {
                                                    setEditItem({...editItem!, isActive: !e.target.checked});
                                                }}/>
                                    :
                                    !i.isActive ? "Да" : "Не"
                            }
                        </td>

                        <td className={"text-end text-nowrap"}>
                            {
                                editItemRowIndex === idx ?
                                    <>
                                        {
                                            validating && editItem ? <Spinner animation={"border"} size={"sm"}/> :
                                                <Button variant={"success"} size={"sm"}
                                                        onClick={async () => {
                                                            if (editItem && editItem.inquiryNumber.length > 0 &&
                                                                await validateProductionCardNumber(editItem.inquiryNumber) === false) {
                                                                return;
                                                            }
                                                            if (selectedRows.length > 0 && editItem) {
                                                                let _item = {...item};
                                                                let _items = (_item.items || []);
                                                                selectedRows.forEach(i => {
                                                                    _items[i].expectedDeliveryDate = editItem.expectedDeliveryDate;
                                                                    _items[i].deliveryDate = editItem.deliveryDate;
                                                                    _items[i].invoiceNumber = editItem.invoiceNumber;
                                                                    _items[i].invoiceDate = editItem.invoiceDate;
                                                                });
                                                            }
                                                            item.items[idx] = {...editItem!};
                                                            setEditItemRowIndex(-1);
                                                            setEditItem(null);
                                                        }
                                                        }>
                                                    <FontAwesomeIcon icon={faSave}
                                                    />
                                                </Button>
                                        }
                                        {/*<Button variant={"success"} size={"sm"}*/}
                                        {/*        onClick={async () => {*/}
                                        {/*            if (editItem && editItem.inquiryNumber.length > 0 &&*/}
                                        {/*                await validateProductInquiryNumber(editItem.inquiryNumber) === false) {*/}
                                        {/*                return;*/}
                                        {/*            }*/}
                                        {/*            if (selectedRows.length > 0 && editItem) {*/}
                                        {/*                let _item = {...item};*/}
                                        {/*                let _items = (_item.items || []);*/}
                                        {/*                selectedRows.forEach(i => {*/}
                                        {/*                    _items[i].expectedDeliveryDate = editItem.expectedDeliveryDate;*/}
                                        {/*                    _items[i].deliveryDate = editItem.deliveryDate;*/}
                                        {/*                    _items[i].invoiceNumber = editItem.invoiceNumber;*/}
                                        {/*                    _items[i].invoiceDate = editItem.invoiceDate;*/}
                                        {/*                });*/}
                                        {/*            }*/}
                                        {/*            item.items[idx] = {...editItem!};*/}
                                        {/*            setEditItemRowIndex(-1);*/}
                                        {/*            setEditItem(null);*/}
                                        {/*        }*/}
                                        {/*        }>*/}
                                        {/*    <FontAwesomeIcon icon={faSave}/>*/}
                                        {/*</Button>*/}
                                        <Button variant={"danger"} size={"sm"}
                                                onClick={() => {
                                                    setEditItem(null);
                                                    setEditItemRowIndex(-1);
                                                }}>
                                            <FontAwesomeIcon icon={faCancel}/>
                                        </Button>
                                    </>
                                    :
                                    <>
                                        <Button variant={"primary"} size={"sm"} onClick={() => doEditItem(idx)}>
                                            <FontAwesomeIcon icon={faPencil}/>
                                        </Button>
                                        <Button variant={"danger"} size={"sm"} onClick={() => removeItem(idx)}>
                                            <FontAwesomeIcon icon={faTrash}/>
                                        </Button>
                                    </>
                            }
                        </td>
                    </tr>
            )
        }
        return
        <tr>
            <td colSpan={14}>
                <div className={"text-center fw-bold"}>
                    Добавете продукт
                </div>
            </td>
        </tr>
    }, [item, selectedRows, editItem]);

    async function addProduct() {

        const validInquiryNumber = newProductInquiryNumber.length > 0 ? await validateProductionCardNumber(newProductInquiryNumber) : true;
        if (!validInquiryNumber) {
            return;
        }

        const newItem: WarehouseRequestTypeItem = {
            product: {
                id: newProduct?.id || "",
                sku: newProduct?.sku || "",
                dimension: newProduct?.dimens || "",
                name: newProduct?.name || "",
                price: parseFloat(newProductPrice)
            },
            qty: parseFloat(newProductQty),
            inquiryNumber: newProductInquiryNumber,
            expectedDeliveryDate: newProductDeliveryDate!,
            isActive: true,
            ownCompanyId: item.ownCompanyId,
            warehouseId: item.warehouseId
        }
        let _item = {...item};
        let newItemItems = [...item.items];
        newItemItems.push(newItem!);
        _item.items = newItemItems;
        setItem(_item);

        setNewProduct(null);
        setNewProductQty("1");
        setNewProductInquiryNumber("");
        setNewProductPrice("0");
        setNewProductDeliveryDate(undefined);
    }

    if (saving) {
        setSaving(false);
    }

    async function validateProductionCardNumber(newProductionCardNumber: string) {
        try {
            setValidating(true);
            if (newProductionCardNumber.length === 0) {
                return;
            }
            const item = await API.getProductionCardByNumber(newProductionCardNumber);
            if (item.docs.length === 0) {
                showErrorsListInToast("Грешка", ["Не е намерена карта за възлагане с този номер"]);
                return false;
            }
            return true;
        } catch (e) {
            console.error(e);
            showErrorsListInToast("Грешка", ["Възникна грешка при валидация на номер на заявка"]);
        } finally {
            setValidating(false);
        }
    }

    return (
        <ModalBackground zIndex={zIndex || 1050}>
            <Modal show={true} size={"xl"} style={{zIndex: ((zIndex || 1050) + 1)}}>
                <ModalHeader>
                    <ModalTitle>
                        <FontAwesomeIcon icon={faDolly}/> {item?.id ? "Корекция на заявка" : "Добавяне на заявка"}
                    </ModalTitle>
                    <DialogCloseButton onClose={() => onDone()}/>
                </ModalHeader>
                <ModalBody>
                    <div className={"ps-2 pe-2"}>
                        <Row>
                            <Col xs={"auto"}>
                                <Form.Group>
                                    <Form.Label>Фирма:</Form.Label>
                                    <OwnCompanyDropdown
                                        readOnly={(item.items?.length ?? 0) > 0}
                                        selectedOwnCompanyId={item.ownCompanyId}
                                        onChange={
                                            (selectedOwnCompanyId) => setItem({...item, ownCompanyId: selectedOwnCompanyId})
                                        } />
                                </Form.Group>
                            </Col>

                            <Col xs={"auto"}>
                                <Form.Group>
                                    <Form.Label>Склад:</Form.Label>
                                    <WarehouseObjectsDropdown
                                        readOnly={(item.items?.length ?? 0) > 0}
                                        ownCompanyId={item.ownCompanyId}
                                        selectedWarehouseObjectId={item.warehouseId}
                                        onChange={(selectedWarehouseObjectId) => setItem({...item, warehouseId: selectedWarehouseObjectId})}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={1}>
                                <Form.Label>Номер</Form.Label>
                                <Form.Control
                                    value={
                                        item.requestNumber ? item.requestNumber.toString() : "нова заявка"}
                                    readOnly
                                    disabled={true}
                                />
                            </Col>
                            <Col xs={"2"}>
                                <Form.Label>Дата</Form.Label><br/>
                                <ReactDatePicker
                                    onChange={
                                        (date: any) => {
                                            setItem({...item, createdAt: date})
                                        }
                                    }
                                    selected={item.createdAt}
                                    dateFormat={"dd.MM.yyyy"}
                                    className={"form-control"}
                                />
                            </Col>
                            <Col>
                                <SelectValueFromDialog label={"Доставчик"}
                                                       value={item.provider?.name}
                                                       onChange={(provider: ProviderType) => setItem({
                                                           ...item,
                                                           provider: provider
                                                       })}
                                                       dialog={providerDialog}
                                />
                            </Col>
                            <Col xs={"auto"}>
                            </Col>
                        </Row>
                        {
                            item.provider ?
                                <Row>
                                    <Col>
                                        <Table size={"sm"} bordered hover>
                                            <thead>
                                            <tr>
                                                <th className={"w-50px text-center"}>
                                                    <Form.Check
                                                        onClick={(e: any) => {
                                                            if (e.target.checked) {
                                                                setSelectedRows(item.items.map((i, idx) => idx));
                                                            } else {
                                                                setSelectedRows([]);
                                                            }
                                                        }}
                                                        checked={selectedRows.length === item.items.length}
                                                    />
                                                </th>
                                                <th>SKU</th>
                                                <th className={"w-50"}>Продукт</th>
                                                <th>М.Е.</th>
                                                <th className={"text-end"}>Кол.</th>
                                                <th className={"text-end"}>Ед.<br/>цена</th>
                                                <th>Kъм<br/>карта</th>
                                                <th>Очаквана<br/>доставка</th>
                                                <th>Доставено<br/>на</th>
                                                <th>Фактура<br/>No:</th>
                                                <th>Фактура<br/>дата</th>
                                                <th className={"text-end"}>Кол.</th>
                                                <th className={"text-end"}>Ед.<br/>цена</th>
                                                <th className={"text-end"}>Цена</th>
                                                <th className={"text-center"}>Анул.</th>
                                                <th/>
                                            </tr>
                                            </thead>

                                            <tbody>
                                            {tableRows}
                                            <tr>
                                                <td colSpan={4}>
                                                    <SelectValueFromDialog size={"sm"} className={"mb-0"}
                                                                           value={newProduct?.name}
                                                                           onChange={(product: ProductType) => {
                                                                               selectProduct(product)
                                                                           }}
                                                                           dialog={productsDialog}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        ref={newProductQtyRef}
                                                        size={"sm"}
                                                        className={"text-end p-0 m-0 w-50px"}
                                                        value={newProductQty}
                                                        onChange={(e: any) => setNewProductQty(e.target.value)}
                                                        onBlur={(e: any) => setNewProductQty(zeroToEmptyString(e.target.value))}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        size={"sm"}
                                                        className={"text-end p-0 m-0 w-50px"}
                                                        value={newProductPrice}
                                                        onChange={(e: any) => setNewProductPrice(e.target.value)}
                                                        onBlur={(e: any) => setNewProductPrice(zeroToEmptyString(e.target.value))}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        size={"sm"}
                                                        className={"text-end p-0 m-0 w-50px"}
                                                        value={newProductInquiryNumber}
                                                        onChange={(e: any) => setNewProductInquiryNumber(e.target.value)}
                                                        onBlur={(e: any) => setNewProductInquiryNumber(zeroToEmptyString(e.target.value))}
                                                    />
                                                </td>
                                                <td>
                                                    <ReactDatePicker
                                                        selected={newProductDeliveryDate}
                                                        onChange={(date: any) => setNewProductDeliveryDate(date)}
                                                        dateFormat={"dd.MM.yyyy"}
                                                        className={"form-control form-control-sm w-100px"}
                                                        isClearable={true}
                                                    />
                                                </td>
                                                <td colSpan={7}></td>
                                                <td className={"text-end"}>
                                                    {
                                                        validating && !editItem ?
                                                            <Spinner animation={"border"} size={"sm"}/> :
                                                            <PlusIconButton size={"sm"} onClick={addProduct}
                                                                            disabled={
                                                                                !newProduct
                                                                                || !newProductQty
                                                                                || !newProductPrice
                                                                            }/>
                                                    }
                                                </td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                                :
                                <Row>
                                    <Col>
                                        <i>
                                            Моля, изберете доставчик
                                        </i>
                                    </Col>
                                </Row>
                        }
                    </div>
                </ModalBody>
                <Modal.Footer>
                    <Row className={"w-100 ps-0 ms-0"}>
                        {
                            saving && <Col xs={12} className={"text-center"}>
                                <Spinner animation={"border"}/>
                            </Col>
                        }

                        {
                            !saving &&
                            <>
                                <Col xs={6}>
                                    <Button variant={"outline-secondary"} onClick={() => {
                                        onDone()
                                    }}>
                                        <FontAwesomeIcon icon={faCancel}/> Откажи
                                    </Button>
                                </Col>
                                <Col xs={6} className={"text-end"}>
                                    <Button type={"submit"} onClick={() => save()}>
                                        <FontAwesomeIcon icon={faSave}/> Запиши
                                    </Button>
                                </Col>
                            </>
                        }
                    </Row>
                </Modal.Footer>
            </Modal>
        </ModalBackground>
    )
}
