import {FC, useEffect, useState} from "react";
import {
    useWarehouseRequestsRepo, warehouseRequestConverter
} from "../pages/warehouse/warehouse-transactions/warehouse-request/useWarehouseRequestsRepo";
import useFirebaseCollection from "../hooks/useFirebaseCollection";
import {collection, query, where} from "firebase/firestore";
import {WarehouseRequestType} from "../pages/warehouse/warehouse-transactions/warehouse-request/WarehouseRequestType";
import {convertWarehouseItemData} from "../pages/warehouse/hooks/useWarehouseItemsForObject";
import {Row, Spinner, Tab, Tabs} from "react-bootstrap";
import {WarehouseItemType} from "../pages/warehouse/hooks/useWarehouseInItems";
import {dateToString} from "../common";
import {useCollection} from "react-firebase-hooks/firestore";
import {fbDb} from "../App";
import {productionCardConverter, ProductionCardType} from "../pages/production/types/ProductionCardType";
import I18Label, {getI18Text} from "../i18/i18label";
import useWarehouseObjectsRepo from "../pages/warehouse/hooks/useWarehouseObjectsRepo";

interface ProductAvailabilityProps {
    productId: string;
}

const ProductAvailability: FC<ProductAvailabilityProps> = ({productId}) => {
    const {allWarehouseObjects, getWarehouseObjectShortName} = useWarehouseObjectsRepo();
    const [requestDocs, requestsLoading, requestError] =
        useFirebaseCollection("warehouseRequests",
            [
                where("isActive", "==", true),
                where("itemIds", "array-contains", productId)
            ]);

    const [warehouseDocs, warehouseLoading, wharehouseError] =
        useFirebaseCollection("warehouseItems",
            [
                where("productId", "==", productId)
            ]);

    const [productionCardData, loadingProductionCard, errorProductionCard] = useCollection(
        query(collection(fbDb, "productionCards"),
            where("materialIds", "array-contains", productId),
            where("productionStatus", "in", ["Чакащи", "В производство", "Изпълнена"]),
            where("cardDate", ">=", new Date(2024, 8, 1, 0, 0, 0))
        ).withConverter(productionCardConverter)
    );

    const [reserved, setReserved] = useState<string>("");
    const [reservedDetails, setReservedDetails] = useState<string[]>([]);
    const [showReservedDetails, setShowReservedDetails] = useState<boolean>(false);
    const [availability, setAvailability] = useState<string>("");
    const [requested, setRequested] = useState<string>("");
    const [availabilityDetails, setAvailabilityDetails] = useState<string[]>([]);
    const [showAvailabilityDetails, setShowAvailabilityDetails] = useState<boolean>(false);
    const [selectedWarehouseId, setSelectedWarehouseId] = useState<string>("all");

    useEffect(() => {
        if (!requestsLoading && !requestError && requestDocs
            && !warehouseLoading && !wharehouseError && warehouseDocs
            && !loadingProductionCard && !errorProductionCard && productionCardData
        ) {
            const _requestDocs = requestDocs.docs.map(d => warehouseRequestConverter.fromFirestore(d));
            let qty = 0;
            let details: string[] = [];
            _requestDocs.forEach(d => {
                d.items.forEach(i => {
                    if (i.product.id === productId && (selectedWarehouseId === "all" || i.warehouseId === selectedWarehouseId)) {
                        qty += i.qty;
                        details.push(`${d.requestNumber?.toString().padStart(4, "0")}/${dateToString(i.expectedDeliveryDate) || "???"} - ${i.qty}`);
                    }
                });
            });
            if (qty > 0) {
                setRequested(getI18Text("Заявени") + ": " + qty.toString() + "; ");
                setAvailabilityDetails(details);
            } else {
                setRequested("");
                setAvailabilityDetails([]);
            }

            let reservedQty = 0;
            const _reservedDetails: string[] = [];
            const _productionCardData = productionCardData.docs.map(d => d.data() as ProductionCardType);
            _productionCardData.forEach(d => {
                d.materials.forEach(m => {
                    if (m.productId === productId && (selectedWarehouseId === "all" || m.warehouseId === selectedWarehouseId)) {
                        reservedQty += m.productQty;
                        _reservedDetails.push(`${getWarehouseObjectShortName(m.warehouseId)} : ${d.cardNumber}/${dateToString(d.cardDate)} - ${m.productQty}`);
                    }
                });
            });
            if (reservedQty > 0) {
                setReserved(getI18Text("Резервирани") + ": " + reservedQty.toString() + "; ");
                setReservedDetails(_reservedDetails);
            } else {
                setReserved("");
            }

            let availableQty = 0;
            const _warehouseDocs = warehouseDocs.docs.map(d => d.data() as WarehouseItemType);
            _warehouseDocs.forEach(d => {
                if (selectedWarehouseId === "all" || d.warehouseId === selectedWarehouseId) {
                    availableQty += d.availableQty ?? 0
                }
            });
            availableQty -= reservedQty;
            if (availableQty !== 0) {
                setAvailability(getI18Text("Налични") + ": " + availableQty.toString() + "; ");
            } else {
                setAvailability("");
            }

        }
    }, [requestDocs, warehouseDocs, selectedWarehouseId]);

    if (requestsLoading || warehouseLoading) {
        return <Spinner animation="border" variant="primary"/>;
    }

    const ReservedDetails = ({reservedDetails}: { reservedDetails: string[] }) => {
        const [warehousesIds, setWarehousesIds] = useState<string[]>([]);

        useEffect(() => {
            const ids = new Set<string>();

        }, []);

        return (
            <Row className={"border rounded p-2"} style={{maxHeight: "150px", overflowY: "scroll"}}>
                {
                    reservedDetails.map(
                        d => <span key={d} className={" text-danger fst-italic"}>{d}<br/></span>)
                }
            </Row>
        );
    }

    return (
        <>
            <Tabs activeKey={selectedWarehouseId} onSelect={(k) => setSelectedWarehouseId(k ?? "all")}>
                <Tab eventKey={"all"} title={"*"}/>
                {
                    allWarehouseObjects.map(w => <Tab key={w.id} eventKey={w.id} title={w.shortName}/>)
                }
            </Tabs>
            {
                availability.trim() + requested.trim() + reserved.trim() === "" ?
                    <span><I18Label label={"Няма наличност"}/></span>
                    :
                    <>
                        {
                            reserved && <span className={"text-danger cursor-pointer"}
                                              onClick={() => setShowReservedDetails(!showReservedDetails)}
                            >{reserved}<br/></span>
                        }
                        {
                            showReservedDetails && reservedDetails.length > 0 &&
                            <ReservedDetails reservedDetails={reservedDetails}/>
                        }
                        {availability}
                        {
                            requested &&
                            <>
                                <span className={"text-info"}><br/>{requested}</span>
                                {
                                    showAvailabilityDetails && availabilityDetails.length > 0 &&
                                    <span className={"fst-italic text-info"}>
                                        <ReservedDetails reservedDetails={availabilityDetails}/>
                                    </span>
                                }
                            </>
                        }
                    </>
            }
        </>
    );
};

export default ProductAvailability;
