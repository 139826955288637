import {updateDoc, addDoc, collection, getFirestore, doc} from "firebase/firestore";
import {Button, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faSave} from "@fortawesome/free-solid-svg-icons";
import {fbDb, firebaseApp} from "../../../App";
import {removeEmptyFields, showErrorsListInToast} from "../../../common";
import {useState} from "react";
import {WarehouseObjectType} from "../../warehouse/hooks/useWarehouseObjects";
import {useHookstate} from "@hookstate/core";
import appDb from "../../../global-state/global-db";
import {OwnCompanyDropdown} from "../own-companies/OwnCompanySelector";
import {OwnCompanyType} from "../../warehouse/hooks/useOwnCompanies";


export interface WarehouseObjectDetailsProps {
    zIndex?: number;
    object: WarehouseObjectType | null;
    onDone: () => any;
}

export function WarehouseObjectDetails({
                                 zIndex,
                                 object,
                                 onDone
                             }: WarehouseObjectDetailsProps) {
    const db = useHookstate(appDb);
    const ownCompanies = db.ownCompanies.value;

    const [selectedOwnCompanyId, setSelectedOwnCompanyId] = useState(object?.ownCompanyId ?? ownCompanies[0].id);
    const [saving, setSaving] = useState(false);

    const save = async (data: any) => {
        const objectsRef = collection(fbDb, "warehouseObjects");
        setSaving(true);
        let _data = removeEmptyFields(data);
        if (object?.id) {
            await updateDoc(doc(objectsRef, object?.id), _data);
        } else {
            await addDoc(objectsRef, _data);
        }
        setSaving(false);
        onDone();
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        const data: any = {
            name: event.target.elements.name.value || '',
            shortName: event.target.elements.shortName.value || '',
            ownCompanyId: selectedOwnCompanyId
        };

        if(data.name && data.name.length > 0 && data.shortName && data.shortName.length > 0) {
            save(data);
        } else {
            showErrorsListInToast("Внимание", ["Моля, въведете пълно и кратко име за складовото стопанство и опитайте пак."]);
        }
    }

    return (
        <><Form className={"p-2"} onSubmit={handleSubmit}>
            <Modal.Body>
                <h5 className={"mb-3"}>
                    {object ? "Корекция" : "Добавяне"} на складов обект
                </h5>

                <Row>
                    <Col>
                        <Form.Label>Собстена фирма:</Form.Label>
                        <OwnCompanyDropdown
                            selectedOwnCompanyId={selectedOwnCompanyId}
                            onChange={(v) => setSelectedOwnCompanyId(v)}
                        />
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Име</Form.Label>
                            <Form.Control type="text" name="name" defaultValue={object?.name}/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Кратко име</Form.Label>
                            <Form.Control type="text" name="shortName" defaultValue={object?.shortName}/>
                        </Form.Group>
                    </Col>
                </Row>

            </Modal.Body>
            <Modal.Footer>
                <Row className={"w-100"}>
                    {
                        saving && <Col xs={12} className={"text-center"}>
                            <Spinner animation={"border"}/>
                        </Col>
                    }

                    {
                        !saving &&
                        <>
                            <Col xs={6}>
                                <Button variant={"outline-secondary"} onClick={() => {
                                    onDone()
                                }}>
                                    <FontAwesomeIcon icon={faArrowLeft}/> Към списъка
                                </Button>
                            </Col>
                            <Col xs={6} className={"text-end"}>
                                <Button type={"submit"}>
                                    <FontAwesomeIcon icon={faSave}/> Запиши
                                </Button>
                            </Col>
                        </>
                    }
                </Row>
            </Modal.Footer>
        </Form>
        </>
    )
}
