import {
    Col,
    Modal,
    ModalTitle, Row,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import React, {useEffect, useState} from "react";
import {DIALOG_OPTIONS} from "../../../types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRobot} from "@fortawesome/free-solid-svg-icons";
import ModalBackground from "../../../common/ModalBackground";
import DialogCloseButton from "../../../common/DialogCloseButton";
import {ProductionList} from "./ProductionList";
import {ProductionDetails} from "./ProductionDetails";
import { ProductionDelete } from "./ProductionDelete";
import {EMPTY_PRODUCTION, ProductionType} from "./hooks/useProductionOnce";
import I18Label from "../../../i18/i18label";
import OwnCompanyWarehouseSelector from "../../warehouse/warehouse/OwnCompanyWarehouseSelector";

export function ProductionDialog({zIndex, show, onClose, onSelect}: DIALOG_OPTIONS) {
    const [viewMode, setViewMode] = useState<"VIEW_LIST" | "VIEW_ADD" | "VIEW_EDIT" | "VIEW_DELETE">('VIEW_LIST');
    const [editDocument, setEditDocument] = useState<ProductionType | null>(null);
    const [deleteDocument, setDeleteDocument] = useState<ProductionType | null>(null);
    const [addDocument, setAddDocument] = useState<ProductionType | undefined>();

    useEffect(() => {
        setViewMode(editDocument ? "VIEW_EDIT" : "VIEW_LIST");
    }, [editDocument])

    useEffect(() => {
        setViewMode(deleteDocument ? "VIEW_DELETE" : "VIEW_LIST");
    }, [deleteDocument])

    useEffect(() => {
        setViewMode(addDocument ? "VIEW_ADD" : "VIEW_LIST");
    }, [addDocument])

    function handleClose() {
        if(viewMode === "VIEW_LIST") {
            onClose(null);
        } else {
            setEditDocument(null);
            setAddDocument(undefined);
            setDeleteDocument(null);
        }
    }

    return (
        <ModalBackground zIndex={zIndex}>
            <Modal show={show} size={"xl"} style={{zIndex: (zIndex + 1)}} >
                <ModalHeader>
                    <ModalTitle>
                        <FontAwesomeIcon icon={faRobot}/> <I18Label label={"Собствено производство"} />
                    </ModalTitle>
                    <DialogCloseButton onClose={() => handleClose()} />
                </ModalHeader>
                {
                    <ProductionList onClose={handleClose} isVisible={viewMode === "VIEW_LIST"}
                                    onSelect={onSelect}
                                    onAdd={(selectedOwnCompanyId, selectedWarehouseId) => {
                                        setAddDocument(
                                            {...EMPTY_PRODUCTION, ownCompanyId: selectedOwnCompanyId, warehouseId: selectedWarehouseId}
                                        );
                                    }}
                                    onEdit={(doc: ProductionType) => {
                                     setEditDocument(doc);
                                 }}
                                    onDelete={(doc: ProductionType) => {
                                     setDeleteDocument(doc);
                                 }}
                    />
                }
                {
                    viewMode === "VIEW_EDIT" && editDocument &&
                    <ProductionDetails product={editDocument}
                                       zIndex={(zIndex || 1100) + 10}
                                       onDone={() => {
                                      setEditDocument(null)
                                  }}/>
                }
                {
                    viewMode === "VIEW_DELETE" && deleteDocument &&
                    <ProductionDelete doc={deleteDocument} onDone={() => {
                        setDeleteDocument(null)
                    }}/>
                }
                {
                    viewMode === "VIEW_ADD" && addDocument &&
                    <ProductionDetails product={addDocument}
                                       zIndex={(zIndex || 1100) + 10}
                                       onDone={() => setAddDocument(undefined)}
                    />
                }

            </Modal>
        </ModalBackground>
    )
}
