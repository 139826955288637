import {useState} from "react";

import {
    DocumentData,
    updateDoc,
    addDoc,
    collection,
    query,
    where,
    doc as docRef,
    getDocs, writeBatch
} from "firebase/firestore";
import {Button, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faSave} from "@fortawesome/free-solid-svg-icons";
import {fbDb} from "../../../App";
import {ClientsSelector} from "../clients_selector/ClientsSelector";
import {removeEmptyFields, showErrorsListInToast} from "../../../common";
import {usersConverter} from "../../../fb-converters/users-converter";
import {locationConverter, ClientLocationType} from "../../../fb-converters/location-converter";
import {useCollectionDataOnce} from "react-firebase-hooks/firestore";
import {regionConverter} from "../../../fb-converters/region-converter";

function ErrorRow(props: any) {
    return (
        <tr>
            <td colSpan={3} className={"text-center text-danger"}> {props.msg.toString()} </td>
        </tr>
    )
}

export interface PositionsDetailsProps {
    zIndex?: number;
    doc: DocumentData | null;
    onDone: () => any;
}

export function PositionsDetails({zIndex, doc, onDone}: PositionsDetailsProps) {
    const [saving, setSaving] = useState(false);
    const [selectedRegions, setSelectedRegions] = useState<string[]>(doc?.data().regions || []);
    const [selectedClients, setSelectedClients] = useState<string[]>(doc?.data().clients || []);

    const [allLocations] = useCollectionDataOnce(
        collection(fbDb, "clientLocations").withConverter(locationConverter)
    );

    const [allRegions] = useCollectionDataOnce(
        collection(fbDb, "regions").withConverter(regionConverter)
    );

    async function updateSaleRepresentatives(positionId: string, position: DocumentData, clearMode: boolean) {
        try {
            const usersData = await getDocs(
                query(
                    collection(fbDb, "users"),
                    where("isSalesRepresentative", "==", true)
                ).withConverter(usersConverter));

            const users = usersData.docs.map(value => value.data());//.filter(value => !value.activeTo || firebaseDateToDate(value.activeTo) > today);

            const locations: ClientLocationType[] = [];
            const regions = position.regions ? [...position.regions] : [];
            while(regions.length) {
                const portion = regions.splice(0, 10);
                const locationsData = await getDocs(
                    query(
                        collection(fbDb, "clientLocations"),
                        where("region", "in", portion)
                    ).withConverter(locationConverter));
                locationsData.forEach(d => locations.push(d.data()));
            }

            let clients = new Map<string, string[]>();
            let currentList: string[] = [];

            position.clients.forEach(
                (locationId: string) => {
                    const _location = locations.find(value => value.id === locationId);
                    if(_location) {
                        const clientId = _location.clientId || "";

                        if(clients.has(clientId)) {
                            currentList = clients.get(clientId) || [];
                        } else currentList = [];

                        users.forEach(
                            user => {
                                if(user.position === positionId) {
                                    if(user.name) {
                                        if(currentList.indexOf(user.name) === -1) {
                                            currentList.push(user.name);
                                        }
                                    }
                                }
                            }
                        )

                        clients.set(clientId, currentList);
                    }
                }
            )

            const collectionRef = collection(fbDb, "clients");
            const clientIdList = Array.from(clients.keys());

            while(clientIdList.length) {
                const batch = writeBatch(fbDb);
                const portion = clientIdList.splice(0, 200);

                portion.forEach(
                    locationId => {
                        const _docRef = docRef(fbDb, "clients", locationId);
                        batch.update(_docRef, {salesRepresentative: clearMode ? [] : clients.get(locationId)});
                    }
                );
                await batch.commit();
            }

            while(locations.length) {
                const batch = writeBatch(fbDb);
                const portion = locations.splice(0, 200);

                portion.forEach(
                    location => {
                        const _docRef = docRef(fbDb, "clientLocations", location.id!);
                        batch.update(_docRef, {salesRepresentative: clearMode ? [] : clients.get(location.clientId!) || null});
                    }
                );
                await batch.commit();
            }

        } catch (e: any) {
            console.error(e);
            showErrorsListInToast("Грешка", [`Възникна грешка при опит да се попълнят търгосвките представители на клиентите.\nГрешка: ${e.message}`])
        }
    }

    const save = async (data: any) => {
        setSaving(true);
        let _data = removeEmptyFields(data);
        let positionId: string;
        if (doc) {
            await updateDoc(doc.ref, _data);
            positionId = doc.id;
        } else {
            const newDoc = await addDoc(collection(fbDb, "positions"), _data);
            positionId = newDoc.id;
        }

        if(doc) {
            await updateSaleRepresentatives(positionId, doc?.data(), true);
        }
        await updateSaleRepresentatives(positionId, _data, false);

        setSaving(false);
        onDone();
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        const _selectedRegions = selectedRegions.filter(
            regionName => {
                const reg = allRegions?.find(
                    item => item.name === regionName
                );
                return reg ? true : false;
            }
        );

        const _selectedLocations = selectedClients.filter(
            locationId => {
                const loc = allLocations?.find(
                    item => item.id === locationId &&
                        item.region &&
                        _selectedRegions.indexOf(item.region) !== -1
                );
                return loc ? true : false;
            }
        );

        const data: any = {
            name: event.target.elements.name.value || '',
            regions: _selectedRegions,
            clients: _selectedLocations,
        };

        save(data);
    }

    return (
        <><Form className={"p-2"} onSubmit={handleSubmit}>
            <Modal.Body>
                <h5 className={"mb-3"}>
                    {doc ? "Корекция" : "Добавяне"} на позиция
                </h5>

                <Row className={"mb-3"}>
                    <Col>
                        <Form.Group>
                            <Form.Label>Име</Form.Label>
                            <Form.Control type="text" name="name" defaultValue={doc?.data().name}/>
                        </Form.Group>
                    </Col>
                </Row>

                <ClientsSelector selectedRegions={selectedRegions}
                                 selectedClients={selectedClients}
                                 onChangeRegions={regions => setSelectedRegions(regions)}
                                 onChangeClients={clients => setSelectedClients(clients)}
                />

            </Modal.Body>
            <Modal.Footer>
                <Row className={"w-100"}>
                    {
                        saving && <Col xs={12} className={"text-center"}>
                            <Spinner animation={"border"}/>
                        </Col>
                    }

                    {
                        !saving &&
                        <>
                            <Col xs={6}>
                                <Button variant={"outline-secondary"} onClick={() => {
                                    onDone()
                                }}>
                                    <FontAwesomeIcon icon={faArrowLeft}/> Към списъка
                                </Button>
                            </Col>
                            <Col xs={6} className={"text-end"}>
                                <Button type={"submit"}>
                                    <FontAwesomeIcon icon={faSave}/> Запиши
                                </Button>
                            </Col>
                        </>
                    }
                </Row>
            </Modal.Footer>
        </Form>
        </>
    )
}
