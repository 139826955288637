import {
    CustomerInvoiceItemsType,
    CustomerInvoiceItemType,
    EMPTY_CUSTOMER_INVOICE_ITEM
} from "../../../fb-converters/cutomerInvoice-converter";
import React, {FC, useEffect, useState} from "react";
import {Button, FormCheck, Table} from "react-bootstrap";
import EditableTableCell from "../../../common/EditableTableCell";
import RightAlignedCell from "../../../common/RightAlignedCell";
import {textToFloat, zeroToEmptyString, zeroToEmptyStringAsCurrency, zeroToEmptyStringAsNumber} from "../../../common";
import PlusIconButton from "../../../common/icon-buttons/PlusIconButton";
import TrashIconButton from "../../../common/icon-buttons/TrashIconButton";
import CheckIconButton from "../../../common/icon-buttons/CheckIconButton";
import CancelIconButton from "../../../common/icon-buttons/CancelIconButton";
import CustomerInvoiceDetailsSelectItem from "./CustomerInvoiceDetailsSelectItem";
import CenterAlignedCell from "../../../common/CenterAlignedCell";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo, faFileImport, faInfo} from "@fortawesome/free-solid-svg-icons";
import {ProductsDialog} from "../../nomenclatures/products/ProductsDialog";
import I18Label, {getI18Price, getI18PriceInLv, getI18Text, getProductI18Name} from "../../../i18/i18label";

export interface Props {
    currencyIndex: number;
    documentType: string;
    availableItems: CustomerInvoiceItemsType;
    items: CustomerInvoiceItemsType;
    onChange: (newItems: CustomerInvoiceItemsType) => void;
}

function InvoiceItemsTableRow(props: {
    currencyIndex: number;
    documentType: string;
    availableItems: CustomerInvoiceItemsType;
    editCell: string,
    rowIndex: number,
    item: CustomerInvoiceItemType,
    onSetEditCell: (editCell: string) => void,
    onChange: (i: CustomerInvoiceItemType) => void,
    onRemove: VoidFunction,
}) {
    const [item, setItem] = useState(props.item);
    const [confirmDelete, setConfirmDelete] = useState(false);

    useEffect(() => {
        const qty = item.qty || 0;
        const price = item.price || 0;
        // const discount = item.discount || 0;
        // const total = (price * qty) - (price * discount / 100) * qty;
        const total = price * qty;
        item.total = total;
        props.onChange(item);
    }, [item]);

    return <tr>
        <RightAlignedCell> {props.rowIndex} </RightAlignedCell>
        <EditableTableCell inEditMode={true}
                           className={"w-100px"}
                           isWorking={false}
                           value={item.code || ""}
                           onEdit={() => props.onSetEditCell(`${props.rowIndex.toString()}_1`)}
                           onBlur={newValue => setItem({...item, code: newValue})}
                           onDone={() => props.onChange(item)}/>
        <EditableTableCell inEditMode={true}
            // inEditMode={props.editCell === `${props.rowIndex.toString()}_2`}
                           isWorking={false}
                           value={getProductI18Name(item.name || "")}
                           onEdit={() => props.onSetEditCell(`${props.rowIndex.toString()}_2`)}
                           onBlur={newValue => setItem({...item, name: newValue})}
                           onDone={() => props.onChange(item)}>
            <CustomerInvoiceDetailsSelectItem
                currencyIndex={props.currencyIndex}
                availableItems={props.availableItems}
                onSelectItem={_item => {
                    setItem({..._item, listIdx: item.listIdx});
                }}/>
        </EditableTableCell>
        <EditableTableCell inEditMode={true}
                           isWorking={false}
                           className={"w-50px"}
                           value={zeroToEmptyString(item._length) || ""}
                            onEdit={() => props.onSetEditCell(`${props.rowIndex.toString()}_l3`)}
                            onBlur={newValue => setItem({...item, _length: textToFloat(newValue) || 0})}
                            onDone={() => {
                            }}
        />
        <EditableTableCell inEditMode={true}
                            isWorking={false}
                            className={"w-50px"}
                            value={zeroToEmptyString(item.width) || ""}
                             onEdit={() => props.onSetEditCell(`${props.rowIndex.toString()}_w3`)}
                             onBlur={newValue => setItem({...item, width: textToFloat(newValue) || 0})}
                             onDone={() => {
                             }}
        />
        <EditableTableCell inEditMode={true}
                            isWorking={false}
                            className={"w-50px"}
                            value={zeroToEmptyString(item.height1) || ""}
                             onEdit={() => props.onSetEditCell(`${props.rowIndex.toString()}_h13`)}
                             onBlur={newValue => setItem({...item, height1: textToFloat(newValue) || 0})}
                             onDone={() => {
                             }}
        />
        <EditableTableCell inEditMode={true}
                            isWorking={false}
                            className={"w-50px"}
                            value={zeroToEmptyString(item.height2) || ""}
                             onEdit={() => props.onSetEditCell(`${props.rowIndex.toString()}_h23`)}
                             onBlur={newValue => setItem({...item, height2: textToFloat(newValue) || 0})}
                             onDone={() => {
                             }}
        />
        <EditableTableCell
            inEditMode={true}
            // inEditMode={props.editCell === `${props.rowIndex.toString()}_3`}
            isWorking={false}
            className={"w-75px"}
            value={getI18Text(item.dimension || "")}
            onEdit={() => props.onSetEditCell(`${props.rowIndex.toString()}_3`)}
            onBlur={newValue => setItem({...item, dimension: newValue})}
            onDone={() => {
            }}/>
        <RightAlignedCell> {zeroToEmptyStringAsNumber(item.weight
        )} </RightAlignedCell>
        <EditableTableCell
            inEditMode={true}
            // inEditMode={props.editCell === `${props.rowIndex.toString()}_4`}
            className={"text-end w-50px"}
            isWorking={false}
            value={zeroToEmptyStringAsNumber(item.qty) || ""}
            onEdit={() => props.onSetEditCell(`${props.rowIndex.toString()}_4`)}
            onBlur={newValue => setItem({...item, qty: textToFloat(newValue) || 0})}
            onDone={() => {
            }}/>
        <EditableTableCell
            inEditMode={true}
            // inEditMode={props.editCell === `${props.rowIndex.toString()}_5`}
            className={"text-end w-75px"}
            isWorking={false}
            value={zeroToEmptyStringAsCurrency(getI18Price(item.price, props.currencyIndex)?.toString()) || ""}
            onEdit={() => props.onSetEditCell(`${props.rowIndex.toString()}_5`)}
            onBlur={newValue => setItem({...item, price: getI18PriceInLv(textToFloat(newValue) || 0, props.currencyIndex)})}
            onDone={() => {
            }}/>
        <EditableTableCell
            inEditMode={true}
            // inEditMode={props.editCell === `${props.rowIndex.toString()}_5_1`}
            className={"text-end w-75px"}
            isWorking={false}
            value={zeroToEmptyStringAsCurrency(getI18Price(item.price * 1.2, props.currencyIndex)?.toString()) || ""}
            onEdit={() => props.onSetEditCell(`${props.rowIndex.toString()}_5_1`)}
            onBlur={newValue => setItem({...item, price: getI18PriceInLv((textToFloat(newValue) || 0) / 1.2, props.currencyIndex)})}
            onDone={() => {
            }}/>
        {/*<EditableTableCell inEditMode={props.editCell === `${props.rowIndex.toString()}_6`}*/}
        {/*                   className={"text-end w-100px"}*/}
        {/*                   isWorking={false}*/}
        {/*                   value={zeroToEmptyStringAsCurrency(item.discount) || ""}*/}
        {/*                   onEdit={() => props.onSetEditCell(`${props.rowIndex.toString()}_6`)}*/}
        {/*                   onBlur={newValue => setItem({...item, discount: textToFloat(newValue) || 0})}*/}
        {/*                   onDone={() => props.onChange(item)}/>*/}
        <RightAlignedCell> {zeroToEmptyStringAsCurrency(getI18Price(item.total, props.currencyIndex))} </RightAlignedCell>
        <CenterAlignedCell>
            {
                item.productId && props.documentType === "потвърждение на поръчка" &&
                <FormCheck checked={item.ownProduction}
                           onClick={() => {
                               const _item = {...item, ownProduction: !item.ownProduction};
                               setItem(_item);
                               props.onChange(_item);
                           }}/>
            }
        </CenterAlignedCell>
        <RightAlignedCell className={"w-75px"}>
            {
                confirmDelete ? <>
                    <CheckIconButton onClick={() => props.onRemove()}/>&nbsp;
                    <CancelIconButton onClick={() => setConfirmDelete(false)}/>
                </> : <>

                    <TrashIconButton onClick={() => setConfirmDelete(true)}/>
                </>
            }
        </RightAlignedCell>
    </tr>;
}

const CustomerInvoiceItemsTable: FC<Props> = (props) => {
    const [editCell, setEditCell] = useState("");
    const [selectProductsFlag, setSelectProductsFlag] = useState(false);

    function setItem(idx: number, i: CustomerInvoiceItemType) {
        const _newItems = [...props.items];
        _newItems[idx] = i;
        setEditCell("");

        props.onChange(_newItems);
    }

    function addItem(newItem: CustomerInvoiceItemType) {
        const _newItems = [...props.items];
        _newItems.push({...newItem, listIdx: _newItems.length});
        setEditCell("");
        props.onChange(_newItems);
    }

    function removeItem(idx: number) {
        const _newItems = [...props.items];
        _newItems.splice(idx, 1);
        setEditCell("");
        props.onChange(_newItems);
    }

    function showSelectProducts() {
        setSelectProductsFlag(true);
    }

    return (
        <>
            <Table>
                <thead>
                <tr>
                    <th rowSpan={2}>No:<br/>&nbsp;</th>
                    <th rowSpan={2}><I18Label label={"Код"} /><br/>&nbsp;</th>
                    <th rowSpan={2}><I18Label label={"Наименование на стоката / услугата"} /><br/>&nbsp;</th>
                    <CenterAlignedCell colSpan={4} header><I18Label label={"Габаритни размери"} /></CenterAlignedCell>
                    <th rowSpan={2}><I18Label label={"Мярка"} /><br/>&nbsp;</th>
                    <RightAlignedCell header rowSpan={2}><I18Label label={"Тегло"} /><br/>&nbsp;</RightAlignedCell>
                    <RightAlignedCell header rowSpan={2}><I18Label label={"Кол."} /><br/>&nbsp;</RightAlignedCell>
                    <RightAlignedCell header rowSpan={2}><I18Label label={"Цена"} /><br/><span
                        className={"small fst-italic"}><I18Label label={"без ДДС"} /></span></RightAlignedCell>
                    <RightAlignedCell header rowSpan={2}><I18Label label={"Цена"} /><br/><span
                        className={"small fst-italic"}><I18Label label={"със ДДС"} /></span></RightAlignedCell>
                    {/*<RightAlignedCell header>Отст.<br/>%</RightAlignedCell>*/}
                    <RightAlignedCell header rowSpan={2}><I18Label label={"Сума"} /><br/>&nbsp;</RightAlignedCell>
                    <CenterAlignedCell header className={"text-nowrap"} rowSpan={2}>
                        {
                            props.documentType === "потвърждение на поръчка" && <>
                        <I18Label label={"СП"} /><br/><FontAwesomeIcon icon={faCircleInfo} size={"sm"} title={getI18Text("Собствено производство")}
                                                        color={"white"}/>
                            </>
                        }
                    </CenterAlignedCell>
                    <th rowSpan={2}></th>
                </tr>
                <tr>
                    <CenterAlignedCell header>L</CenterAlignedCell>
                    <CenterAlignedCell header>B</CenterAlignedCell>
                    <CenterAlignedCell header>H1</CenterAlignedCell>
                    <CenterAlignedCell header>H2</CenterAlignedCell>
                </tr>
                </thead>
                <tbody>
                {
                    props.items.map(
                        (i, idx) =>
                            <InvoiceItemsTableRow key={idx.toString() + i.name}
                                                  currencyIndex={props.currencyIndex}
                                                  documentType={props.documentType}
                                                  availableItems={props.availableItems}
                                                  rowIndex={idx + 1} item={i}
                                                  editCell={editCell}
                                                  onSetEditCell={editCell1 => setEditCell(editCell1)}
                                                  onChange={i => setItem(idx, i)}
                                                  onRemove={() => removeItem(idx)}
                            />
                    )
                }
                <tr>
                    <RightAlignedCell colSpan={15}>
                        <PlusIconButton onClick={() => addItem(EMPTY_CUSTOMER_INVOICE_ITEM)}/>&nbsp;
                        <Button variant={"outline-primary"} onClick={showSelectProducts}>
                            <FontAwesomeIcon icon={faFileImport}/> <I18Label label={"Избери продукти"} />
                        </Button>
                    </RightAlignedCell>
                </tr>
                </tbody>
            </Table>

            <ProductsDialog show={selectProductsFlag} zIndex={1600}
                            onClose={() => setSelectProductsFlag(false)}
                            onSelect={result => {
                                if (result) {
                                    addItem({
                                        listIdx: -1,
                                        productId: result.id,
                                        code: result.sku || "",
                                        name: result.name,
                                        dimension: result.dimens || "брой",
                                        _length: result._length || 0,
                                        width: result.width || 0,
                                        height1: result.height1 || 0,
                                        height2: result.height2 || 0,
                                        qty: 1,
                                        price: (result.price || 0) * props.currencyIndex,
                                        discount: 0,
                                        total: 0,
                                        weight: result.weightWithElectrolit || 0,
                                        ownProduction: false,
                                    });
                                    setSelectProductsFlag(false);
                                }
                            }}
                            onMultiSelect={results => {
                                const _newItems = [...props.items];
                                results.forEach((r: any) => {
                                    const newItem = {
                                        listIdx: _newItems.length,
                                        productId: r.id,
                                        code: r.sku || "",
                                        name: r.name,
                                        dimension: r.dimens || "брой",
                                        _length: r._length || 0,
                                        width: r.width || 0,
                                        height1: r.height1 || 0,
                                        height2: r.height2 || 0,
                                        qty: 1,
                                        price: (r.price || 0) * props.currencyIndex,
                                        discount: 0,
                                        total: 0,
                                        weight: r.weightWithElectrolit || 0,
                                        ownProduction: false,
                                    };
                                    _newItems.push(newItem);
                                });
                                props.onChange(_newItems);

                                setSelectProductsFlag(false);
                            }}
            />
        </>
    )
}

export default CustomerInvoiceItemsTable;
