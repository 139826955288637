import {useState} from "react";

import {
    addDoc,
    collection,
    deleteDoc,
    getFirestore,
    runTransaction,
    getDoc,
    where,
    query,
    getDocs, updateDoc
} from "firebase/firestore";
import {Button, Col, Modal, Row, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faSave} from "@fortawesome/free-solid-svg-icons";
import {ProductionType} from "./hooks/useProductionOnce";
import {firebaseApp} from "../../../App";
import {showErrorsListInToast} from "../../../common";
import UseRecalcWarehouseItemStatus from "../../warehouse/hooks/useRecalcWarehouseItemQty";
import {DEFAULT_WAREHOUSE} from "../../warehouse/consts";


interface Props {
    doc: ProductionType;
    onDone: () => any;
}

export function ProductionDelete({doc, onDone}: Props) {
    const [deleting, setDeleting] = useState(false);
    const warehouseItemUpdater = UseRecalcWarehouseItemStatus();

    const deleteDocument = async () => {
        if (!doc.ref) return;

        const firestore = getFirestore(firebaseApp);

        try {
            setDeleting(true);
            const productIds = new Set<string>();

            await runTransaction(firestore, async (transaction) => {
                productIds.add(doc.productId!);
                doc.items.forEach(
                    value => productIds.add(value.productId)
                );

                const warehouseInItems = await getDocs(query(collection(firestore, "warehouseInItems"),
                    where("transactionId", "==", doc.id),
                ));
                if(!warehouseInItems.empty) {
                    warehouseInItems.forEach(d => transaction.delete(d.ref))
                }

                const warehouseOutItems = await getDocs(query(collection(firestore, "warehouseOutItems"),
                    where("transactionId", "==", doc.id),
                ));
                if(!warehouseOutItems.empty) {
                    warehouseOutItems.forEach(d => {
                        transaction.delete(d.ref);
                        productIds.add(d.get("productId"))
                    })
                }

                transaction.delete(doc.ref!);
            });
            productIds.forEach(productId => warehouseItemUpdater("", DEFAULT_WAREHOUSE, productId,
                doc.productId === productId, undefined));
            onDone();
        } catch (e: any) {
            showErrorsListInToast("Възникна грешка", [e.message])
        } finally {
            setDeleting(false);
        }
    }

    return (
        <>
            <Modal.Body>
                <h5 className={"mb-3"}>
                    Изтриване на продукт
                </h5>

                <div className={"text-danger"}>
                    Моля, потвърдете изтриването на продукта <strong className={"text-primary"}>{doc.name}</strong>!
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Row className={"w-100"}>
                    {
                        deleting && <Col xs={12} className={"text-center"}>
                            <Spinner animation={"border"}/>
                        </Col>
                    }

                    {
                        !deleting &&
                        <>
                            <Col xs={6}>
                                <Button variant={"outline-secondary"} onClick={() => {
                                    onDone()
                                }}>
                                    <FontAwesomeIcon icon={faArrowLeft}/> Към списъка
                                </Button>
                            </Col>
                            <Col xs={6} className={"text-end"}>
                                <Button onClick={() => deleteDocument()} variant={"danger"}>
                                    <FontAwesomeIcon icon={faSave}/> Изтрий
                                </Button>
                            </Col>
                        </>
                    }
                </Row>
            </Modal.Footer>
        </>
    )
}
