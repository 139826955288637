import {Badge, Button, Col, Container, Dropdown, Form, Row, Spinner, Tab, Table, Tabs} from "react-bootstrap";
import {useCollection, useDocumentData} from "react-firebase-hooks/firestore";
import {addDoc, collection, doc, getDocs, onSnapshot, query, where} from "firebase/firestore";
import {auth, fbDb,} from "../../App";
import {useEffect, useMemo, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight, faFilter} from "@fortawesome/free-solid-svg-icons";
import {Activity, CrmPeriod} from "../../types";
import {API} from "../../api";
import moment from "moment";
import {loadClientsLocationsById} from "../../common/DataLoader";
import {dateDiffInDays, firebaseDateToDate, getRequiredActivitiesCntBySizeGroup} from "../../common";
import CheckFilter, {CheckFilterItem} from "../../common/CheckFilter";
import ClientNameWithDetails from "../../common/ClientNameWithDetails";
import {useAuthState} from "react-firebase-hooks/auth";
import {start} from "repl";
import I18Label from "../../i18/i18label";
import {useHookstate} from "@hookstate/core";
import appDb from "../../global-state/global-db";
import {ClientLocationsType, ClientLocationType} from "../../fb-converters/location-converter";

export interface CrmCampaignsProps {
    period: CrmPeriod;
}


export function CrmCampaigns({period}: CrmCampaignsProps) {
    const db = useHookstate(appDb);
    const clientsLocations = db.clientsLocations.value;

    const _filterSizes: CheckFilterItem[] = [
        {name: 'Ключов клиент/дилър', selected: false},
        {name: 'Голям клиент', selected: false},
        {name: 'Среден клиент', selected: false},
        {name: 'Малък клиент', selected: false},
    ];

    const _date = useMemo(() => new Date(), []);
    const [currentTab, setCurrentTab] = useState<any>(null);
    const [campaign, setCampaign] = useState<any>(null);
    const [clientIds, setClientIds] = useState<any>(null);
    const [clientsSlice, setClientsSlice] = useState(0);
    const [clients, setClients] = useState<ClientLocationsType | null>([]);
    const [filteredClients, setFilteredClients] = useState<any>([]);
    const [slicedClients, setSlicedClients] = useState<any>([]);
    const [campaignProgress, setcampaignProgress] = useState(0);
    const [saving, setSaving] = useState(false);
    const [filterRegions, setFilterRegions] = useState<CheckFilterItem[]>([]);
    const [filterSizes, setFilterSizes] = useState<CheckFilterItem[]>(_filterSizes);
    const [filterName, setFilterName] = useState("");
    const [activityStat, setActivityStat] = useState<any>([]);
    const [loadingClients, setLoadingClients] = useState(false);
    const [totalFiltersCount, setTotalFiltersCount] = useState(0);

    const [campaigns, loadingCampaigns, errorCampaigns] = useCollection(
        query(collection(fbDb, "campaigns"),
            where("toDate", ">", _date)
        )
    );
    const [filteredCampaigns, setFilteredCampaigns] = useState<any>([]);

    const [position] = useDocumentData(doc(fbDb, "positions", API.user?.position || "-"));

    useEffect(() => {
        if (position && position.clients && campaigns && campaigns.docs) {
            const _filteredCampaigns = campaigns.docs.filter((c: any) => {
                return c.data().clients.some((cId: string) => position.clients.includes(cId));
            });
            setFilteredCampaigns(_filteredCampaigns);
        }
    });

    const loadActivityStat = async () => {
        let q = query(collection(fbDb, "campaigns/" + (campaign?.id || 'notset') + '/user_activities'));
        const docs = await getDocs(q);
        setActivityStat(docs);
    }

    useEffect(() => {
        const unsubscribe = onSnapshot(doc(fbDb,
            "campaigns/" + (campaign?.id || "notset") + "/user_activities", "change_trigger"), (doc) => {
            loadActivityStat();
        });
        loadActivityStat();
        return unsubscribe;
    }, [campaign])

    useEffect(() => {
        if (campaigns && !currentTab && campaigns.docs.length > 0) setCurrentTab(campaigns.docs[0].id);
    }, [campaigns])

    useEffect(() => {
        setCampaign(null);
        campaigns?.docs.forEach(
            async (d) => {
                if (d.id === currentTab) {
                    setCampaign(d);
                    setFilterRegions(d.data().regions.sort().map((i: string) => {
                        return {name: i, selected: false};
                    }));
                    setFilterSizes(_filterSizes);
                }
            }
        )
    }, [currentTab])

    useEffect(() => {
        if (campaign && campaign.data().clients) {
            const _clientIds: string[] =
                campaign.data().clients?.filter((cId: string) => position?.clients.includes(cId)) || [];
            setClientIds(_clientIds);
        } else {
            setClientIds([]);
        }
    }, [campaign]);

    useEffect(() => {
        if (campaign && activityStat) {
            let _total = 0;
            const _campTotal = campaign.get('totalActivities') || 0;
            if (_campTotal == 0) setcampaignProgress(100);

            activityStat.docs.forEach((d: any) => _total += (d.get('count') || 0));
            setcampaignProgress(Math.round(_total / _campTotal * 100));
        }
    }, [activityStat]);

    useEffect(() => {
        if (clientIds && clientIds.length > 0) {
            setLoadingClients(true);
        } else setClients(null);
    }, [clientIds]);


    useEffect(() => {
        if (loadingClients && clientIds && clientIds.length > 0) {
            window.setTimeout(() => {
                const clients = (clientsLocations.filter((c: any) => clientIds.includes(c.id)))
                    .map(c => {
                        return {...c} as ClientLocationType;
                    });

                setClients(clients);
                setLoadingClients(false);
            }, 0);
        }
    }, [loadingClients])

    useEffect(() => {
        if (!clients) {
            setFilteredClients([]);
            return;
        }

        const selectedRegions = filterRegions.filter(r => r.selected).map(r => r.name);
        const selectedSizes = filterSizes.filter(r => r.selected).map(r => r.name);
        const filterNameLower = filterName.toLowerCase();
        setTotalFiltersCount(selectedRegions.length + selectedSizes.length + (filterName.length > 0 ? 1 : 0));

        const _fc = clients
            .filter(c => selectedRegions.length === 0 || selectedRegions.indexOf(c.region ?? "") > -1)
            .filter(c => selectedSizes.length === 0 || selectedSizes.indexOf(c.sizeGroup ?? "") > -1)
            .filter(c => filterName.length === 0 || c.name?.toString().toLowerCase().startsWith(filterNameLower));
        if (_fc) {
            _fc.forEach(c => {
                c.actIndex = getClientActivitiesIndex(c)
            });
            _fc.sort((c1, c2) =>
                (c1.actIndex ?? 0) > (c2.actIndex ?? 0) ? 1 :
                    ((c1.actIndex ?? 0) < (c2.actIndex ?? 0) ? -1 :
                        (c1.name?.toString().localeCompare(c2.name ?? ""))) ?? 0);
        }

        setFilteredClients(_fc);
    }, [clients, filterRegions, filterSizes, filterName, activityStat]);

    useEffect(() => {
        setClientsSlice(0);
    }, [clients, filterRegions, filterSizes, filterName]);

    useEffect(() => {
        setSlicedClients(filteredClients?.slice(clientsSlice, clientsSlice + 10));
    }, [filteredClients, clientsSlice]);

    const save = async (data: Activity) => {
        setSaving(true);
        await addDoc(collection(fbDb, "activities"), data);
        setSaving(false);
    }

    function createPlan(c: any) {
        const editActivity: Activity = {
            date: moment().year(period.year)
                .month(period.month != null ? period.month : moment().month())
                .date(period.day != null ? period.day : moment().date()).toDate(),
            activityType: "задача",
            activityStatus: "",
            clientId: c.id,
            region: c.region,
            clientName: c.name,
            campaignId: campaign.id,
            campaignName: campaign.data().name,
            userId: API.user?.id || undefined,
            userName: API.user?.name,
        }
        save(editActivity);
    }

    function getClientActivities(client: ClientLocationType) {
        const sizeGroup = client.sizeGroup ?? "";
        if (activityStat) {
            const id = client.id;
            const _stat = activityStat.docs.find((item: any) => item.id == id);
            if (_stat) {
                let activityCnt = getRequiredActivitiesCntBySizeGroup(campaign, sizeGroup);
                const startDate = client.startDate ?? null;
                const fromDate = campaign.fromDate;
                const toDate = campaign.toDate;
                if (startDate && fromDate && toDate && startDate.getTime() > fromDate.getTime()) {
                    if (startDate.getTime() >= toDate.getTime()) {
                        activityCnt = 0;
                    } else {
                        const _fDate = firebaseDateToDate(fromDate);
                        const _tDate = firebaseDateToDate(toDate);
                        const periodDiff = Math.abs(dateDiffInDays(_tDate, _fDate));
                        const startDiff = Math.abs(dateDiffInDays(_fDate, firebaseDateToDate(startDate)));
                        if (periodDiff > 0 && startDiff > 0 && periodDiff > startDiff) {
                            activityCnt -= Math.floor(startDiff / (periodDiff / activityCnt))
                        }
                    }
                }
                return _stat.get('count').toString() + "/" + activityCnt;
            }
        }

        return `0/${getRequiredActivitiesCntBySizeGroup(campaign, sizeGroup)}`;
    }

    function getClientActivitiesIndex(client: any) {
        if (activityStat) {
            const id = client.id;
            const sizeGroup = client.sizeGroup ?? "";

            const _stat = activityStat.docs.find((item: any) => item.id == id);
            if (_stat && _stat.get('count') !== 0) return _stat.get('count') / getRequiredActivitiesCntBySizeGroup(campaign, sizeGroup)
            return 0.1 / getRequiredActivitiesCntBySizeGroup(campaign, sizeGroup);
        }

        return 0;
    }

    return <>
        <Row className={"mb-2"}>
            <Col>
                {
                    loadingCampaigns ? <Spinner animation={"border"}/> :
                        <Tabs activeKey={currentTab} className={"bg-secondary-light pt-1 ps-1"}
                              onSelect={(e) => setCurrentTab(e)}>
                            {
                                filteredCampaigns &&
                                filteredCampaigns.map(
                                    (d: any) => <Tab key={d.id} eventKey={d.id} title={d.data().name}/>
                                )
                            }
                        </Tabs>
                }
            </Col>
            <Col xs={"auto"}>
                <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <FontAwesomeIcon icon={faFilter}/>
                        {
                            totalFiltersCount > 0 && <Badge bg="primary">{totalFiltersCount}</Badge>
                        }
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Container className={"small min-width-450px"}>
                            <CheckFilter title={<><I18Label label={"Райони"}/>:</>} options={filterRegions}
                                         onChange={(r) => setFilterRegions(r)}/>
                            <CheckFilter title={<><I18Label label={"Типове фирми"}/>:</>} options={filterSizes}
                                         onChange={(r) => setFilterSizes(r)}/>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col className={"fw-bold"}><I18Label label={"Търсене по име"}/></Col>
                                    </Row>
                                </Col>
                                <Row>
                                    <Col>
                                        <Form.Control value={filterName}
                                                      onChange={event => setFilterName(event.target.value)}/>
                                    </Col>
                                </Row>
                            </Row>
                        </Container>
                    </Dropdown.Menu>
                </Dropdown>
            </Col>
        </Row>
        {
            !loadingCampaigns && loadingClients &&
            <Row><Col xs={12} className={"text-center p-5"}><Spinner animation={"border"}/></Col></Row>
        }
        {
            campaign && !loadingClients &&
            <Table size={"sm"} bordered hover className={"mt-2"}>
                <thead>
                <tr>
                    <th className={"text-center fw-bold bg-primary text-light"}> {campaignProgress}%</th>
                    <th className={"w-160px"}><I18Label label={"Клиент"}/></th>
                    <th><I18Label label={"Акт"}/></th>
                    <th className={"w-100px"}><I18Label label={"Регион"}/></th>
                    <th className={"w-100px"}><I18Label label={"Тип"}/></th>
                    <th><I18Label label={"Адрес"}/></th>
                </tr>
                </thead>
                <tbody className={"table-small-lines"}>
                {
                    slicedClients.map(
                        (c: any) => <tr key={c.id}>
                            <td>
                                <Button size={"sm"} onClick={() => createPlan(c)} disabled={saving}>
                                    <FontAwesomeIcon icon={faArrowLeft}/>
                                </Button></td>
                            <td><ClientNameWithDetails clientLocationId={c.id} clientLocationName={c.name}/></td>
                            <td> {getClientActivities(c)} </td>
                            <td> {c.region} </td>
                            <td> {c.sizeGroup} </td>
                            <td> {c.address} </td>
                        </tr>
                    )
                }
                </tbody>
            </Table>

        }
        <Row className={"justify-content-between align-content-between mb-2"}>
            <Col xs={"auto"}>
                {
                    clientsSlice > 0 &&
                    <Button variant={"secondary"} onClick={() => setClientsSlice(clientsSlice - 10)}>
                        <FontAwesomeIcon icon={faArrowLeft}/> Предишна страница
                    </Button>
                }
            </Col>
            <Col className={"text-center"}>
                {
                    !filteredClients || loadingClients ? null
                        :
                        <strong>
                            {
                                filteredClients?.length === 0 ?
                                    <><I18Label label={"Няма фирми"}/>.</>
                                    :
                                    <>
                                        <I18Label
                                            label={"Показани са"}/>: {(clientsSlice + 1)} - {(clientsSlice + 10) < filteredClients?.length ? (clientsSlice + 10) : filteredClients?.length}
                                        &nbsp;<I18Label label={"от общо"}/> {filteredClients?.length} <I18Label
                                        label={"фирми"}/>
                                    </>
                            }
                        </strong>
                }
            </Col>
            <Col xs={"auto"}>
                {
                    clientsSlice + 10 < filteredClients?.length &&
                    <Button variant={"secondary"} onClick={() => setClientsSlice(clientsSlice + 10)}>
                        Следваща страница <FontAwesomeIcon icon={faArrowRight}/>
                    </Button>
                }
            </Col>
        </Row>
    </>
}
