import {useEffect, useState} from "react";

import {DocumentData, updateDoc, addDoc, collection, getFirestore, writeBatch} from "firebase/firestore";
import {Button, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faSave} from "@fortawesome/free-solid-svg-icons";
import {fbDb, firebaseApp} from "../../../App";
import {ClientsSelector} from "../clients_selector/ClientsSelector";
import ReactDatePicker from "react-datepicker";
import {loadAllClients, loadAllClientsLocations} from "../../../common/DataLoader";
import {firebaseDateToDate, removeEmptyFields} from "../../../common";


export interface CampaignDetailsProps {
    zIndex?: number;
    doc: DocumentData | null;
    onDone: () => any;
}

export function CampaignDetails({zIndex, doc, onDone}: CampaignDetailsProps) {
    const [saving, setSaving] = useState(false);
    const [selectedRegions, setSelectedRegions] = useState<string[]>(doc?.data().regions || []);
    const [selectedClients, setSelectedClients] = useState<string[]>(doc?.data().clients || []);
    const [fromDate, setFromDate] = useState<Date | undefined | null>(firebaseDateToDate(doc?.data().fromDate) || new Date());
    const [toDate, setToDate] = useState<Date | undefined | null>(firebaseDateToDate(doc?.data().toDate) || new Date());
    const [allClients, setAllClients] = useState<any>();
    const [totalClients, setTotalClients] = useState(doc?.data().totalClients || 0);
    const [totalActivities, setTotalActivities] = useState(doc?.data().totalActivities || 0);
    const [smallTries, setSmallTries] = useState(doc?.data().smallTries || "1");
    const [midTries, setMidTries] = useState(doc?.data().midTries || "2");
    const [bigTries, setBigTries] = useState(doc?.data().bigTries || "3");
    const [keyTries, setKeyTries] = useState(doc?.data().keyTries || "4");

    useEffect(() => {
        let _tCl = 0;
        let _tAct = 0;

        if(allClients) {
            const filteredClients = allClients.docs
                .filter((d: any) => selectedRegions.indexOf(d.data().region) > -1) || [];

            let _kTry = parseInt(keyTries); if(isNaN(_kTry)) _kTry = 0;
            let _bTry = parseInt(bigTries); if(isNaN(_bTry)) _bTry = 0;
            let _mTry = parseInt(midTries); if(isNaN(_mTry)) _mTry = 0;
            let _sTry = parseInt(smallTries); if(isNaN(_sTry)) _sTry = 0;

            selectedClients.forEach(
                cId => {
                    const cl = filteredClients.find(
                        (d: any) => {
                          return d.id == cId;
                        }
                    );
                    if(cl) {
                        _tCl ++;
                        switch (cl.data().sizeGroup) {
                            case "Малък клиент" : _tAct += _sTry;  break;
                            case "Среден клиент" : _tAct += _mTry; break;
                            case "Голям клиент" : _tAct += _bTry; break;
                            case "Ключов клиент/дилър" : _tAct += _kTry; break;
                            default: _tAct++;
                        }
                    }
                }
            )
        }

        setTotalClients(_tCl);
        setTotalActivities(_tAct);
    }, [allClients, selectedClients, keyTries, smallTries, bigTries, midTries])

    useEffect(
        () => {
            loadAllClientsLocations().then(
                result => {
                    setAllClients(result);

                    /// REMOVE LOCATIONS WITHOUT CLIENTS

                    // loadAllClients().then(
                    //     clients => {
                    //         console.log(clients);
                    //         console.log(result);
                    //
                    //         // const tobeDeleted: any[] = [];
                    //         // result.forEach(
                    //         //     location => {
                    //         //         const client = clients.docs.find(
                    //         //             c => c.id === location.get("clientId")
                    //         //         );
                    //         //         if(!client) {
                    //         //             tobeDeleted.push(location)
                    //         //         }
                    //         //     }
                    //         // )
                    //         //
                    //         // while(tobeDeleted.length > 0) {
                    //         //     const deleteIds = tobeDeleted.splice(0, 500);
                    //         //     const batch = writeBatch(fbDb);
                    //         //     deleteIds.forEach(
                    //         //         item => batch.delete(item.ref)
                    //         //     )
                    //         //     batch.commit()
                    //         // }
                    //     })
                }
            )
        }, []
    );

    const save = async (data: any) => {
        setSaving(true);
        let _data = removeEmptyFields(data);
        if (doc) {
            await updateDoc(doc.ref, _data);
        } else {
            await addDoc(collection(fbDb, "campaigns"), _data);
        }
        setSaving(false);
        onDone();
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        const _finalClients = selectedClients.filter(
            c => {
                const cl = allClients.docs.find(
                    (d: any) => {
                        return d.id == c;
                    }
                );
                if(cl) {
                    return selectedRegions.indexOf(cl.data().region) > -1;
                } else {
                    return false;
                }
            }
        );

        const data: any = {
            name: event.target.elements.name.value || 'Не е посочено',
            target: event.target.elements.target.value || '',
            weekTarget: event.target.elements.weekTarget.value || '0',
            keyTries: keyTries || '0',
            bigTries: bigTries || '0',
            midTries: midTries || '0',
            smallTries: smallTries || '0',
            totalClients: totalClients || '0',
            totalActivities: totalActivities || '0',
            isActive: event.target.elements.isActive.value || 'Не',
            fromDate: fromDate,
            toDate: toDate,
            regions: selectedRegions,
            clients: _finalClients,
        };
        save(data);
    }


    return (
        <><Form className={"p-2"} onSubmit={handleSubmit}>
            <Modal.Body>
                <h5 className={"mb-3"}>
                    {doc ? "Корекция" : "Добавяне"} на кампания
                </h5>

                <div className={"y-scrollable-block-50"}>

                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label className={"small"}>Име</Form.Label>
                            <Form.Control type="text" name="name" defaultValue={doc?.data().name}/>
                        </Form.Group>
                    </Col>
                    <Col xs={"auto"}>
                        <Form.Group className={"w-120px"}>
                            <Form.Label className={"small"}>Начална дата</Form.Label>
                            <ReactDatePicker className={"form-control"} isClearable
                                             dateFormat="dd.MM.yyyy"
                                             onChange={(d) => {
                                                 setFromDate(d)
                                             }}
                                             selected={fromDate}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={"auto"}>
                        <Form.Group className={"w-120px"}>
                            <Form.Label className={"small"}>Крайна дата</Form.Label>
                            <ReactDatePicker className={"form-control"} isClearable
                                             dateFormat="dd.MM.yyyy"
                                             onChange={(d) => {
                                                 setToDate(d)
                                             }}
                                             selected={toDate}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label className={"small"}>Цел на кампанията</Form.Label>
                            <Form.Control as={"textarea"} name="target" defaultValue={doc?.data().target}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className={"mb-3"}>
                    <Col>
                        <Form.Group>
                            <Form.Label className={"small"}>Брой клиенти седмично</Form.Label>
                            <Form.Control type="number" name="weekTarget" defaultValue={doc?.data().weekTarget}/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label className={"small"}>Опити за контакт ключов клиент</Form.Label>
                            <Form.Control type="number" name="keyTries" value={keyTries}
                                          onChange={(e) => setKeyTries(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label className={"small"}>Опити за контакт голям клиент</Form.Label>
                            <Form.Control type="number" name="bigTries" value={bigTries}
                                          onChange={(e) => setBigTries(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label className={"small"}>Опити за контакт среден клиент</Form.Label>
                            <Form.Control type="number" name="midTries" value={midTries}
                                          onChange={(e) => setMidTries(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label className={"small"}>Опити за контакт малък клиент</Form.Label>
                            <Form.Control type="number" name="smallTries" value={smallTries}
                                          onChange={(e) => setSmallTries(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label className={"small"}>Кампанията е активна?</Form.Label>
                            <select className={"form-select"} name="isActive" defaultValue={doc?.data().isActive}>
                                <option value={"Да"}>Да</option>
                                <option value={"Не"}>Не</option>
                            </select>
                        </Form.Group>
                    </Col>
                </Row>
                    <ClientsSelector selectedRegions={selectedRegions}
                                     selectedClients={selectedClients}
                                     onChangeRegions={regions => setSelectedRegions(regions)}
                                     onChangeClients={clients => setSelectedClients(clients)}
                    />
                </div>

                <Row className={"border shadow p-2 m-1 bg-info"}>
                    <Col>Брой клиенти включени в кампанията: <span className={"fw-bold"}>{totalClients}</span></Col>
                    <Col>Брой активности в кампанията: <span className={"fw-bold"}>{totalActivities}</span></Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Row className={"w-100"}>
                    {
                        saving && <Col xs={12} className={"text-center"}>
                            <Spinner animation={"border"}/>
                        </Col>
                    }

                    {
                        !saving &&
                        <>
                            <Col xs={6}>
                                <Button variant={"outline-secondary"} onClick={() => {
                                    onDone()
                                }}>
                                    <FontAwesomeIcon icon={faArrowLeft}/> Към списъка
                                </Button>&nbsp;
                            </Col>
                            <Col xs={6} className={"text-end"}>
                                <Button type={"submit"}>
                                    <FontAwesomeIcon icon={faSave}/> Запиши
                                </Button>
                            </Col>
                        </>
                    }
                </Row>
            </Modal.Footer>
        </Form>
        </>
    )
}
