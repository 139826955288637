import {
    WarehouseRevisionIndexItemType, warehouseRevisionItemConverter,
    WarehouseRevisionItemType
} from "../../../fb-converters/warehouse-revision-converter";
import ModalBackground from "../../../common/ModalBackground";
import {Button, Col, Form, FormGroup, Modal, Row, Table} from "react-bootstrap";
import {FC, useEffect, useState} from "react";
import {zeroToEmptyString, zeroToEmptyStringAsCurrency} from "../../../common";
import ReactDatePicker from "react-datepicker";
import {useDocument} from "react-firebase-hooks/firestore";
import {doc,} from "firebase/firestore";
import {fbDb} from "../../../App";
import WarehouseRevisionNewItem from "./WarehouseRevisionNewItem";
import RightAlignedCell from "../../../common/RightAlignedCell";
import WarehouseRevisionEditableItem from "./WarehouseRevisionEditableItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {OwnCompanyDropdown} from "../../nomenclatures/own-companies/OwnCompanySelector";
import WarehouseObjectsDropdown from "../../nomenclatures/warehouse-objects/WarehouseObjectsDropdown";

interface WarehouseRevisionDetailsProps {
    zIndex: number;
    editRevision: WarehouseRevisionIndexItemType;
    onDone: (revision: WarehouseRevisionIndexItemType | null, revisionItems: WarehouseRevisionItemType[], updatedItems: WarehouseRevisionItemType[]) => void;
}

const WarehouseRevisionDetails: FC<WarehouseRevisionDetailsProps> = ({zIndex, editRevision, onDone}) => {

    const [revision, setRevision] = useState<WarehouseRevisionIndexItemType>(editRevision);
    const [originalRevisionItems, setOriginalRevisionItems] = useState<WarehouseRevisionItemType[]>([]);
    const [revisionItems, setRevisionItems] = useState<WarehouseRevisionItemType[]>([]);

    const [revisionItemsData, revisionItemsLoading, revisionItemsError] = useDocument(
        doc(fbDb, `warehouseRevisions/${editRevision.id}`).withConverter(warehouseRevisionItemConverter)
    );
    useEffect(() => {
        setRevisionItems([]);
        setOriginalRevisionItems([]);

        if (revisionItemsData) {
            const newRevisons = revisionItemsData.data()?.items || [];
            setRevisionItems(newRevisons);
            setOriginalRevisionItems(newRevisons);
        }
    }, [revisionItemsData]);

    useEffect(
        () => {
            setRevision(editRevision);
        },
        [editRevision]
    );

    useEffect(() => {
        let totalAmount = 0;
        revisionItems.forEach(item => {
            totalAmount += item.productSinglePrice * (item.actualQty - item.expectedQty);
        });
        setRevision({...revision, revisionAmount: totalAmount});
    }, [revisionItems]);

    function onSave() {
        const updatedItemIds: WarehouseRevisionItemType[] = [];
        revisionItems
            .filter(item => item.productId !== null)
            .forEach(item => {
                const originalItem = originalRevisionItems.find(i => i.productId === item.productId);
                if (!originalItem || originalItem.expectedQty !== item.expectedQty || originalItem.actualQty !== item.actualQty) {
                    updatedItemIds.push(item);
                }
            });
        originalRevisionItems
            .filter(item => item.productId !== null)
            .forEach(item => {
                const updatedItem = revisionItems.find(i => i.productId === item.productId);
                if (!updatedItem) {
                    updatedItemIds.push(item);
                }
            });
        onDone(revision, revisionItems, updatedItemIds);
    }

    return (
        <ModalBackground zIndex={zIndex}>
            <Modal show={true} onHide={() => onDone(null, [], [])} size={"xl"} style={{zIndex: zIndex + 1}}>
                <Modal.Header closeButton>
                    <Modal.Title>Детайли за ревизия</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={"auto"}>
                            <FormGroup>
                                <Form.Label>Фирма:</Form.Label>
                                <OwnCompanyDropdown
                                    selectedOwnCompanyId={revision.ownCompanyId ?? undefined}
                                    onChange={(ownCompanyId) => setRevision({...revision, ownCompanyId})}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={"auto"}>
                            <FormGroup>
                                <Form.Label>Склад:</Form.Label>
                                <WarehouseObjectsDropdown
                                    ownCompanyId={revision.ownCompanyId ?? undefined}
                                    selectedWarehouseObjectId={revision.warehouseId ?? undefined}
                                    onChange={(warehouseId) => setRevision({...revision, warehouseId: warehouseId})}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={"auto"}>
                            <FormGroup style={{width: "100px"}}>
                                <Form.Label>Номер:</Form.Label>
                                <Form.Control type="text"
                                              value={zeroToEmptyString(revision.revisionNumber) || "-= нова =-"}
                                              readOnly disabled/>
                            </FormGroup>
                        </Col>
                        <Col xs={"auto"}>
                            <FormGroup>
                                <Form.Label>Дата:</Form.Label>
                                <br/>
                                <ReactDatePicker
                                    selected={revision.revisionDate}
                                    onChange={(date: Date) => setRevision({...revision, revisionDate: date})}
                                    dateFormat="dd.MM.yyyy"
                                    className="form-control w-100px"
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Form.Label>Създадена от:</Form.Label>
                                <Form.Control type="text"
                                              value={revision.createdBy || ""}
                                              onChange={(e) => setRevision({...revision, createdBy: e.target.value})}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Form.Label>Получена от:</Form.Label>
                                <Form.Control type="text"
                                              value={revision.receivedBy || ""}
                                              onChange={(e) => setRevision({...revision, receivedBy: e.target.value})}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={1}>
                            <FormGroup>
                                <Form.Label>Сума:</Form.Label>
                                <Form.Control type="text"
                                              className={"text-end"}
                                              value={zeroToEmptyStringAsCurrency(revision.revisionAmount)}
                                              readOnly disabled/>
                            </FormGroup>
                        </Col>
                    </Row>

                    <hr/>

                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>Код</th>
                            <th>Име</th>
                            <th>Ед.</th>
                            <RightAlignedCell header>Ед.цена</RightAlignedCell>
                            <RightAlignedCell header>Очаквано</RightAlignedCell>
                            <RightAlignedCell header>Реално</RightAlignedCell>
                            <RightAlignedCell header>Разлика</RightAlignedCell>
                            <RightAlignedCell header>Стойност</RightAlignedCell>
                            <th className={"text-center w-60px"}/>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            revisionItemsLoading ? (
                                <tr>
                                    <td colSpan={8} className={"text-center"}>Зареждане...</td>
                                </tr>
                            ) : revisionItemsError ? (
                                <tr>
                                    <td colSpan={8} className={"text-center"}>Грешка: {revisionItemsError.message}</td>
                                </tr>
                            ) : revisionItems.length === 0 ? (
                                <tr>
                                    <td colSpan={8} className={"text-center"}>Няма добавени артикули</td>
                                </tr>
                            ) : null
                        }
                        {
                            revisionItems.length > 0 && revisionItems.map((item, index) => (
                                <WarehouseRevisionEditableItem zIndex={zIndex}
                                                               editItem={item}
                                                               isEditMode={true}
                                                               onChange={(newItem) => {
                                                                   if (newItem === null) {
                                                                       setRevisionItems([
                                                                           ...revisionItems.slice(0, index),
                                                                           ...revisionItems.slice(index + 1)
                                                                       ]);
                                                                       return;
                                                                   }
                                                                   setRevisionItems([
                                                                       ...revisionItems.slice(0, index),
                                                                       newItem,
                                                                       ...revisionItems.slice(index + 1)
                                                                   ]);
                                                               }}
                                                               onEdit={() => {
                                                               }}
                                                               onCancel={() => {
                                                               }}/>
                            ))}
                        <WarehouseRevisionNewItem zIndex={zIndex}
                                                  onAdd={
                                                      (item) => {
                                                          setRevisionItems([...revisionItems, item])
                                                      }
                                                  }/>
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Col>
                        <Button variant={"outline-secondary"} onClick={() => {
                            onDone(null, [], []);
                        }}>
                            <FontAwesomeIcon icon={faArrowLeft}/> Към списъка
                        </Button>
                    </Col>
                    <Col className={"text-end"}>
                        <button className="btn btn-primary" onClick={onSave}>Запази</button>
                    </Col>
                </Modal.Footer>
            </Modal>
        </ModalBackground>
    );
}

export default WarehouseRevisionDetails;
