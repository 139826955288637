import {Card, Col, Container, Row} from "react-bootstrap";
import LoadingErrorContent from "../../../common/LoadingErrorContent";
import useWarehouseObjects from "../hooks/useWarehouseObjects";
import {useState} from "react";
import WarehouseObjectComponent from "./WarehouseObjectComponent";
import {DEFAULT_WAREHOUSE} from "../consts";
import I18Label from "../../../i18/i18label";
import {useWarehouseRequestsRepo} from "../warehouse-transactions/warehouse-request/useWarehouseRequestsRepo";
import useWarehouseObjectsRepo from "../hooks/useWarehouseObjectsRepo";
import OwnCompanyWarehouseSelector from "./OwnCompanyWarehouseSelector";

export default function WarehousePage() {
    const [selectedOwnCompanyId, setSelectedOwnCompanyId] = useState<string | undefined>();
    const [selectedWarehouseId, setSelectedWarehouseId] = useState<string | undefined>();

    return <Container>
        <Card>
            <Card.Header>
                <Card.Title><I18Label label={"Склад - складово стопанство"}/></Card.Title>
            </Card.Header>

            <Card.Body>
                <Row>
                    <Col>
                        <OwnCompanyWarehouseSelector
                            selectedCompanyId={selectedOwnCompanyId}
                            selectedWarehouseId={selectedWarehouseId}
                            onChange={(companyId, warehouseId) => {
                                setSelectedOwnCompanyId(companyId);
                                setSelectedWarehouseId(warehouseId);
                            }}
                        />
                    </Col>
                </Row>
                { selectedWarehouseId && <WarehouseObjectComponent warehouseId={selectedWarehouseId}/> }
            </Card.Body>

        </Card>


    </Container>
}
