import React, {useEffect, useMemo, useState} from "react";

import {Button, Col, Form, Modal, ModalBody, ModalTitle, Row, Spinner, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faSave,
    faCancel,
    faDolly, faTrash, faCheck, faWarning
} from "@fortawesome/free-solid-svg-icons";
import {
    dateToString,
    removeEmptyFields,
    showErrorsListInToast,
    textToFloat,
    zeroToEmptyStringAsCurrency,
    zeroToEmptyStringAsNumber
} from "../../../../common";
import SelectValueFromDialog from "../../../../common/SelectValueFromDialog";
import {ProviderType} from "../../../nomenclatures/providers/hooks/useProviders";
import {ProductsDialog} from "../../../nomenclatures/products/ProductsDialog";
import {ProvidersDialog} from "../../../nomenclatures/providers/ProvidersDialog";
import ReactDatePicker from "react-datepicker";
import ModalHeader from "react-bootstrap/ModalHeader";
import DialogCloseButton from "../../../../common/DialogCloseButton";
import ModalBackground from "../../../../common/ModalBackground";
import EditableTableCell from "../../../../common/EditableTableCell";
import {ProductType} from "../../../nomenclatures/products/hooks/useProductsOnce";
import {
    addDoc,
    collection, doc,
    getDocs,
    query,
    setDoc,
    where,
    writeBatch
} from "firebase/firestore";
import {fbDb} from "../../../../App";
import PlusIconButton from "../../../../common/icon-buttons/PlusIconButton";
import LoadingErrorContent from "../../../../common/LoadingErrorContent";
import {WarehouseInType} from "../../hooks/useWarehouseIn";
import useWarehouseInItems, {WarehouseItemType} from "../../hooks/useWarehouseInItems";
import UseRecalcWarehouseItemStatus from "../../hooks/useRecalcWarehouseItemQty";
import {WarehouseRequestType} from "../warehouse-request/WarehouseRequestType";
import {warehouseRequestConverter} from "../warehouse-request/useWarehouseRequestsRepo";
import {useHookstate} from "@hookstate/core";
import appDb from "../../../../global-state/global-db";
import CenterAlignedCell from "../../../../common/CenterAlignedCell";
import {v4 as uuidv4} from 'uuid';
import appState from "../../../../global-state/global-state";
import useOwnCompaniesRepo from "../../hooks/useOwnCompaniesRepo";
import {OwnCompanyDropdown} from "../../../nomenclatures/own-companies/OwnCompanySelector";
import WarehouseObjectsDropdown from "../../../nomenclatures/warehouse-objects/WarehouseObjectsDropdown";


export interface WarehouseTransactionDetailsProps {
    zIndex?: number;
    warehouseTransaction: WarehouseInType;
    onDone: () => any;
}

export function WarehouseInDetails({zIndex, warehouseTransaction, onDone}: WarehouseTransactionDetailsProps) {
    const allProducts = useHookstate(appDb.products);

    const eurRateField = useHookstate(appState.currencyIndex);
    const eurRate = eurRateField.value.EUR || 1;

    const [saving, setSaving] = useState(false);
    const [item, setItem] = useState<WarehouseInType>({...warehouseTransaction});
    const [itemItems, loadItems, errorItems] = useWarehouseInItems(warehouseTransaction.id || "-1");
    const [newItem, setNewItem] = useState<WarehouseItemType | null>(null);
    const [editValue, setEditValue] = useState("");
    const [itemRequests, setItemRequests] = useState<WarehouseRequestType[]>([]);
    const [itemRequestLoading, setItemRequestLoading] = useState(false);
    const [selectedItemRequest, setSelectedItemRequest] = useState<WarehouseRequestType | null>(null);
    const [checkedItems, setCheckedItems] = useState<string[]>([]);

    const warehouseItemUpdater = UseRecalcWarehouseItemStatus();

    const getSinglePrice = (originalSinglePrice: number | null | undefined): number => {
        return (originalSinglePrice || 0) / (item.isEUR ? 1 : eurRate) / (item.hasDDS ? 1.2 : 1);
    }

    const getOriginalSinglePrice = (singlePrice: number | null | undefined): number => {
        return (singlePrice || 0) * (item.hasDDS ? 1.2 : 1) * (item.isEUR ? 1 : eurRate);
    }

    useEffect(() => {
        const singlePrice = getSinglePrice(newItem?.originalSinglePrice);
        const qty = newItem?.qty || 0;
        const deliveryPrice = qty * singlePrice;
        if (newItem?.deliveryPrice !== deliveryPrice || newItem?.singlePrice !== singlePrice || newItem?.qty !== qty) {
            setNewItem({
                ...newItem,
                deliveryPrice: deliveryPrice,
                singlePrice: singlePrice,
                qty: qty
            } as WarehouseItemType);
        }
    }, [newItem?.qty, newItem?.originalSinglePrice, item.hasDDS, item.isEUR, eurRate]);

    useEffect(() => {
        if (item && itemItems) {
            setItem({...item, items: itemItems});
        }
    }, [itemItems]);

    const save = async () => {
        setSaving(true);

        try {
            const affectedProducts = new Set<string>();

            item.price = 0;
            item.productIds = [];
            item.items?.forEach(
                i => {
                    item.price += (i.deliveryPrice || 0);
                    i.availableQty = i.qty;
                    i.usedQty = 0;
                    if (i.productId) {
                        item.productIds?.push(i.productId);

                        if (selectedItemRequest) {
                            const requestItem = selectedItemRequest.items.find(ri => ri.product.id === i.productId);
                            if (requestItem) {
                                requestItem.deliveryQty = (requestItem.deliveryQty ?? 0) + (i.qty ?? 0);
                                requestItem.deliveryPrice = i.singlePrice ?? 0;
                                requestItem.deliveryDate = item.transactionDate;
                                requestItem.invoiceDate = item.transactionDate;
                                requestItem.invoiceNumber = item.invoiceNumber;
                            }
                        }
                    }
                }
            );

            if (selectedItemRequest) {
                const selectedItemRequestRef = doc(fbDb, "warehouseRequests/" + selectedItemRequest.id);
                let isAllDelivered = selectedItemRequest.items.every(ri => ri.deliveryQty && ri.deliveryQty >= ri.qty);
                selectedItemRequest.isActive = !isAllDelivered;

                const _selectedItemRequest = removeEmptyFields({
                    ...selectedItemRequest,
                    items: selectedItemRequest.items.map(
                        i => removeEmptyFields({...i})
                    ),
                    provider: removeEmptyFields(selectedItemRequest.provider)
                })!;
                await setDoc(selectedItemRequestRef, _selectedItemRequest);
            }

            let _data = removeEmptyFields({...item, items: null});

            if (item.ref) {
                await setDoc(item.ref, _data);

                const whtItemsRef = query(collection(fbDb, "warehouseInItems"),
                    where("transactionId", "==", item.id));
                const whtItems = await getDocs(whtItemsRef);

                const batch = writeBatch(fbDb);
                for (const item of whtItems.docs) {
                    affectedProducts.add(item.get("productId"));
                    batch.delete(item.ref);
                }
                await batch.commit();
            } else {
                let newItem = await addDoc(collection(fbDb, "warehouseIn"), {
                    ..._data,
                    items: null
                });
                item.id = newItem.id;
            }

            item.items?.forEach(
                async i => {
                    affectedProducts.add(i.productId);
                    await addDoc(collection(fbDb, `warehouseInItems`),
                        {
                            warehouseId: item.warehouseId,
                            transactionId: item.id,
                            transactionDate: item.transactionDate,
                            productId: i.productId,
                            isOwnProduction: false,
                            name: i.name,
                            sku: i.sku,
                            dimens: i.dimens,
                            originalSinglePrice: i.originalSinglePrice || 0,
                            singlePrice: i.singlePrice || 0,
                            qty: i.qty || 0,
                            deliveryPrice: (i.deliveryPrice || 0) / (i.qty || 1),
                            availableQty: i.qty || 0,
                            usedQty: 0
                        });
                }
            );

            console.log("Affected products: ", affectedProducts);
            affectedProducts.forEach(
                productId => warehouseItemUpdater(item.ownCompanyId, item.warehouseId, productId, false, undefined)
            );

            onDone();
        } catch (e: any) {
            showErrorsListInToast("Възникна грешка", [e.message]);
        } finally {
            setSaving(false);
        }
    }

    const providerDialog = <ProvidersDialog show={true} zIndex={(zIndex || 1060) + 1} onClose={() => {
    }}/>;
    const productsDialog = <ProductsDialog show={true} zIndex={(zIndex || 1060) + 1} onClose={() => {
    }}/>

    function selectProduct(product: ProductType) {
        if (product) {
            let wProduct: WarehouseItemType = {
                ...newItem,
                ownCompanyId: item.ownCompanyId,
                warehouseId: item.warehouseId,
                productId: product.id,
                isOwnProduction: product.isOwnProduct,
                name: product.name,
                dimens: product.dimens || null,
                sku: product.sku || null,
                qty: 1,
                singlePrice: product.deliveryPrice,
                originalSinglePrice: getOriginalSinglePrice(product.deliveryPrice),
                id: uuidv4()
            }
            setNewItem(wProduct);
        }
    }


    function removeItem(idx: number) {
        let _item = {...item};
        let _items = (_item.items || []);
        _items.splice(idx, 1);
        setItem(_item);
    }

    function checkItemDeliveryPrice(i: WarehouseItemType) {
        const item = allProducts.value.find(p => p.id === i.productId);
        if (item) {
            if (getSinglePrice(i.originalSinglePrice).toFixed(2) !== item.deliveryPrice?.toFixed(2)) {
                return <span className={"text-danger"}
                             title={"Цената на доставката не съвпада с цената на продукта ( " + zeroToEmptyStringAsCurrency(getOriginalSinglePrice(item.deliveryPrice)) + " )"}
                ><FontAwesomeIcon icon={faWarning}/></span>
            }
        }
        return null;
    }

    function switchCheck(id?: string) {
        if (!id) return;
        if (checkedItems.includes(id)) {
            setCheckedItems(checkedItems.filter(ci => ci !== id));
        } else {
            setCheckedItems([...checkedItems, id]);
        }
    }

    const tableRows = useMemo(() => {
        let totalQty = 0;
        let totalPrice = 0;

        if (item.items && item.items.length > 0) {
            const rows = item.items.map(
                (i, idx) => {
                    totalQty += i.qty || 0;
                    totalPrice += i.originalSinglePrice ? (i.qty || 0) * i.originalSinglePrice : 0;

                    return (<tr key={idx}>
                        <CenterAlignedCell>
                            <Form.Check type={"checkbox"} checked={(i.id && checkedItems.includes(i.id)) ? true : false}
                                        onChange={(e) => switchCheck(i.id)}
                            />
                        </CenterAlignedCell>
                        <td> {i.sku} </td>
                        <td> {i.name} </td>
                        <td> {i.dimens} </td>
                        <EditableTableCell inEditMode={editValue === "qty_" + idx.toString()} isWorking={false}
                                           value={i.qty?.toString() || ""}
                                           className={"text-end"}
                                           onEdit={() => {
                                               setEditValue("qty_" + idx.toString())
                                           }}
                                           onBlur={newValue => {
                                               setItem({
                                                   ...item,
                                                   items: item.items?.map(
                                                       (item, iidx) => {
                                                           if (iidx === idx) {
                                                               const qty = textToFloat(newValue) ?? 0;
                                                               return {
                                                                   ...item,
                                                                   qty: qty,
                                                                   deliveryPrice: (qty || 0) * (item.originalSinglePrice || 0)
                                                               }
                                                           }
                                                           return item;
                                                       }
                                                   )
                                               });
                                               setEditValue("");
                                           }}
                                           onDone={() => {
                                               setEditValue("")
                                           }}/>
                        <EditableTableCell inEditMode={editValue === "originalSinglePrice_" + idx.toString()}
                                           isWorking={false}
                                           className={"text-end"}
                                           value={i.originalSinglePrice?.toFixed(2) || ""}
                                           onEdit={() => {
                                               setEditValue("originalSinglePrice_" + idx.toString())
                                           }}
                                           onBlur={newValue => {
                                               setItem({
                                                   ...item,
                                                   items: item.items?.map(
                                                       (item, iidx) => {
                                                           if (iidx === idx) {
                                                               const originalSinglePrice = textToFloat(newValue) ?? 0;
                                                               return {
                                                                   ...item,
                                                                   originalSinglePrice: originalSinglePrice,
                                                                   singlePrice: getSinglePrice(originalSinglePrice),
                                                                   deliveryPrice: (item.qty || 0) * (originalSinglePrice)
                                                               }
                                                           }
                                                           return item;
                                                       }
                                                   )
                                               });
                                               setEditValue("");
                                           }}
                                           onDone={() => {
                                               setEditValue("")
                                           }}
                        >
                            {
                                checkItemDeliveryPrice(i)
                            }
                        </EditableTableCell>
                        <td className={"text-end"}> {
                            zeroToEmptyStringAsCurrency(
                                ((i.originalSinglePrice || 0) * (i.qty || 0)).toFixed(2)
                            )
                        } </td>
                        <td className={"text-end"}>
                            <Button variant={"danger"} size={"sm"} onClick={() => removeItem(idx)}>
                                <FontAwesomeIcon icon={faTrash}/>
                            </Button>
                        </td>
                    </tr>)
                }
            );
            rows.push(
                <tr key={"total"}>
                    <td colSpan={4} className={"text-end fw-bold"}>Общо:</td>
                    <td className={"text-end fw-bold"}>{totalQty}</td>
                    <td/>
                    <td className={"text-end fw-bold"}>{zeroToEmptyStringAsNumber(totalPrice)}</td>
                    <td/>
                </tr>
            );
            return rows;
        }
        return <tr>
            <td colSpan={9}>
                <div className={"text-center fw-bold"}>
                    Добавете продукт
                </div>
            </td>
        </tr>
    }, [item, editValue, checkedItems, item.isEUR, item.hasDDS, eurRate]);

    function addProduct() {
        if (!newItem?.productId) return;

        let _item = {...item};
        let newItemItems = (item.items || []);
        newItemItems.push(newItem!);
        _item.items = newItemItems;
        setItem(_item);

        setNewItem(null);
    }

    useEffect(() => {
        setItemRequests([]);
        if (item.provider) {
            try {
                setItemRequestLoading(true);

                const whtItemsRef = query(collection(fbDb, "warehouseRequests"),
                    where("provider.id", "==", item.provider.id),
                    where("isActive", "==", true),
                ).withConverter(warehouseRequestConverter);
                getDocs(whtItemsRef).then(
                    (docs) => {
                        let _items: WarehouseRequestType[] = [];
                        docs.forEach(
                            doc => {
                                _items.push(doc.data() as WarehouseRequestType);
                            }
                        );
                        setItemRequests(_items);
                    }
                );
            } catch (e: any) {
                showErrorsListInToast("Възникна грешка", [e.message]);
            } finally {
                setItemRequestLoading(false);
            }
        }
    }, [item.provider]);

    function loadSelectedItemRequest() {
        if (selectedItemRequest) {
            let _itemItems = [...item.items ?? []];
            selectedItemRequest.items
                .filter(
                    item => _itemItems.findIndex(
                        i => i.productId === item.product.id
                    ) === -1
                )
                .forEach(
                    i => {
                        let _item: WarehouseItemType = {
                            id: uuidv4(),
                            ownCompanyId: item.ownCompanyId,
                            warehouseId: item.warehouseId,
                            productId: i.product.id!,
                            isOwnProduction: false,
                            name: i.product.name,
                            dimens: i.product.dimension,
                            sku: i.product.sku,
                            qty: i.qty,
                            originalSinglePrice: i.product.price,
                            deliveryPrice: i.deliveryPrice || (i.product.price * i.qty)
                        }
                        _itemItems.push(_item);
                    }
                );
            setItem({...item, items: _itemItems});
        }
    }

    function clearNotChecked() {
        const newItems = (item.items ?? []).filter(
            item => checkedItems.findIndex(
                i => i === item.id
            ) !== -1
        );

        setItem({...item, items: newItems});
    }

    return (
        <ModalBackground zIndex={zIndex || 1050}>
            <Modal show={true} size={"xl"} style={{zIndex: ((zIndex || 1050) + 1)}}>
                <ModalHeader>
                    <ModalTitle>
                        <FontAwesomeIcon icon={faDolly}/> {item?.id ? "Корекция" : "Добавяне"} на доставка
                    </ModalTitle>
                    <DialogCloseButton onClose={() => onDone()}/>
                </ModalHeader>
                <ModalBody>
                    <LoadingErrorContent loading={loadItems} error={errorItems?.message}>
                        <div className={"ps-2 pe-2"}>
                            <Row>
                                <Col xs={"auto"}>
                                    <Form.Group>
                                        <Form.Label>Фирма:</Form.Label>
                                        <OwnCompanyDropdown
                                            selectedOwnCompanyId={item.ownCompanyId}
                                            onChange={
                                                (selectedOwnCompanyId) => setItem({...item, ownCompanyId: selectedOwnCompanyId})
                                            } />
                                    </Form.Group>
                                </Col>

                                <Col xs={"auto"}>
                                    <Form.Group>
                                        <Form.Label>Склад:</Form.Label>
                                        <WarehouseObjectsDropdown
                                            ownCompanyId={item.ownCompanyId}
                                            selectedWarehouseObjectId={item.warehouseId}
                                            onChange={(selectedWarehouseObjectId) => setItem({...item, warehouseId: selectedWarehouseObjectId})}
                                        />
                                    </Form.Group>
                                </Col>

                                <Col xs={"auto"}>
                                    <Form.Group className={"w-140px"}>
                                        <Form.Label>Дата:</Form.Label>
                                        <ReactDatePicker className={"form-control"} isClearable
                                                         dateFormat="dd.MM.yyyy"
                                                         onChange={(d) => {
                                                             setItem({...item, transactionDate: d || new Date()})
                                                         }}
                                                         selected={item.transactionDate}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <SelectValueFromDialog label={"Доставчик"}
                                                           value={item.provider?.name}
                                                           onChange={(provider: ProviderType) => setItem({
                                                               ...item,
                                                               provider: provider
                                                           })}
                                                           dialog={providerDialog}
                                    />
                                </Col>
                                <Col xs={"auto"}>
                                    <Form.Group>
                                        <Form.Label>Фактура номер:</Form.Label>
                                        <Form.Control type="text" value={item.invoiceNumber} className={"w-200px"}
                                                      onChange={e => setItem({...item, invoiceNumber: e.target.value})}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={"auto"}>
                                    <Form.Group>
                                        <Form.Label>Валута:</Form.Label>
                                        <Form.Select value={item.isEUR ? "EUR" : "BGN"}
                                                     onChange={e => setItem({
                                                         ...item,
                                                         isEUR: e.target.value === "EUR"
                                                     })}>
                                            <option value={"BGN"}>BGN</option>
                                            <option value={"EUR"}>EUR</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col xs={"auto"}>
                                    <Form.Group>
                                        <Form.Label>Цената е:</Form.Label>
                                        <Form.Select value={item.hasDDS ? "1" : "0"}
                                                     onChange={e => setItem({...item, hasDDS: e.target.value === "1"})}>
                                            <option value={"1"}>с ДДС</option>
                                            <option value={"0"}>без ДДС</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className={"mb-3"}>
                                <Col>
                                    <Form.Label>Намерени заявки към този доставчик:</Form.Label>
                                    <Form.Select
                                        value={selectedItemRequest?.id}
                                        onChange={e => {
                                            const _selected = itemRequests.find(r => r.id?.toString() ?? "" === e.target.value);
                                            setSelectedItemRequest(_selected || null);
                                        }}
                                    >
                                        <option value={""}>
                                            {
                                                itemRequests.length > 0 ? "Изберете заявка" : "Няма намерени заявки"
                                            }
                                        </option>
                                        {
                                            itemRequests.map(
                                                (r, idx) => <option key={idx} value={r.id}>
                                                    {r.requestNumber?.toString().padStart(8, "0")} от {dateToString(r.createdAt)}
                                                </option>
                                            )
                                        }
                                    </Form.Select>
                                </Col>
                                <Col xs={"auto"} style={{paddingTop: "27px"}}>
                                    {
                                        itemRequestLoading ? <Spinner animation={"border"} size={"sm"}/>
                                            :
                                            <Button
                                                variant={"primary"}
                                                onClick={() => loadSelectedItemRequest()}
                                                disabled={!selectedItemRequest}
                                            >
                                                <FontAwesomeIcon icon={faCheck}/> Зареди
                                            </Button>
                                    }
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Table size={"sm"} bordered hover>
                                        <thead>
                                        <tr>
                                            <th/>
                                            <th>SKU</th>
                                            <th>Продукт</th>
                                            <th>М.Е.</th>
                                            <th className={"text-end w-120px"}>Количество</th>
                                            <th className={"text-end w-120px"}>Ед.Цена</th>
                                            <th className={"text-end w-120px"}>Цена</th>
                                            <th/>
                                        </tr>
                                        </thead>

                                        <tbody>
                                        {tableRows}
                                        <tr>
                                            <td/>
                                            <td> {newItem?.sku} </td>
                                            <td>
                                                <SelectValueFromDialog size={"sm"} className={"mb-0"}
                                                                       value={newItem?.name}
                                                                       onChange={(product: ProductType) => {
                                                                           selectProduct(product)
                                                                       }}
                                                                       dialog={productsDialog}
                                                />
                                            </td>
                                            <td> {newItem?.dimens} </td>
                                            <EditableTableCell inEditMode={editValue === "qty"} isWorking={false}
                                                               value={newItem?.qty?.toString() || ""}
                                                               className={"text-end"}
                                                               onEdit={() => {
                                                                   setEditValue("qty")
                                                               }}
                                                               onBlur={newValue => {
                                                                   setNewItem({
                                                                       ...newItem,
                                                                       qty: textToFloat(newValue)
                                                                   } as WarehouseItemType);
                                                                   setEditValue("");
                                                               }}
                                                               onDone={() => {
                                                                   setEditValue("")
                                                               }}/>
                                            <EditableTableCell inEditMode={editValue === "originalSinglePrice"}
                                                               isWorking={false}
                                                               className={"text-end "}
                                                               value={newItem?.originalSinglePrice?.toFixed(2) || ""}
                                                               onEdit={() => {
                                                                   setEditValue("originalSinglePrice")
                                                               }}
                                                               onBlur={newValue => {
                                                                   setNewItem({
                                                                       ...newItem,
                                                                       originalSinglePrice: textToFloat(newValue)
                                                                   } as WarehouseItemType);
                                                                   setEditValue("");
                                                               }}
                                                               onDone={() => {
                                                                   setEditValue("")
                                                               }}>
                                            </EditableTableCell>
                                            <td className={"text-end"}> {zeroToEmptyStringAsCurrency(
                                                ((newItem?.originalSinglePrice || 0) * (newItem?.qty || 0)).toFixed(2))} </td>
                                            <td className={"text-end"}>
                                                <PlusIconButton size={"sm"} onClick={addProduct}
                                                                disabled={editValue !== ""}/>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </div>
                    </LoadingErrorContent>
                </ModalBody>
                <Modal.Footer>
                    <Row className={"w-100 ps-0 ms-0"}>
                        {
                            saving && <Col xs={12} className={"text-center"}>
                                <Spinner animation={"border"}/>
                            </Col>
                        }

                        {
                            !saving &&
                            <>
                                <Col xs={6}>
                                    <Button variant={"outline-secondary"} onClick={() => {
                                        onDone()
                                    }}>
                                        <FontAwesomeIcon icon={faCancel}/> Откажи
                                    </Button>
                                </Col>
                                <Col xs={6} className={"text-end"}>
                                    <Button variant={"outline-secondary"}
                                            onClick={clearNotChecked}
                                    >
                                        Изчити немаркираните
                                    </Button>&nbsp;
                                    <Button type={"submit"} onClick={() => save()}>
                                        <FontAwesomeIcon icon={faSave}/> Запиши
                                    </Button>
                                </Col>
                            </>
                        }
                    </Row>
                </Modal.Footer>
            </Modal>
        </ModalBackground>
    )
}
