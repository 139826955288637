import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStickyNote, faTrash} from "@fortawesome/free-solid-svg-icons";
import {Button} from "react-bootstrap";

export default function NotesIconButton(props: {note?: string, size?: "sm" | "lg", onClick: VoidFunction}) {
    return <Button onClick={props.onClick} size={props.size || "sm"}
                   variant={props.note ? "info" : "outline-info"} title={props.note}
    >
        <FontAwesomeIcon icon={faStickyNote}/>
    </Button>;
}
