import {
    DocumentData,
    DocumentReference,
    FirestoreError,
    QueryDocumentSnapshot,
    QuerySnapshot
} from "firebase/firestore";
import {useEffect, useState} from "react";
import useFirebaseCollection from "../../../hooks/useFirebaseCollection";


export interface WarehouseType {
    id: string;
    name: string;
}
export type WarehousesType = WarehouseType[];


export interface WarehouseObjectType {
    id: string;
    ref: DocumentReference;
    ownCompanyId: string | null;
    name: string;
    shortName: string;
}
export type WarehouseObjectsType = WarehouseObjectType[];

export function convertWharehouseObjectData(_objects: QuerySnapshot<DocumentData>): WarehouseObjectsType {
    if(_objects && _objects.docs) {
        const data = _objects.docs.map(
            d => {
                return {
                    id: d.id,
                    ref: d.ref,
                    name: d.get('name') ?? "",
                    shortName: d.get('shortName') ?? "",
                    ownCompanyId: d.get('ownCompanyId') ?? null,
                } as WarehouseObjectType;
            }
        );
        return data;
    } else return [];
}



export default function useWarehouseObjects(): [WarehouseObjectsType, boolean, FirestoreError | undefined] {
    const [documents, loading, error] = useFirebaseCollection("warehouseObjects");
    const [warehouseObjects, setWarehouseObjects] = useState<WarehouseObjectsType>([]);

    useEffect(() => {
        if(documents) {
            setWarehouseObjects(convertWharehouseObjectData(documents));
        }
    }, [documents]);

    return [warehouseObjects, loading, error];
}
